import React, {useState, useEffect, useCallback, useRef} from 'react';
import { addDays, startOfWeek, format, isWeekend, isToday, getISOWeek, getYear } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/NavigateBefore';
import ArrowForwardIcon from '@mui/icons-material/NavigateNext';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useAuth } from 'oidc-react';
import DatePicker, {registerLocale} from 'react-datepicker';
import { statusMapping } from '../Utils.js';
import { useTranslation } from 'react-i18next';
import {CalendarMonth} from "@mui/icons-material";
import {useLanguage} from "./LanguageProvider";
import {enGB, sv} from "date-fns/locale";
import GroupIcon from '@mui/icons-material/Group';
import ExpandableText from './ExpandableText.js';
import './Agenda.css'
import StatusFilter from './StatusFilter.js';

const AgendaView = ({ setActiveDate }) => {

  const [tasks, setTasks] = useState([]);
  const [dateGrouping, setDateGrouping] = useState(null);
  const selectedDateKey = 'selectedDate';
  const storedDateStr = sessionStorage.getItem(selectedDateKey);
  const selectedEndDateKey = 'selectedEndDate';
  const storedEndDateStr = sessionStorage.getItem(selectedEndDateKey);
  const [selectedDate, setSelectedDate] = useState(storedDateStr ? new Date(storedDateStr) : new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(storedEndDateStr ? new Date(storedEndDateStr) : null);
  const navigate = useNavigate();
  const startOfCurrentWeek = startOfWeek(selectedDate, {weekStartsOn: 1});
  const datesOfWeek = Array.from({length: 7}, (_, index) => addDays(startOfCurrentWeek, index));
  const {language, dateFnsLocale} = useLanguage();
  const refDatePicker = useRef(null);
  const [open, setOpen] = useState(false);
  const isCalendarModeKey = 'isCalendarMode';
  const isCalendarModeValue = sessionStorage.getItem(isCalendarModeKey);
  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
  let isCalendarModeTmp = true;
  let year = storedDateStr ? getYear(new Date(storedDateStr)) : getYear(new Date())
  if (isCalendarModeValue && isCalendarModeValue === 'false') {
    isCalendarModeTmp = false;
  } 
  const [isCalendarMode, setCalendarMode] = useState(isCalendarModeTmp);
  const [holidays, setHolidays] = useState(null);
  const [holidaysDatePicker, setHolidaysDatePicker] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);

  registerLocale(language, dateFnsLocale);
  const goToPreviousWeek = () => {
    moveByDays(-7);
  };

  const goToNextWeek = () => {
    moveByDays(7);
  };

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
          if (year !== getYear(selectedDate) || !holidays) {
            year = getYear(selectedDate)
          } else {
            return;
          }
          let url = `${rootUrl}api/Eos/holidays/${year}`;
          console.log(url);
          const response = await fetch(url, {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
              }
          });

          if (response.ok) {
              const data = await response.json();
              setHolidays(data);
          }
      } catch (error) {
          console.error('Error fetching holiday value:', error);
      }
    };
    fetchHolidays();
  }, [selectedDate]);

  useEffect(() => { 
    if (holidays) {
      const updatedHolidays = holidays.map(holiday => ({
        date: holiday.date.substring(0, 10),
        holidayName: i18n.language === 'sv' ? holiday.localName : holiday.name
      }));
      setHolidaysDatePicker(updatedHolidays)
    }
  }, [holidays]);

  const moveByDays = (dateAmount) => {
    const newSelectedDate = addDays(selectedDate, dateAmount);
    setSelectedDate(newSelectedDate);
    setSelectedEndDate(newSelectedDate);
  }

  const auth = useAuth();
  const accessToken = auth.userData?.access_token;
  const getTasksForDate = async (startDate, endDate, selectedStatuses) => {
    try {
      if (isCalendarMode === true && selectedStatuses === null) {
        // No need to try and fetch workorders if in calendar mode and no statuses selected
        return;
      }
      
      const formattedDate = format(startDate, 'yyyy-MM-dd');
      let url = `${rootUrl}api/Eos/calendar/${formattedDate}?statusFilter=${selectedStatuses ?? ''}`;
      if (endDate && endDate.getTime() > startDate.getTime() && isCalendarMode) {
        const formattedDateEndDate = format(endDate, 'yyyy-MM-dd');
        url = `${rootUrl}api/Eos/calendar/range?start=${formattedDate}&end=${formattedDateEndDate}&statusFilter=${selectedStatuses ?? ''}`;
      } else if (isCalendarMode === false) {
        url = `${rootUrl}WorkOrder/workorderlist`;
      }
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      if (isCalendarMode === true) {
        setTasks(data.workorders);
        setDateGrouping(data.dateGrouping);
      }
      else {
        setTasks(data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    setActiveDate(selectedDate);
    sessionStorage.setItem(selectedDateKey, selectedDate.toISOString());
  }, [selectedDate]);

  useEffect(() => {
    if (selectedEndDate) {
      sessionStorage.setItem(selectedEndDateKey, selectedEndDate.toISOString());
    } else {
      sessionStorage.removeItem(selectedEndDateKey);
    }
  }, [selectedEndDate]);

  useEffect(() => {
    if (accessToken) {
      getTasksForDate(selectedDate, selectedEndDate, statusFilter);
    }
  }, [selectedDate, selectedEndDate, isCalendarMode, statusFilter]);

  // Add a useEffect to listen for changes in the auth state
  useEffect(() => {
    if (accessToken) {
      getTasksForDate(selectedDate, selectedEndDate, statusFilter);
    }
  }, [auth, accessToken]);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setSelectedDate(start);
    if (!end || end.getTime() < start.getTime()) {
      setSelectedEndDate(null)
    } else if (start < end) {
      setSelectedEndDate(end);
    }
  };

  const handleDateClick = (date) => {
    const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    setSelectedDate(newDate);
    setSelectedEndDate(newDate);
  };

  const handleShowAllWorkOrdersClick = () => {
    sessionStorage.setItem(isCalendarModeKey, String(!isCalendarMode));
    setCalendarMode(!isCalendarMode);
    setTasks([]);
  };

  const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

  const theme = createTheme({
    palette: {
      primary: {
        main: '#2196f3',
      },
    },
  });
  const today = new Date();
  const { t, i18n } = useTranslation();

  const dayNames = [];
  for (let i = 1; i <= 7; i++) {
    const formatter = new Intl.DateTimeFormat(i18n.language, { weekday: 'short' });
    const dayName = formatter.format(addDays(firstDayOfWeek, i - 1));
    dayNames.push(dayName);
  }

  const handleNavigateToDetailPage = useCallback((cardId) => {
    navigate(`/task/${cardId}`);
  }, [navigate]);

  const localDateFormat = new Intl.DateTimeFormat(navigator.language, { day: 'numeric', month: 'long' });
  const formattedDate = selectedDate.toLocaleDateString(navigator.language, {
    month: 'long',
    year: 'numeric',
  });

  const onAnyTextFieldChanged = (e) => {
    if (!!e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (open && datePickerContainerRef.current && !datePickerContainerRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [open, refDatePicker]);

  const CustomInput = React.forwardRef(({ value }, ref) => (
      <div className="datepicker-input-container" ref={ref}>
        <IconButton onClick={() => {
          setOpen(!open);
        }}>
          <CalendarMonth />
        </IconButton>
      </div>
  ));
  const datePickerContainerRef = useRef(null);

  const getWorkordersForDate = (date) => {
    return tasks.filter(workorder => date.value.includes(workorder.id));
  };

  const formatDateGroupingDate = (dateString) => {
    let date = new Date(dateString);

    return date.toLocaleDateString(i18n.language, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
    })
  };

  const isHoliday = (date) => {
    const formattedDate  = format(date, 'yyyy-MM-dd'); 
    if (holidays && Array.isArray(holidays)) {
      for (let holiday of holidays) {       
        if (holiday.date.split('T')[0] === formattedDate) {
          return true; 
        }
      }
      return false;
    } else {
      return false; 
    }
  }
  
  const onStatusFilterChanged = (updatedStatusFilter) => {
    setStatusFilter(updatedStatusFilter);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ height: '7vh', margin: '0', padding: '0' }}>
          <Grid item>
            <Grid container direction="row">
              <Grid item>
                {isCalendarMode && (
                  <div ref={datePickerContainerRef}>
                    <DatePicker
                        ref={refDatePicker}
                        selectsRange={true}
                        startDate={selectedDate}
                        endDate={selectedEndDate}
                        onChange={handleDateChange}
                        selected={selectedDate}
                        shouldCloseOnSelect={false}
                        dateFormat="yyyy-MM-dd"
                        locale={language && language.startsWith('en') ? enGB : sv}
                        customInput={<CustomInput/>}
                        open={open}
                        popperPlacement="bottom-start"
                        showWeekNumbers
                        holidays={holidaysDatePicker}
                    />
                  </div>
                )}
              </Grid>
              <Grid item>
                <IconButton onClick={handleShowAllWorkOrdersClick}>
                  {isCalendarMode ? <ListAltIcon /> : <CalendarMonthIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          {isCalendarMode && (
            <Grid item>
              <Typography>{t('week')}&nbsp;{getISOWeek(selectedDate)}</Typography>
            </Grid>
          )}

          <Grid item>
            {isCalendarMode && (
              <Grid container direction="row" alignItems="center">
                <IconButton onClick={goToPreviousWeek} sx={{ padding: '6px'}}>
                  <ArrowBackIcon />
                </IconButton>

                <Typography
                  variant="body1"
                  style={{ margin: '0', cursor: 'pointer' }}
                  onClick={() => { setSelectedDate(today); setSelectedEndDate(null); } }
                >
                  {t('today')}
                </Typography>

                <IconButton onClick={goToNextWeek} sx={{ padding: '6px'}}>
                  <ArrowForwardIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        
        {isCalendarMode && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
            {datesOfWeek.map((date, index) => (
              <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="body2" style={{ marginBottom: '4px' }}>
                  {dayNames[index]}
                </Typography>
                <Chip
                  label={format(date, 'dd')}
                  onClick={() => handleDateClick(date)}
                  color={
                    isWeekend(date) || isHoliday(date)
                        ? 'secondary'
                        : selectedDate.getTime() === date.getTime()
                        || (selectedEndDate && (selectedDate <= date
                            && date <= selectedEndDate))
                            ? 'primary'
                            : isToday(date)
                                ? 'error'
                                : 'default'
                  }
                  clickable
                  style={{
                    fontWeight: selectedDate.getTime() === date.getTime() ? 'bold' : 'normal',
                    fontSize: window.innerWidth < 600 ? '12px' : '16px',
                    minWidth: 'unset',
                  }}
                />
              </div>
            ))}
          </div>
        )}

        {isCalendarMode ? (
          <Typography variant="h6" sx={{ my: 1, textAlign: 'center' }}>
            {selectedDate.toLocaleDateString(i18n.language, {
              weekday: 'long',
              day: 'numeric',
              month: 'long',
            })}
            {
              selectedEndDate && selectedDate < selectedEndDate ? ` - ${selectedEndDate.toLocaleDateString(i18n.language, {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
              })}` : ''
            }
          </Typography>
        ) : (
          <Typography variant="h6" sx={{ my: 1, textAlign: "center" }}>
            {t("workOrder.listMode")}
          </Typography>
        )}

        {isCalendarMode && (
          <StatusFilter onStatusFilterChanged={onStatusFilterChanged} />
        )}
        <Grid container justifyContent="center" spacing={2}>
          {tasks.length === 0 ? (
            // <Grid item>
            <Typography variant="body1">{t('noTasks')}</Typography>
            // </Grid>
          ) : (
            dateGrouping !== null && isCalendarMode === true ? (
              dateGrouping.map((date) => (
                <>
                <h3 style={{ marginBottom: 0 }}>{formatDateGroupingDate(date.key)}</h3>
                { getWorkordersForDate(date).map((task) => (
                  <Grid key={task.id} item xs={12} sm={12} md={12} lg={12}>
                    <div onClick={() => handleNavigateToDetailPage(task.id)} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
                      <BasicCard task={task} />
                    </div>
                  </Grid>
                ))}
                
                </>
              ))
            ) :
            (
              tasks.map((task) => (
                <Grid key={task.id} item xs={12} sm={12} md={12} lg={12}>
                  <div onClick={() => handleNavigateToDetailPage(task.id)} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
                    <BasicCard task={task} />
                  </div>
                </Grid>
              ))
            )
          )}
        </Grid>
      </Container>
    </ThemeProvider>

  );
};

const statusColors = [
  { status: 'cu', color: '#C0C0C0', label: 'Central Office' },
  { status: 'OP', color: '#20B2AA', label: 'Operational' },
  { status: 'På vei', color: '#FFA500', label: 'In Transit' },
  { status: 'Under Arbied', color: '#00BFFF', label: 'Under Construction' },
  { status: 'Teknisk ferdig', color: '#9370DB', label: 'Technically Completed' },
  { status: 'CO', color: '#FF0000', label: 'Closed' },
];
const BasicCard = ({ task }) => {
  const { t, i18n } = useTranslation();
  const formattedDate = new Date(task.start).toLocaleString(undefined);
  const startDateTime = new Date(task.start);
  const endDateTime = new Date(task.end);

  const formattedStartDate = startDateTime.toLocaleTimeString();
  const formattedEndDate = endDateTime.toLocaleTimeString();

  // Finn riktig objekt i statusColors-arrayet basert på oppgavens status
  const colorObject = statusColors.find(item => item.status === task.status);
  const backgroundColor = colorObject ? colorObject.color : '#FFFFFF'; // Default farge

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 2,
        position: 'relative',
        borderLeft: `4px solid ${statusMapping(task.status).stateColor}`
      }}
    >
      <div
        style={{
          width: 25,
          height: 25,
          borderRadius: '50%',
          backgroundColor: backgroundColor,
          position: 'absolute',
          top: 10,
          right: 10,
        }}
      />
      <CardContent sx={{ flex: '1 0 auto', padding: 0, mt: 2 }}>
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item>
            <Typography fontSize={14} color="text.secondary" gutterBottom>
              <span>{t('workOrder.id')}: {task.id}</span>
            </Typography>
            <Typography fontSize={14} color="text.secondary" gutterBottom>
              <span>{t('workOrder.referenceNbr')}: {task.type}</span>
            </Typography>
          </Grid>
          <Grid item>
            <GroupIcon style={{ display: task.hasExtraResources ? "inline-flex" : "none" }} fontSize="medium" />
          </Grid>
          <Grid item>
            <Typography fontSize={14} color="text.secondary" gutterBottom>
              <span>{t('workOrder.status')}: {statusMapping(task.status).stateName}</span>
            </Typography>
            <Typography fontSize={14} color="text.secondary" gutterBottom textAlign={"right"}>
              {
                task.eventControl === "PENDING" ? (
                  <span style={{ color: "#E17919" }}>{t('workOrder.pending')}</span>
                ) : (
                  <span>{task.customerBooked === true ? t('workOrder.booked') : t('workOrder.notBooked')}</span>
                )
              }
            </Typography>
          </Grid>
        </Grid>
        
        <Typography variant="h5" component="div">
          {task.title}
        </Typography>
        {(task.planStartDate || task.planEndDate) ?
          <>
            <Typography sx={{ mb: 1, display: task.planStartDate ? "block" : "none" }} color="text.secondary">
              {t('workOrder.planStartDate')}: {task.planStartDate && new Date(task.planStartDate).toLocaleString(i18n.language)}
            </Typography>
            <Typography sx={{ mb: 1, display: task.planEndDate ? "block" : "none" }} color="text.secondary">
              {t('workOrder.planEndDate')}: {task.planEndDate && new Date(task.planEndDate).toLocaleString(i18n.language)}
            </Typography>
          </>
          :
          (task.start || task.end) ?
            <>
              <Typography sx={{ mb: 1, display: task.start ? "block" : "none" }} color="text.secondary">
                {t('workOrder.requestedStartTime')}: {task.start && new Date(task.start).toLocaleString(i18n.language)}
              </Typography>
              <Typography sx={{ mb: 1, display: task.end ? "block" : "none" }} color="text.secondary">
                {t('workOrder.requestedEndTime')}: {task.end && new Date(task.end).toLocaleString(i18n.language)}
              </Typography>
            </> : <></>
        }
        <Typography variant="body2">{task.address}</Typography>
        {(task.materialReceived !== null) ? 
        <>
          {
            task.materialReceived === true ? (
              <Typography style={{ color: "#425CFB" }}>{t('workOrder.materialReceived')}<br />{task.materialReceivedText}</Typography>
            ) : (
              <span style={{ color: "#E17919" }}>{t('workOrder.materialNotReceived')}</span>
            )
          }
        </> : <></>}
        <ExpandableText text={task.internalInfo} /> 
      </CardContent>
    </Card>
  );
};

export default AgendaView;
