
import React, { useState, useEffect } from 'react';
import { Select, InputLabel, MenuItem, FormControl, Typography, Box, AccordionDetails, CircularProgress, Button, FormHelperText } from '@mui/material';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import ReportQualityEE000 from './ReportQualityEE000';

const ReportQuality = ({ wono, setToastState, isReadOnly, pEE000, parameterEE20xValue }) => {
    const [paraDefList, setParaDefList] = useState(null);
    
    const [optionsEE201, setOptionsEE201] = useState(null);
    const [optionsEE202, setOptionsEE202] = useState(null);
    const [optionsEE203, setOptionsEE203] = useState(null);
    const [optionsEE204, setOptionsEE204] = useState([]);
    const [optionsEE205, setOptionsEE205] = useState([]);
    const [valueEE20x, setValueEE20x] = useState({ EE201: "", EE202: "", EE203: "", EE204: "", EE205: "", });
    const [saving, setSaving] = useState(false);
    
    let woData = "";
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const { t } = useTranslation();
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;



    useEffect(() => {
        const fetchParameterDefList = async () => {
            try {
                if (parameterEE20xValue !== null && parameterEE20xValue.length === 5) {
                    const result1 = await getParameterDefList("EE201");
                    setOptionsEE201(result1);
                    const result2 = await getParameterDefList("EE202");
                    setOptionsEE202(result2);
                    const result3 = await getParameterDefList("EE203");
                    setOptionsEE203(result3);
                    const result4 = await getParameterDefList("EE204");
                    setOptionsEE204(result4);
                    const result5 = await getParameterDefList("EE205");
                    setOptionsEE205(result5);

                    setValueEE20x({
                        EE201: parameterEE20xValue[0].valuE_WITH_LOV,
                        EE202: parameterEE20xValue[1].valuE_WITH_LOV,
                        EE203: parameterEE20xValue[2].valuE_WITH_LOV,
                        EE204: parameterEE20xValue[3].valuE_WITH_LOV,
                        EE205: parameterEE20xValue[4].valuE_WITH_LOV,
                    });
                }
            } catch (error) {
                console.error('Error fetching parameter definition list:', error);
            }
        };
        fetchParameterDefList();
    }, [parameterEE20xValue]);

    useEffect(() => {
        console.log("optionsEE201 --->");
        console.log(optionsEE201);
    }, [optionsEE201]);

    const getParameterDefList = async (parameter) => {
        try {
            let url = `${rootUrl}WorkOrder/parameter/deflist/${parameter}`;
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error(`Error Status: ${response.status}`);
            }

            const parameterValue = await response.json();
            return parameterValue;
        } catch (error) {
            console.error(`Error fetching parameter list for: ${parameter}`, error);
            throw error;
        }
    };

    const handleChangeSelect = (event, selectId) => {
        setValueEE20x(prevState => ({
            ...prevState,
            [selectId]: event.target.value
        }));
    };

    const updateWorkOrder = async () => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(woData)
        };
        let url = `${rootUrl}WorkOrder/wo`;
        const response = await fetch(url, options);

        if (!response.ok) {
            console.log(response);
            setToastState({
                open: true,
                severity: 'error',
                message: t('updateFail'),
            });
        }
        else {
            setToastState({
                open: true,
                severity: 'success',
                message: t('updateSuccess'),
            });
        }
        setSaving(false);
    };

    const handleUploadReportQuality = async () => {
        setSaving(true);
        woData =
        {
            wO_NO: wono,
            parameteR_CATEGORIES: [
                {
                    parameteR_ID: "EE201",
                    valuE_WITH_LOV: valueEE20x.EE201
                },
                {
                    parameteR_ID: "EE202",
                    valuE_WITH_LOV: valueEE20x.EE202
                },
                {
                    parameteR_ID: "EE203",
                    valuE_WITH_LOV: valueEE20x.EE203
                },
                {
                    parameteR_ID: "EE204",
                    valuE_WITH_LOV: valueEE20x.EE204
                },
                {
                    parameteR_ID: "EE205",
                    valuE_WITH_LOV: valueEE20x.EE205
                },
            ]
        };
        updateWorkOrder();
    };

    function isButtonDisabeled() {
        if (valueEE20x.EE201 === "" ||
            valueEE20x.EE202 === "" ||
            valueEE20x.EE203 === "" ||
            valueEE20x.EE204 === "" ||
            valueEE20x.EE205 === "") {
            return true;
        } else {
            return false;
        }
    };

    const allOptions = [
        optionsEE201,
        optionsEE202,
        optionsEE203,
        optionsEE204,
        optionsEE205
    ];

    return (
        <AccordionDetails>
            {
                parameterEE20xValue && parameterEE20xValue.length === 5 ? (
                    <>
                        {[...Array(5).keys()].map((index) => (
                            <Box key={index} sx={{ minWidth: 220 }}>
                                <FormControl fullWidth sx={{marginTop: '10px'}}>
                                    <InputLabel id={`select-label-EE20${index + 1}`}>{parameterEE20xValue[index].parameteR_DESCRIPTION}</InputLabel>
                                    <Select
                                        labelId={`select-label-EE20${index + 1}`}
                                        id={`select-EE20${index + 1}`}
                                        value={valueEE20x[`EE20${index + 1}`]}
                                        label={parameterEE20xValue[index].parameteR_DESCRIPTION}
                                        disabled={isReadOnly}
                                        onChange={(event) => handleChangeSelect(event, `EE20${index + 1}`)}>
                                        {allOptions[index] !== null ? (
                                            allOptions[index].map((row, key) => (
                                                <MenuItem key={key} value={row.idLov}
                                                          style={{maxWidth: '100%', whiteSpace: 'normal'}}>
                                                    {row.description}
                                                </MenuItem>
                                            ))) : (<MenuItem value={0}>---</MenuItem>)}
                                    </Select>
                                    <FormHelperText sx={{color: "red"}}>
                                        {parameterEE20xValue[index].isMandatoryOn && !valueEE20x[`EE20${index + 1}`] ? 
                                            `${t('workOrder.parameterMandatory')} ${t(`workOrder.statusesIfs.${parameterEE20xValue[index].isMandatoryOn.toLowerCase()}`)}` : ""}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        ))}
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                            <Button
                                sx={{ marginTop: '10px' }}
                                variant="contained"
                                color="primary"
                                onClick={handleUploadReportQuality}
                                disabled={saving || isButtonDisabeled() || isReadOnly}>
                                {saving ? <CircularProgress size={24} /> : t('save')}
                            </Button>
                        </Typography>
                    </>
                ) : (<></>)
            }

            {
                //Show if parameter EE000 exist on wo
                pEE000.parameteR_ID === "EE000" ? (
                    <ReportQualityEE000 wono={wono} setToastState={setToastState} />
                ) : (<></>)
            }
        </AccordionDetails >
    )
}

export default ReportQuality