import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useMediaQuery, Typography, TextField, Box, Select, MenuItem, InputLabel, CircularProgress, Button, FormControl, FormHelperText } from '@mui/material';

export const ReportParameters = ({ wono, setToastState, isReadOnly }) => {
    const [controlValues, setControlValues] = useState({});
    const [parameters, setParameters] = useState([]);
    const [saving, setSaving] = useState(false);
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const { t } = useTranslation();
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
    const isMobileScreen = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        fetchParameters();
    }, []);

    const fetchParameters = async () => {
        try {
            let url = `${rootUrl}WorkOrder/parameter/report/${wono}`;
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (response.ok) {
                const parameterValue = await response.json();
                console.log(parameterValue);
                setParameters(parameterValue);
            }
        } catch (error) {
            console.error('Error fetching parameter value:', error);
        }
    };

    const updateWorkOrder = async (woData) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(woData)
        };
        let url = `${rootUrl}WorkOrder/wo`;
        const response = await fetch(url, options);

        if (!response.ok) {
            console.log(response);
            setToastState({
                open: true,
                severity: 'error',
                message: t('updateFail'),
            });
        }
        else {
            setToastState({
                open: true,
                severity: 'success',
                message: t('updateSuccess'),
            });
            fetchParameters();
        }
        setSaving(false);
    };

    useEffect(() => {
        const initialValues = {};
        parameters.forEach(item => {
            initialValues[item.parameterId] = item.valueWithLov || '';
        });
        setControlValues(initialValues);
    }, [parameters]);

    const handleDateTimePickerChange = (parameterId, format, date) => {
        if (!isNaN(date)) {
            setControlValues(prevValues => ({
                ...prevValues,
                [parameterId]: date.toISOString().slice(0, format.length - 2).replace('T', ' ')
            }));
        } else {
            setControlValues(prevValues => ({
                ...prevValues,
                [parameterId]: null
            }));
        }
    };

    const handleDatePickerChange = (parameterId, format, date) => {
        if (!isNaN(date)) {
            setControlValues(prevValues => ({
                ...prevValues,
                [parameterId]: date.toISOString().slice(0, format.length)
            }));
        } else {
            setControlValues(prevValues => ({
                ...prevValues,
                [parameterId]: null
            }));
        }
    };

    const handleSelectChange = (parameterId, value) => {
        setControlValues(prevValues => ({
            ...prevValues,
            [parameterId]: value
        }));
    };

    const handleTextFieldChange = (parameterId, value) => {
        setControlValues(prevValues => ({
            ...prevValues,
            [parameterId]: value
        }));
    };

    const handleSave = () => {
        setSaving(true);
        const woData = {
            wO_NO: wono,
            parameteR_CATEGORIES: Object.entries(controlValues).map(([parameterId, value]) => ({
                parameteR_ID: parameterId,
                valuE_WITH_LOV: value || ""
            }))
        };
        updateWorkOrder(woData);
        console.log(woData);
    };

    const renderDateTimePicker = (item) => {
        if (isMobileScreen) {
            if (item.format === "YYYY-MM-DD") {
                return (
                    <>
                        <MobileDatePicker
                            value={controlValues[item.parameterId] ? new Date(controlValues[item.parameterId]) : null}
                            label={item.parameterDescription}
                            onChange={(date) => handleDatePickerChange(item.parameterId, item.format, date)}
                            disabled={isReadOnly}
                            format='yyyy-MM-dd'
                        />
                        <FormHelperText sx={{color: "red"}}>
                            {item.isMandatoryOn && !controlValues[item.parameterId] ? 
                                `${t('workOrder.parameterMandatory')} ${t(`workOrder.statusesIfs.${item.isMandatoryOn.toLowerCase()}`)}` : ""}
                        </FormHelperText>
                    </>
                );
            } else {
                return (
                    <>
                        <MobileDateTimePicker
                            value={controlValues[item.parameterId] ? new Date(controlValues[item.parameterId]) : null}
                            label={item.parameterDescription}
                            onChange={(date) => handleDateTimePickerChange(item.parameterId, item.format, date)}
                            disabled={isReadOnly}
                            format='yyyy-MM-dd HH:mm'
                        />
                        <FormHelperText sx={{color: "red"}}>
                            {item.isMandatoryOn && !controlValues[item.parameterId] ? 
                                `${t('workOrder.parameterMandatory')} ${t(`workOrder.statusesIfs.${item.isMandatoryOn.toLowerCase()}`)}` : ""}
                        </FormHelperText>
                    </>
                );
            }
        } else {
            if (item.format === "YYYY-MM-DD") {
                return (
                    <>
                        <DatePicker
                            value={controlValues[item.parameterId] ? new Date(controlValues[item.parameterId]) : null}
                            label={item.parameterDescription}
                            onChange={(date) => handleDatePickerChange(item.parameterId, item.format, date)}
                            disabled={isReadOnly}
                            format='yyyy-MM-dd'
                        />
                        <FormHelperText sx={{color: "red"}}>
                            {item.isMandatoryOn && !controlValues[item.parameterId] ? 
                                `${t('workOrder.parameterMandatory')} ${t(`workOrder.statusesIfs.${item.isMandatoryOn.toLowerCase()}`)}` : ""}
                        </FormHelperText>
                    </>
                );
            } else {
                return (
                    <>
                        <DateTimePicker
                            value={controlValues[item.parameterId] ? new Date(controlValues[item.parameterId]) : null}
                            label={item.parameterDescription}
                            onChange={(date) => handleDateTimePickerChange(item.parameterId, item.format, date)}
                            disabled={isReadOnly}
                            format='yyyy-MM-dd HH:mm'
                        />
                        <FormHelperText sx={{color: "red"}}>
                            {item.isMandatoryOn && !controlValues[item.parameterId] ? 
                                `${t('workOrder.parameterMandatory')} ${t(`workOrder.statusesIfs.${item.isMandatoryOn.toLowerCase()}`)}` : ""}
                        </FormHelperText>
                    </>
                );
            }
        }
    };

    const renderSpecialParameterHandling = (parameterName) => {
        if (!parameters.some(item => item.parameterId.startsWith(parameterName))) {
            return null;
        }
        const highestId = parameters
        .filter(item => item.parameterId.startsWith(parameterName))
        .map(item => {
            const parts = item.parameterId.split('_');
            return parts.length > 1 ? parseInt(parts[1]) : 1;
        })
        .reduce((max, num) => num > max ? num : max, 0);
        const highestParameterId = `${parameterName}${highestId > 1 ? `_${highestId}` : ''}`;
        const prefix = parameterName;
        const parts = highestParameterId.split('_');
        const suffix = parts[1] ? parseInt(parts[1]) + 1 : 2;
        const nextParameterId = `${prefix}_${suffix}`;

        const hasEmptyParameter = parameters.some(item => 
            item.parameterId.startsWith(parameterName) && (!item.valueWithLov || item.valueWithLov === "")
        );
        const firstDescription = parameters.find(item => item.parameterId.startsWith(parameterName)).parameterDescription;
        const filledParameters = parameters.filter(item => item.parameterId.startsWith(parameterName) && item.valueWithLov);
        const emptyParameter = parameters.find(item => item.parameterId.startsWith(parameterName) && (!item.valueWithLov || item.valueWithLov === ""));
        return(
        <>
            {filledParameters.map(item => (
                item.parameterId.startsWith(parameterName) &&
                <Box key={item.parameterId} sx={{ minWidth: 220 }}>
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                        <>
                            <TextField
                                label={item.parameterDescription}
                                value={controlValues[item.parameterId] || ''}
                                onChange={(e) => handleTextFieldChange(item.parameterId, e.target.value)}
                                multiline={item.paraRows !== ""}
                            />
                        </>        
                    </FormControl>
                </Box>                
            ))
            }
            {emptyParameter && (
                <Box key={emptyParameter.parameterId} sx={{ minWidth: 220 }}>
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                        <>
                            <TextField
                                label={emptyParameter.parameterDescription}
                                value={controlValues[emptyParameter.parameterId] || ''}
                                onChange={(e) => handleTextFieldChange(emptyParameter.parameterId, e.target.value)}
                                multiline={emptyParameter.paraRows !== ""}
                            />
                        </>
                    </FormControl>
                </Box>
            )}
            {
                !emptyParameter && hasEmptyParameter === false && suffix < 5 &&
                <Box key={nextParameterId} sx={{ minWidth: 220 }}>
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                        <>
                            <TextField
                                label={firstDescription}
                                value={controlValues[nextParameterId] || ''}
                                onChange={(e) => handleTextFieldChange(nextParameterId, e.target.value)}
                            />
                        </>        
                    </FormControl>
                </Box>  
            } 
        </>
        );
    };

    return (
        <div>
            {parameters.map(item => (
                !item.parameterId.startsWith("TILLSYNINGSMAN") && !item.parameterId.startsWith("SOS-LEDARE") &&
                <Box key={item.parameterId} sx={{ minWidth: 220 }}>
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                        {
                            item.parametersList ? (
                                <>
                                    <InputLabel id={`select-label-para${item.parameterId}`}>{item.parameterDescription}</InputLabel>
                                    <Select
                                        labelId={`select-label-para${item.parameterId}`}
                                        label={item.parameterDescription}
                                        value={controlValues[item.parameterId] || ''}
                                        disabled={isReadOnly}
                                        onChange={(e) => handleSelectChange(item.parameterId, e.target.value)}
                                    >
                                        {item.parametersList.map(option => (
                                            <MenuItem key={option.id} value={option.idLov}>{option.description}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText sx={{color: "red"}}>
                                        {item.isMandatoryOn && !controlValues[item.parameterId] ? 
                                            `${t('workOrder.parameterMandatory')} ${t(`workOrder.statusesIfs.${item.isMandatoryOn.toLowerCase()}`)}` : ""}
                                    </FormHelperText>
                                </>
                            ) : (
                                item.datatype === 'Date' && item.editable === 'Yes' ? (
                                    <>{renderDateTimePicker(item)}</>
                                ) : (           
                                    <>
                                        <TextField
                                            label={item.parameterDescription}
                                            value={controlValues[item.parameterId] || ''}
                                            onChange={(e) => handleTextFieldChange(item.parameterId, e.target.value)}
                                            multiline={item.paraRows !== ""}
                                            InputProps={{
                                                readOnly: item.editable === "No" || isReadOnly,
                                                inputProps: {
                                                    minLength: item.minLength,
                                                    maxLength: item.maxLength
                                                }
                                            }}
                                        />
                                        <FormHelperText sx={{color: "red"}}>
                                            {item.isMandatoryOn && !controlValues[item.parameterId] ? 
                                                `${t('workOrder.parameterMandatory')} ${t(`workOrder.statusesIfs.${item.isMandatoryOn.toLowerCase()}`)}` : ""}
                                        </FormHelperText>
                                    </>
                                )
                            )
                        }
                    </FormControl>
                </Box>
            ))
            }
            {renderSpecialParameterHandling("TILLSYNINGSMAN")}
            {renderSpecialParameterHandling("SOS-LEDARE")}
            {parameters.length > 0 && (
                <Typography style={{ whiteSpace: 'pre-line' }}>
                    <Button
                        sx={{ marginTop: '10px' }}
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={saving || isReadOnly}>
                        {saving ? <CircularProgress size={24} /> : t('save')}
                    </Button>
                </Typography>
            )}
        </div >
    )
}

export default ReportParameters;