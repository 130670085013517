import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "@mui/material";
const DiaryTabs = ({ selectedTab, handleTabChange }) => {
    const { t } = useTranslation();
    return (
        <Tabs value={selectedTab} onChange={handleTabChange} scrollable="true">
            <Tab label={t('diary.tabs.tab1')} />
            <Tab label={t('diary.tabs.tab2')} />
            <Tab label={t('diary.tabs.tab3')} />
            <Tab label={t('diary.tabs.tab4')} />
            <Tab label={t('diary.tabs.tab5')} />
            <Tab label={t('diary.tabs.tab6')} />
        </Tabs>
    )
}

export default DiaryTabs