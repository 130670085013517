import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";

export default function DiaryDataGrid({ diaryList, addNewDiaryRow, editDiaryRow, deleteDiaryRow, columns, EditToolbar, diaryListOfValue }) {
    const [rows, setRows] = useState(diaryList);
    const [rowModesModel, setRowModesModel] = useState({});
    const { t } = useTranslation();


    useEffect(() => {
        setRows(diaryList);
    }, [diaryList]);

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };
    const handleEditRowsModelChange = (model) => {
        // model is an object containing information about the edited rows
        console.log("Edit Rows Model:", model);
    };
    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        const currentRow = rows.find((row) => row.id === id);
    };

    const handleDeleteClick = (id) => () => {
        deleteDiaryRow(id);
        setRows(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        console.log("newRow --->");
        console.log(newRow);
        if (newRow.hasOwnProperty('isNew') && newRow.isNew) {
            addNewDiaryRow(newRow);
        } else {
            editDiaryRow(newRow);
        }
        //fetchDiaryList();
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const autosizeOptions = {
        includeOutliers: true,
    };

    const actionColumn = [
        {
            field: 'actions',
            type: 'actions',
            headerName: t('diary.actions'),
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const FooterRow = () => {
        const totalDiaryTime = rows.reduce((acc, curr) => acc + curr.diaryTime, 0);
        const totalCost = rows.reduce((acc, curr) => acc + curr.cost, 0);
        const isCostFieldExists = columns.some(column => column.field === 'cost');

        return (
            <div style={{ width: '100%', height: '52px', backgroundColor: '#f7f7f7', paddingTop: '16px' }}>
                <span style={{ marginLeft: '16px', fontWeight: 'bold' }}>{t('diary.totalTime')}: {totalDiaryTime}</span>
                {
                    isCostFieldExists ?
                        <span style={{ marginLeft: '16px', fontWeight: 'bold' }}>{t('diary.totalCost')}: {totalCost}</span> : <></>
                }
            </div>
        );
    };

    columns = [...columns, ...actionColumn];

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >

            <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                autosizeOptions={autosizeOptions}
                onEditRowsModelChange={handleEditRowsModelChange}
                slots={{
                    toolbar: EditToolbar,
                    footer: FooterRow
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel, rows },
                    //pagination: { labelRowsPerPage: t('diary.rowsPerPage'), }
                }}
                autoHeight
                disableExtendRowFullWidth
            />
        </Box>
    );
}
