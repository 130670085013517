import { React, useState, useEffect } from "react";
import AgendaView from "../components/Agenda2";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Snackbar, Alert } from "@mui/material";
import Banner from "../components/Banner";
import { useAuth } from "oidc-react";

const Mainpage = () => {
  const [activeDate, setActiveDate] = useState(null);
  const [successToastState, setSuccessToastState] = useState({
    open: false,
    message: "",
    severity: "success"
  });
  const [bannerText, setBannerText] = useState("");
  const auth = useAuth();
  const accessToken = auth.userData?.access_token;

  useEffect(() => {
    const fetchBannerInfo = async () => {
      try {
        const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
        const response = await fetch(
          `${rootUrl}api/notifications/announcement`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.ok) {
          const jsonData = await response.text();
          setBannerText(jsonData);
        } else {
          console.log(
            "Error fetching banner info. Status:",
            response.status
          );
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchBannerInfo();
  }, []);

  return (
    <div
      style={{ height: "100vh", overflow: "hidden", backgroundColor: "#eee" }}
    >
      <Header />
      <Banner text={bannerText} />
      <div style={{ overflowY: "scroll", height: "calc(100vh - 120px)" }}>
        <AgendaView setActiveDate={setActiveDate} />
        <Snackbar
          open={successToastState.open}
          autoHideDuration={3000}
          onClose={() =>
            setSuccessToastState({ ...successToastState, open: false })
          }
          anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position and alignment
        >
          <Alert
            severity="success"
            variant="filled"
            onClose={() =>
              setSuccessToastState({ ...successToastState, open: false })
            }
          >
            {successToastState.message}
          </Alert>
        </Snackbar>
      </div>

      {/* Footer */}
      <Footer
        setSuccessToastState={setSuccessToastState}
        activeDate={activeDate}
      />
    </div>
  );
};

export default Mainpage;
