import React, { useState, useEffect } from "react";
import styles from "../pages/Notes.module.css";
import { Typography, Box, Card, CardContent } from "@mui/material";
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';


const HistoryLog = ({ wono }) => {
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const { t } = useTranslation();
    const [historyLog, setHistoryLog] = useState([]);
    useEffect(() => {
        const fetchHistoryLog = async () => {
            try {
                const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
                const response = await fetch(`${rootUrl}WorkOrder/historylog/${wono}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const jsonData = await response.json();
                    setHistoryLog(jsonData);
                } else {
                    console.error("Error fetching data. Status:", response.status);
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchHistoryLog();
    }, []);

    return (
        <Box sx={{ marginTop: -3 }}>
            {historyLog.map((row, index) => (
                <div key={index}>
                    <CardContent sx={{ border: '1px solid lightgray', borderRadius: '10px', margin: '15px' }}>
                        <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
                            {row.timeStamp.replace('T', ' ')}
                        </Typography>

                        <Typography variant="body2">{t('workOrder.historyLog.type')}: {row.columnName}</Typography>
                        <Typography variant="body2">{t('workOrder.historyLog.user')}: {row.username}</Typography>

                        <Typography variant="body2">
                            {t('workOrder.historyLog.oldValue')}: {row.oldValue}
                        </Typography>
                        <Typography variant="body2">
                            {t('workOrder.historyLog.newValue')}: {row.newValue}
                        </Typography>
                    </CardContent>
                </div>
            ))}
        </Box>
    );
}

export default HistoryLog