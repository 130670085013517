import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate, useNavigate,
} from "react-router-dom";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import Attachments from "./pages/Attachments";
import Materials from "./pages/Materials";
import Notes from "./pages/Notes";
import Profile from './pages/Profile';
import { useEffect } from "react";
import Mainpage from "./pages/Mainpage";
import { AuthProvider, useAuth } from 'oidc-react';
import NotificationSettings from './pages/settings/Notifications'
import BroadcastView from "./pages/Broadcast";
import ExtraReport from "./pages/ExtraReport";
import WorkOrderMap from "./pages/WorkOrderMap";
import Diary from "./diary/Diary";
import {LanguageProvider} from "./components/LanguageProvider";
import TaskWrapper from "./pages/Task";
import {ToastStateProvider} from "./components/ToastStateProvider";
import News from "./pages/News";

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  clientId: `${process.env.REACT_APP_CLIENT_ID}`,
  redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URI,
  scope: `openid ${process.env.REACT_APP_API_WORKORDERS_READ_SCOPE}`,
  loadUserInfo: process.env.REACT_APP_OIDC_LOAD_USERINFO === 'true',
};

function Login() {
  const auth = useAuth();

  if (auth && auth.userData) {
    // If user data is available, it means the user is authenticated
    return <Navigate to="/" replace />;
  }

  return (
    <div>
      <h2>Login</h2>
      <button onClick={auth.signIn}>Login with OIDC</button>
    </div>
  );
}

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  const theme = createTheme({
    palette: {
      background: {
        default: "#eee"
      }
    }
  });

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "TAPP";
        metaDescription = "";
        break;
      case "/attachments":
        title = "TAPP-Attachments";
        metaDescription = "";
        break;
      case "/materials":
        title = "TAPP-Materials";
        metaDescription = "";
        break;
      case "/Notes":
        title = "TAPP-Notes";
        metaDescription = "";
        break;
      case "/task":
        title = "Task";
        metaDescription = "";
        break;
      case "/report":
        title = "Report";
        metaDescription = "";
        break;
      case "/news":
        title = "News";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);


  const storeCurrentPath = () => {
    const currentPath = location.pathname + location.search + location.hash;
    sessionStorage.setItem('currentPath', currentPath);
  }

  const redirectToPreviousPath = () => {
    navigate(sessionStorage.getItem('currentPath') ?? '/');
  }

  return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider {...oidcConfig} onBeforeSignIn={storeCurrentPath} onSignIn={redirectToPreviousPath}>
            <LanguageProvider>
              <ToastStateProvider>
                <AppContent />
              </ToastStateProvider>
            </LanguageProvider>
          </AuthProvider>
        </ThemeProvider>
  );
}

function AppContent() {
  const auth = useAuth();
  const isAuthenticated = auth && auth.userData;
  if (isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/attachments/:wono" element={<Attachments />} />
        <Route path="/materials/:wono" element={<Materials />} />
        <Route path="/notes/:wono" element={<Notes />} />
        <Route path="/task/:wono" element={<TaskWrapper />} />
        <Route path="/login" element={<Login />} />
        <Route path="/settings/notifications" element={<NotificationSettings />} />
        <Route path="/broadcast" element={<BroadcastView />} />
        <Route path="/workordermap/:mode?" element={<WorkOrderMap />} />
        <Route path="/report/:wono" element={<ExtraReport />} />
        <Route path="/diary/:wono" element={<Diary />} />
        <Route path="/news" element={<News />} />
      </Routes>
    );
  } else {
    return <></>;
  }
}

export default App;
