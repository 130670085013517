import React, { useState, useEffect } from 'react';
import DiaryDataGrid from './DiaryDataGrid';
import { useAuth } from 'oidc-react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import {
    GridRowModes,
    GridToolbarContainer,
    GridEditInputCell
} from '@mui/x-data-grid';

const ConstructionManagerTab = ({ wono }) => {
    const [diaryList, setDiaryList] = useState([]);
    const [diaryListOfValue, setDiaryListOfValue] = useState([]);
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
    const { t } = useTranslation();

    useEffect(() => {
        fetchDiaryList();
        fetchDiaryListOfValue();
    }, []);

    const fetchDiaryList = async () => {
        if (!accessToken) return;
        try {
            const url = `${rootUrl}Diary/list/${wono}/CONST`;
            console.log("URL = " + url);
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error("Failed to fetch diary list.");
            }

            const data = await response.json();
            setDiaryList(data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchDiaryListOfValue = async () => {
        if (!accessToken) return;
        try {
            const url = `${rootUrl}Diary/listOfValue/${wono}/PROJ`;
            console.log("URL = " + url);
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error("Failed to fetch diary list of value.");
            }

            const data = await response.json();
            setDiaryListOfValue(data);
        } catch (error) {
            console.error(error);
        }
    };

    const addNewDiaryRow = async (diaryRow) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(diaryRow)
        };
        let url = `${rootUrl}Diary/add`;
        const response = await fetch(url, options);

        if (response.ok) {
            console.log(response);
        }
        else {
            console.error(response);
        }
        fetchDiaryList();
    };

    const editDiaryRow = async (diaryRow) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(diaryRow)
        };
        let url = `${rootUrl}Diary/edit`;
        const response = await fetch(url, options);

        if (response.ok) {
            console.log(response);
        }
        else {
            console.error(response);
        }
    };

    const deleteDiaryRow = async (id) => {
        if (!accessToken) return;
        try {
            const url = `${rootUrl}Diary/delete/${id}`;
            console.log("URL = " + url);
            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                console.error(response);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const formatDate = (date) => {
        if (date === "") {
            return "";
        } else {
            return new Date(date);
        }
    };

    function EditToolbar(props) {
        const { setRows, setRowModesModel, rows } = props;
        const { t } = useTranslation();
        const handleClick = () => {
            const maxId = Math.max(...rows.map(row => row.id), 0);
            const id = maxId + 1;
            setRows((oldRows) => [...oldRows, {
                id, woNo: wono, createdTime: '', userId: '',
                diaryTime: null, diaryText: '', visible: true, isNew: true
            }]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'createdTime' },
            }));
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    {t('diary.addRow')}
                </Button>
            </GridToolbarContainer>
        );
    }

    const valueOptionsMapping = [
        { value: 'DES', label: t('diary.designer') },
        { value: 'FDES', label: t('diary.fullDesigner') },
        { value: 'PLEAD', label: t('diary.projectLead') },
        { value: 'WMASTER', label: t('diary.summary.workMaster') },
    ]

    const formatList = (val) => {
        const mapping = valueOptionsMapping.find(item => item.value === val);
        return mapping ? mapping.label : val;
    };

    const formatListWorkAndTime = (val) => {
        const mapping = diaryListOfValue.find(item => item.value === val);
        return mapping ? mapping.label : val;
    };

    const columns = [
        {
            field: 'createdTime',
            headerName: t('diary.date'),
            type: 'dateTime',
            width: 200,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            valueGetter: (params) => formatDate(params.row.createdTime)
        },
        {
            field: 'userId',
            headerName: t('diary.performer'),
            width: diaryListOfValue.length === 0 ? 180 : 120,
            editable: true,
            preProcessEditCellProps: (params) => {
                if (params.props.value) {
                    const hasError = params.props.value.length > 100;
                    return { ...params.props, error: hasError };
                } else {
                    return { ...params.props, error: false };
                }
            },
            renderEditCell: (params) => (
                <GridEditInputCell {...params} inputProps={{ maxLength: 100 }} />
            ),
        },
        {
            field: 'userRole',
            headerName: t('diary.role'),
            width: diaryListOfValue.length === 0 ? 180 : 220,
            editable: true,
            type: 'singleSelect',
            valueOptions: valueOptionsMapping,
            valueFormatter: (params) => formatList(params.value)
        },
        {
            field: 'diaryTime',
            headerName: t('diary.hours'),
            width: 80,
            editable: true,
            type: 'number',
        },
        {
            field: 'timeCode',
            headerName: t('diary.timeCode'),
            width: 320,
            editable: true,
            type: 'singleSelect',
            valueOptions: diaryListOfValue.filter(item => item.type === "TIME"),
            valueFormatter: (params) => formatListWorkAndTime(params.value)
        },
        {
            field: 'workCode',
            headerName: t('diary.work'),
            width: 320,
            editable: true,
            type: 'singleSelect',
            valueOptions: diaryListOfValue.filter(item => item.type === "WORK"),
            valueFormatter: (params) => formatListWorkAndTime(params.value)
        },
        {
            field: 'diaryText',
            headerName: t('diary.description'),
            width: diaryListOfValue.length === 0 ? 500 : 400,
            editable: true,
        },
    ];

    const filteredColumns = columns.filter(column => {
        if (diaryListOfValue.length === 0) {
            return column.field !== 'timeCode' && column.field !== 'workCode';
        }
        return true;
    });

    return (
        <div>

            <DiaryDataGrid diaryList={diaryList} addNewDiaryRow={addNewDiaryRow}
                editDiaryRow={editDiaryRow} deleteDiaryRow={deleteDiaryRow} columns={filteredColumns} EditToolbar={EditToolbar} />

        </div>
    )
}

export default ConstructionManagerTab