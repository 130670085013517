import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from '../components/Footer';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ReactDOMServer from 'react-dom/server';
import { useAuth } from 'oidc-react';
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';
import { useParams } from "react-router-dom";

const containerStyle = {
    width: '100%',
    height: '100%'
  };

  const getHatIconPath = () =>  {
    const iconString = ReactDOMServer.renderToString(<EngineeringIcon />);
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(iconString, 'image/svg+xml');
    const iconPath = svgDoc.querySelector('path')?.getAttribute('d');
  
    return iconPath;
  };

const WorkOrderMap = () => {
  const { t } = useTranslation();
  const [workOrders, setWorkOrders] = useState([]);
  const [map, setMap] = useState(null);
  const [bounds, setBounds] = useState(null);
  const { mode } = useParams();
  const selectedDateKey = 'selectedDate';
  const storedDateStr = sessionStorage.getItem(selectedDateKey);
  const selectedEndDateKey = 'selectedEndDate';
  const storedEndDateStr = sessionStorage.getItem(selectedEndDateKey);
  const selectedDate = storedDateStr ? new Date(storedDateStr) : new Date();
  const selectedEndDate= storedEndDateStr ? new Date(storedEndDateStr) : null;
  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;

  const auth = useAuth();
  const accessToken = auth.userData?.access_token;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const getWorkOrdersForDate = async (startDate, endDate) => {
    try {
      const formattedDate = format(startDate, 'yyyy-MM-dd');
      let url = `${rootUrl}api/Eos/calendar/${formattedDate}`;
      if (endDate && endDate.getTime() > startDate.getTime()) {
        const formattedDateEndDate = format(endDate, 'yyyy-MM-dd');
        url = `${rootUrl}api/Eos/calendar/range?start=${formattedDate}&end=${formattedDateEndDate}`;
      }
      
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      setWorkOrders(data.workorders);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getBroadcastTasks = async () => {
    try {
        const response = await fetch(`${rootUrl}workorder/broadcast`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setWorkOrders(data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      if (mode === "broadcast") {
        getBroadcastTasks();
      }
      else {
        getWorkOrdersForDate(selectedDate, selectedEndDate);
      }
    }
  }, [accessToken]);

  useEffect(() => {
    if (isLoaded && map && workOrders.length > 0) {

      for (let count = 0; count < workOrders.length; count++) {
        const lat = workOrders[count].coordinates.latitude.replace(',', '.');
        const lng = workOrders[count].coordinates.longitude.replace(',', '.');

        if (lat === '' || lng === '')
          continue;

          const marker = new google.maps.Marker({
              position: new google.maps.LatLng(lat, lng),
              map: map,
              title: workOrders[count].title
          });

          bounds.extend(marker.position);

          let infowindow =  new google.maps.InfoWindow({});

          google.maps.event.addListener(marker, 'click', (function (marker, count) {
              return function () {
                let content = `${workOrders[count].id} - ${workOrders[count].title}<br />\
                                ${workOrders[count].address}<br />\
                                <a href="/task/${workOrders[count].id}">${t('workOrderMap.showWorkOrder')}</a>`;
                  infowindow.setContent(content);
                  infowindow.open(map, marker);
              }
          })(marker, count));
      }

      map.fitBounds(bounds);
    }
  }, [workOrders, isLoaded, map]);

  const onMapClick = () => {
    // Already viewing the map?
  };

  const onLoad = React.useCallback(function callback(map) {
    let bounds = new google.maps.LatLngBounds();

    //GEOLOCATION
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        const svgMarker = {
          path: getHatIconPath(),
          fillColor: "#E67620",
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: 0,
          scale: 2,
          anchor: new google.maps.Point(0, 20),
        };

        const marker = new google.maps.Marker({
          position: pos,
          map,
          icon: svgMarker,
          title: 'Din position'
        });

        let infoWindow = new google.maps.InfoWindow();
        infoWindow.setContent(marker.title);
        infoWindow.open(map, marker);
        google.maps.event.addListener(marker, "click", function (e) {
          infoWindow.open(map, marker);
        });
          
          bounds.extend(pos);
      });
    }

    map.fitBounds(bounds);

    setBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <div style={{ height: "100vh", overflow: "hidden", backgroundColor: "#eee" }}>
    <Header />

    <div style={{ overflowY: "scroll", height: "calc(100vh - 120px)" }}>
{
    isLoaded && workOrders !== null ? 
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
    <></>
      </GoogleMap>
   : <></>
}
  </div>

      {/* Footer */}
      <Footer onMapClick={onMapClick} />
    </div>
  )
};

export default WorkOrderMap;
