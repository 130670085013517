
import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, Autocomplete,
    TextField, Button, IconButton, Snackbar, Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAuth } from 'oidc-react';
import { useTranslation } from "react-i18next";

const SearchProductModual = ({ open, onClose, mainProductId, wono, setCounter }) => {
    const { t } = useTranslation();
    const [searchResults, setSearchResults] = useState(null);
    const [products, setProducts] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [isProcessing, setIsProcessing] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
    const preferred_username = auth.userData?.profile?.preferred_username?.toUpperCase();
    const [toastState, setToastState] = useState({
        open: false,
        severity: '',
        message: '',
    });
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`${rootUrl}WorkOrder/products/agreement/${wono}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch most products.");
                }

                const data = await response.json();

                if (Array.isArray(data)) {
                    setProducts(data);
                } else {
                    console.warn("Unexpected data format received from API. Expected an array.");
                    setProducts([]);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchProducts();
    }, []);

    const saveProduct = () => {
        setIsProcessing(true);
        const woData =
        {
            wO_NO: wono,
            worK_ORDER_CODINGS: [
                {
                    cataloG_NO: selectedProduct.value,
                    qtY_TO_INVOICE: quantity,
                    qty: quantity,
                    emP_NO: preferred_username,
                    worK_ORDER_COST_TYPE: "E" //External
                }
            ]
        };
        updateWorkOrder(woData);
    }

    const updateWorkOrder = async (woData) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(woData)
        };
        let url = `${rootUrl}WorkOrder/wo`;
        const response = await fetch(url, options);

        if (response.ok) {
            setToastState({
                open: true,
                severity: 'success',
                message: t('updateSuccess'),
            });
            setCounter(0);
        }
        else {
            setToastState({
                open: true,
                severity: 'error',
                message: t('updateFail'),
            });
        }
        setIsProcessing(false);
    };

    const handleSearch = async (event) => {
        const query = event.target.value;

        if (query.length > 2) {
            try {
                const response = await fetch(`/api/Eos/PartSearch?query=${query}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error("Failed to search for parts.");
                }

                const data = await response.json();
                setSearchResults(data);
            } catch (error) {
                console.error(error);
            }
        } else {
            setSearchResults(mostUsedProducts);
        }
    };

    const increaseQuantity = () => {
        setQuantity(prev => prev + 0.25);
    }

    const decreaseQuantity = () => {
        setQuantity(prev => prev - 0.25);
    }

    const handleOptionSelect = (event, value) => {
        console.log(`Selected option --->`);
        console.log(value);
        setSelectedProduct(value);
    };

    const isOptionEqualToValue = (option, value) => option.value === value.value;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle> {t('workOrder.materials.searchProduct.title')}</DialogTitle>
            <DialogContent>

                <div style={{ display: 'flex', alignItems: 'center', margin: '16px 0' }}>
                    <Autocomplete
                        options={products.map((product) => ({
                            value: product.catalogNo,
                            label: `${product.catalogNo} - ${product.catalogDesc}`
                        }))}
                        getOptionLabel={(option) => option.label}
                        id="controllable-states-demo"
                        renderInput={(params) => <TextField {...params} label={t('workOrder.materials.subMaterials.selectSalePart')} fullWidth />}
                        value={selectedProduct}
                        onChange={handleOptionSelect}
                        isOptionEqualToValue={isOptionEqualToValue}
                        fullWidth
                    />
                </div>
                {/* Quantity Counter */}
                <div style={{ display: 'flex', alignItems: 'center', margin: '16px 0' }}>
                    <IconButton onClick={decreaseQuantity}>
                        <RemoveIcon />
                    </IconButton>
                    <TextField
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        style={{ width: '100px', textAlign: 'center' }}
                    />
                    <IconButton onClick={increaseQuantity}>
                        <AddIcon />
                    </IconButton>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={saveProduct} color="primary" disabled={isProcessing}>
                    {t('add')}
                </Button>
                <Button onClick={onClose} color="primary">
                    {t('close')}
                </Button>
            </DialogActions>
            <Snackbar
                open={toastState.open}
                autoHideDuration={6000}
                onClose={() => setToastState({ ...toastState, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position and alignment
                style={{ position: 'absolute', zIndex: 1301 }} // Adjust zIndex to be above other elements
            >
                <Alert
                    severity={toastState.severity}
                    variant="filled"
                    onClose={() => setToastState({ ...toastState, open: false })}
                >
                    {toastState.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}

export default SearchProductModual