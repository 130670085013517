import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import { statusMapping, selectableStatuses } from '../Utils.js';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


const StatusButton = ({ taskId, woStatus, setToastState, agreementType, setStatusChangeErrorCodes, eventControl }) => {
  const { t } = useTranslation();
  const statusColors = t('workOrder.statusColors', { returnObjects: true });
  //const statusMap = t('workOrder.statusMap', { returnObjects: true });
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState("");
  const [buttonColor, setButtonColor] = useState(statusMapping(woStatus).stateColor);
  const [oldObjState, setOldObjState] = useState(woStatus);
  const [statuses, setStatuses] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const open = Boolean(anchorEl);
  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
  const auth = useAuth();
  const accessToken = auth.userData?.access_token;
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleClick = (event) => {
    if (oldObjState !== 'WORKDONE') {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    (async () => {
      if (woStatus) {
        console.log("woStatus = " + woStatus);
        setButtonColor(statusMapping(woStatus).stateColor);
        const contractType = agreementType === 'BUILD' ? 'BUILD' : 'SERVICE';
        if (woStatus === "RELEASED") {
          const prevState = await fetchPreviousState();
          setOldObjState(prevState);
          setStatuses(selectableStatuses(woStatus, contractType, prevState, eventControl));
        }
        else if (woStatus !== "RELEASED") {
          setOldObjState(woStatus);
          setStatuses(selectableStatuses(woStatus, contractType, oldObjState, eventControl));
        }

        setStatus(statusMapping(woStatus).stateName);
      }
    })();
  }, [woStatus]);

  const savePreviousState = async () => {
    if (oldObjState === "") {
      return;
    }
    const preState = { woNo: taskId, status: oldObjState };
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(preState)
    };
    const url = `${rootUrl}WorkOrder/previousState`;
    const response = await fetch(url, options);

    if (!response.ok) {
      console.error(response);
    }
  };

  const fetchPreviousState = async () => {
    try {
      const url = `${rootUrl}WorkOrder/previousState/${taskId}`;
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status !== "") {
          return data.status;
        }
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error('Error fetching previous status, ', error);
    }
  };

  const handleStatusChange = async (newStatus) => {
    setIsProcessing(true);

    if (newStatus.objState === "RELEASED") {
      savePreviousState();
    }

    handleClose();

    updateWorkOrder(newStatus);
    //setOldObjState(newStatus.objState);
  };

  const updateWorkOrder = async (newStatus) => {
    let woData =
    {
      wO_NO: taskId,
      wO_STATUS_ID: newStatus.objState
    };

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(woData)
    };
    let url = `${rootUrl}WorkOrder/wo`;
    const response = await fetch(url, options);
    const jsonResponse = await response.json();

    if (!response.ok || jsonResponse.isError === true) {
      let errorMessage = t('updateFail');
      
      if (jsonResponse.isError === true) {
        if (jsonResponse.errors.some(e => e.code === "ORA-20132")) {
          errorMessage = t('updateFail_code_ORA_20132');
        }
        else if (jsonResponse.errors.some(e => e.code === "WORKORDER_STATUS_CHANGE")) {
          let errorCodes = [];
          errorMessage = t('workOrder.agreementRules.errorMessages.heading');
          jsonResponse.errors.forEach((error) => {
            if (error.code === "DATE_PASSED") {
              errorMessage += `${t('workOrder.agreementRules.errorMessages.' + error.code).replace('{parameter}', error.message)}\n\n`;
            } else if (error.code !== "WORKORDER_STATUS_CHANGE" && error.code !== "MISSING_PARAMETER") {
              errorMessage += `${t('workOrder.agreementRules.errorMessages.' + error.code)}\n\n`;
              errorCodes.push(error.code);
            }
            else if (error.code === "MISSING_PARAMETER") {
              errorMessage += `${t('workOrder.statusChange.missingParameterError').replace('{parameter}', error.message).replace('{status}', statusMapping(newStatus.objState).stateName)}\n\n`;
            }
          });
          setStatusChangeErrorCodes(errorCodes);
        }
      }

      setToastState({
        open: true,
        severity: 'error',
        message: errorMessage,
        autoHideDuration: null
      });
      // setStatus(statusMapping(oldObjState).stateName);
      // setStatuses(selectableStatuses(oldObjState, "SERVICE", oldObjState));
    }
    else {
      const contractType = agreementType === 'BUILD' ? 'BUILD' : 'SERVICE';
      setStatus(newStatus.stateName);
      setStatuses(selectableStatuses(newStatus.objState, contractType, oldObjState, eventControl));
      setButtonColor(statusMapping(newStatus.objState).stateColor);
      if (newStatus.objState !== "RELEASED") {
        setOldObjState(newStatus.objState);
      }
    }

    setIsProcessing(false);
  };

  return (
    <div>
      <Button
        id="status-button"
        aria-controls={open ? 'status-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={statuses.length > 0 && <KeyboardArrowDownIcon />}
        style={{
          backgroundColor: isProcessing ? "#eaeaea" : buttonColor,
          color: '#303030',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          maxWidth: isMobile ? '150px' : 'none'
        }}
        disabled={isProcessing}
      >
        {status}
      </Button>
      <StyledMenu
        id="status-menu"
        MenuListProps={{
          'aria-labelledby': 'status-button',
        }}
        anchorEl={anchorEl}
        open={open && statuses.length > 0}
        onClose={handleClose}
      >
        {
          statuses.length > 0 && (
            statuses.map((statusRow, key) => (
              <MenuItem
                key={key}
                onClick={() => handleStatusChange(statusRow)}
                disableRipple
                style={{
                  paddingLeft: '24px',
                  borderLeft: `4px solid ${statusRow.stateColor}`,
                }}
                value={statusRow}
                selected={statusRow == status}
              >
                {statusRow.stateName}
              </MenuItem>
            ))
          )}
      </StyledMenu>
    </div>
  );
};

export default StatusButton;
