import React, { useState, useEffect } from 'react';
import { Select, InputLabel, MenuItem, FormControl, 
    Typography, Box, AccordionDetails, CircularProgress, Button, FormHelperText, TextField } from '@mui/material';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';

const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;

const TeliaPulsCode = ({ wono, setToastState, pulsCode }) => {
    const optionsKf010b_9300 = [
        { value: "8A", name: "Arbetet omfattas av Arbifas Fiber Plus FKF1P" },
        { value: "8B", name: "Offert inkommer omfattas ej av Arbifas Fiber Plus FKF1P" }
    ];
    const optionsFkfpp_9300 = [
        { value: "8A", name: "Arbetet omfattas av Arbifas Fiber Plus FKF1P" },
        { value: "8B", name: "Offert inkommer omfattas ej av Arbifas Fiber Plus FKF1P" },
        { value: "8C", name: "Fastighetsnät får ej byggas" }
    ];
    const optionsFrek1_9300 = [
        { value: "7A", name: "Fiber reklamation på grund av uppdragsägande entreprenör" },
        { value: "7B", name: "Fiber reklamation på grund av brukare/ slutkund eller operatör" },
        { value: "7D", name: "Fiber reklamation på grund av TeliaSonera" },
        { value: "7E", name: "Fiber reklamation på grund av annan entreprenör" }
    ];
    const optionsFrek1_9301 = [ //if 7D
        { value: "00", name: "Ej Specificerat" },
        { value: "S80", name: "Överlämning" }
    ];
    const optionsFrek1_9302 = [ //if S80
        { value: "00", name: "Ej Specificerat" },
        { value: "600FIB", name: "Kabelfel Fiber" },
    ];
    let woData = "";
    const [value9300, setValue9300] = useState('');
    const [mandatory9300, setMandatory9300] = useState(null);
    const [value9301, setValue9301] = useState('');
    const [value9302, setValue9302] = useState('');

    const [p9980, setP9980] = useState('');

    const [saving, setSaving] = useState(false);
    const [optionsP9300, setOptionsP9300] = useState(null);
    const [optionsP9301, setOptionsP9301] = useState(null);
    const [optionsP9302, setOptionsP9302] = useState(null);
    const [textToCustomer, setTextToCustomer] = useState("");
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const { t } = useTranslation();

    useEffect(() => {
        const fetchParameterValue = async () => {
            try {
                let url = `${rootUrl}WorkOrder/parameter/${wono}/9980`;
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const parameterValue = await response.json();
                    console.log(parameterValue);
                    setP9980(parameterValue);
                }
            } catch (error) {
                console.error('Error fetching parameter value:', error);
            }
        };
        fetchParameterValue();
    }, []);

    useEffect(() => {
        if (p9980 && p9980.valuE_WITH_LOV.trim() !== '') {
            if (p9980.valuE_WITH_LOV.includes('FKFPP')) {
                setOptionsP9300(optionsFkfpp_9300);
            } else if (p9980.valuE_WITH_LOV.includes('FREK1')) {
                setOptionsP9300(optionsFrek1_9300);
                setOptionsP9301(optionsFrek1_9301);
                setOptionsP9302(optionsFrek1_9302);
            } else if (p9980.valuE_WITH_LOV.includes('KF010B')) {
                setOptionsP9300(optionsKf010b_9300);
            }
        }
    }, [p9980]);

    useEffect(() => {
        if (pulsCode) {
            let obj = pulsCode.find(item => item.parameteR_ID === "9300")
            if (obj) {
                setValue9300(obj.valuE_WITH_LOV);
                setMandatory9300(obj.isMandatoryOn);
            }
            obj = pulsCode.find(item => item.parameteR_ID === "9301")
            if (obj) {
                setValue9301(obj.valuE_WITH_LOV);
            }
            obj = pulsCode.find(item => item.parameteR_ID === "9302")
            if (obj) {
                setValue9302(obj.valuE_WITH_LOV);
            }
        }
    }, [pulsCode]);

    function isButtonDisabeled() {
        let ret = false;
        if (p9980.valuE_WITH_LOV === undefined || 
            (!p9980.valuE_WITH_LOV.includes('FKFPP') && !p9980.valuE_WITH_LOV.includes('FREK1') && !p9980.valuE_WITH_LOV.includes('KF010B'))) {
            ret = true;
        } else {
            if (p9980.valuE_WITH_LOV.includes('FKFPP') || p9980.valuE_WITH_LOV.includes('KF010B')) {
                if (value9300 === "") {
                    ret = true;
                }
            } else if (p9980.valuE_WITH_LOV.includes('FREK1')) {
                if (value9300 === "") {
                    ret = true;
                } else if (value9300 === "7D") {
                    if (value9301 === "") {
                        ret = true;
                    } else if (value9301 === "S80") {
                        if (value9302 === "") {
                            ret = true;
                        }
                    }
                }
            }
        }
        return ret;
    };

    const handleUploadPulsCode = async () => {
        setSaving(true);
        woData =
        {
            wO_NO: wono,
            performeD_ACTION_LO: textToCustomer,
            parameteR_CATEGORIES: [
                {
                    parameteR_ID: "9300",
                    valuE_WITH_LOV: value9300 === "" ? "00" : value9300
                },
                {
                    parameteR_ID: "9301",
                    valuE_WITH_LOV: value9301 === "" ? "00" : value9301
                },
                {
                    parameteR_ID: "9302",
                    valuE_WITH_LOV: value9302 === "" ? "00" : value9302
                },
                {
                    parameteR_ID: "9303",
                    valuE_WITH_LOV: "00"
                },
                {
                    parameteR_ID: "9304",
                    valuE_WITH_LOV: "00"
                },
                {
                    parameteR_ID: "9305",
                    valuE_WITH_LOV: "00"
                }
            ]
        };
        updateWorkOrder();
    };

    const updateWorkOrder = async () => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(woData)
        };
        let url = `${rootUrl}WorkOrder/wo`;
        const response = await fetch(url, options);

        if (!response.ok) {
            console.log(response);
            setToastState({
                open: true,
                severity: 'error',
                message: t('updateFail'),
            });
        }
        else {
            setToastState({
                open: true,
                severity: 'success',
                message: t('updateSuccess'),
            });
        }
        setSaving(false);
    };

    const handleChange9300 = (event) => {
        console.log("Val = " + event.target.value);
        setValue9300(event.target.value);
        setValue9301("");
        setValue9302("");
    };
    const handleChange9301 = (event) => {
        setValue9301(event.target.value);
        setValue9302("");
    };
    const handleChange9302 = (event) => {
        setValue9302(event.target.value);
    };
    function countLettersWithoutSpaces(str) {
        const stringWithoutSpaces = str.replace(/\s/g, '');
        return stringWithoutSpaces.length;
    }
    return (
        <AccordionDetails>
            <Box sx={{ minWidth: 220 }}>
                <FormControl fullWidth>
                    <InputLabel id="select-label-9300">{t('workOrder.extraReport.cause')}</InputLabel>
                    <Select
                        labelId="select-label-9300"
                        id="select-9300"
                        value={value9300}
                        label={t('workOrder.extraReport.cause')}
                        onChange={handleChange9300}>
                        {optionsP9300 !== null ? (
                            optionsP9300.map((row, key) => (
                                <MenuItem key={key} value={row.value}>{row.value} - {row.name}</MenuItem>
                            ))) : (<MenuItem value={0}>---</MenuItem>)}
                    </Select>
                    <FormHelperText sx={{color: "red"}}>
                        {mandatory9300 && !value9300 ? 
                            `${t('workOrder.parameterMandatory')} ${t(`workOrder.statusesIfs.${mandatory9300.toLowerCase()}`)}` : ""}
                    </FormHelperText>
                </FormControl>
            </Box>
            {
                optionsP9301 !== null && value9300 === "7D" ? (
                    <Box sx={{ minWidth: 320 }}>
                        <FormControl fullWidth sx={{ marginTop: '10px' }}>
                            <InputLabel id="select-label-9301">{t('workOrder.extraReport.worktype')}</InputLabel>
                            <Select
                                labelId="select-label-9301"
                                id="select-9301"
                                value={value9301}
                                label={t('workOrder.extraReport.worktype')}
                                onChange={handleChange9301}>
                                {
                                    optionsP9301.map((row, key) => (
                                        <MenuItem key={key} value={row.value}>{row.value} - {row.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                ) : (<></>)
            }
            {
                optionsP9302 !== null && value9301 === "S80" ? (
                    <Box sx={{ minWidth: 320, marginTop: '10px' }}>
                        <FormControl fullWidth>
                            <InputLabel id="select-label-9302">{t('workOrder.extraReport.location12')}</InputLabel>
                            <Select
                                labelId="select-label-9302"
                                id="select-9302"
                                value={value9302}
                                label={t('workOrder.extraReport.location12')}
                                onChange={handleChange9302}>
                                {
                                    optionsP9302.map((row, key) => (
                                        <MenuItem key={key} value={row.value}>{row.value} - {row.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                ) : (<></>)
            }
            <Box sx={{ minWidth: 320, marginTop: '10px' }}>
                <FormControl fullWidth>
                    <TextField
                        label={t('workOrder.extraReport.textToCustomer')}
                        variant="outlined"
                        value={textToCustomer}
                        onChange={(e) => setTextToCustomer(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <FormHelperText sx={{color: "red"}}>
                        {mandatory9300 && !textToCustomer ? 
                            `${t('workOrder.parameterMandatory')} ${t(`workOrder.statusesIfs.${mandatory9300.toLowerCase()}`)}` : ""}
                    </FormHelperText>
                </FormControl>
            </Box>
            <Typography style={{ whiteSpace: 'pre-line' }}>
                <Button
                    sx={{ marginTop: '10px' }}
                    variant="contained"
                    color="primary"
                    onClick={handleUploadPulsCode}
                    disabled={saving || isButtonDisabeled() || countLettersWithoutSpaces(textToCustomer) < 5}>
                    {saving ? <CircularProgress size={24} /> : t('save')}
                </Button>
            </Typography>
        </AccordionDetails>
    )
}

export default TeliaPulsCode