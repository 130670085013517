import styles from "./GoogleMapsQuestionPopup.module.css";
import { useTranslation } from "react-i18next";
import { useAuth } from 'oidc-react';
import { useParams } from "react-router-dom";

const SelfAssignModal = ({ onClose, setShowSelfAssignButton, setDisableSelfAssignButton }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const accessToken = auth.userData?.access_token;
  const username = auth.userData?.profile?.preferred_username?.toUpperCase();
  const { wono } = useParams();
  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
  const selfAssignWorkOrder = async () => {
    setDisableSelfAssignButton(true);
    const woData =
    {
      wO_NO: wono,
      worK_MASTER_SIGN_ID: username
    };
    updateWorkOrder(woData);
    onClose(false);
  }

  const updateWorkOrder = async (woData) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(woData)
    };
    let url = `${rootUrl}WorkOrder/wo`;
    const response = await fetch(url, options);

    if (!response.ok) {
      console.error('Assigning to self error');
      console.error(response);
    }
    else {
      setShowSelfAssignButton(false);
    }
    setDisableSelfAssignButton(false);
  };

  return (
    <div className={styles.modalExamples} style={{ height: "180px" }}>
      <div className={styles.header}>
        <div className={styles.text}>
          {t('workOrder.assignWorkOrder')}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.text1}>{t('workOrder.assignWorkOrderQuestion')}</div>
      </div>
      <div className={styles.footer} style={{ top: "120px" }}>
        <div className={styles.button}>
          <div className={styles.button1}>
            <div className={styles.text2} onClick={onClose}>{t('close')}</div>
          </div>
        </div>
        <div className={styles.button}>
          <div className={styles.button1}>
            <div className={styles.text2} onClick={selfAssignWorkOrder}>OK</div>
          </div>
        </div>
      </div>
      <div className={styles.close}>
        <img className={styles.xIcon} alt="" src="/x.svg" onClick={onClose} />
      </div>
    </div>
  );
};

export default SelfAssignModal;
