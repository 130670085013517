import styles from "./SupportModal.module.css";
import { useTranslation } from "react-i18next";

const SupportModal = ({ onClose }) => {
    const { t } = useTranslation();

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return (
        <div className={styles.supportModal}>
            <div className={styles.header}>
                <div className={styles.text}>
                    {t('support')}
                </div>
            </div>
            <div className={styles.body}>
                <span className={styles.marginRight}>{t('supportModal.callSupport')}:</span>
                {isMobile ? <a href={'tel:0317454500'}>031-745 45 00</a> : <span>031-745 45 00</span>}
            </div>

            <div className={styles.close} onClick={onClose}>
                <img className={styles.xIcon} alt="" src="/x.svg"/>
            </div>
        </div>
    );
};

export default SupportModal;
