import React, { useEffect, useState } from 'react';
import { Typography, Box, ListItemText, Accordion, AccordionSummary, AccordionDetails, BottomNavigation, BottomNavigationAction } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoIcon from '@mui/icons-material/Info';
import ListIcon from '@mui/icons-material/List';
import HistoryIcon from '@mui/icons-material/History';
import Partlist from '../components/Partlist';
import Footer from '../components/FooterTask';  // Assuming Footer is in the same directory, adjust the path if needed.
import { useTranslation } from 'react-i18next';
import HistoryLog from "../components/HistoryLog";
import ChecklistIcon from '@mui/icons-material/Checklist';
import Checklist from '../components/Checklist';
import HandshakeIcon from '@mui/icons-material/Handshake';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { useAuth } from 'oidc-react';
import HistoricalWorkorderList from './HistoricalWorkorderList';

export default function DataComponent({ data, statusChangeErrorCodes }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const [agreementInfo, setAgreementInfo] = useState('');

  const auth = useAuth();
  const accessToken = auth.userData?.access_token;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);

    if (panel === 'agreementInfo' && isExpanded === true && agreementInfo.length === 0) {
      fetchAgreementInfo();
    }
  };

  const fetchAgreementInfo = async () => {
    try {
      const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
      const response = await fetch(`${rootUrl}WorkOrder/agreementInfo/${data.taskId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        const jsonData = await response.text();
        setAgreementInfo(jsonData);
      } else {
        console.log("Error fetching agreement info. Status:", response.status);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    if (statusChangeErrorCodes.length > 0) {
      setExpanded('checklist');
    }
  }, [statusChangeErrorCodes]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>

      <Box sx={{ flex: 1, overflowY: 'auto', zIndex: 500 }}>  {/* Added a zIndex here to ensure it's below the Footer */}

        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content">
            <DescriptionIcon sx={{ mr: 1 }} />
            <Typography>{t('workOrder.description')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>{data.custProbDescr}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content">
            <InfoIcon sx={{ mr: 1 }} />
            <Typography>{t('workOrder.moreInfo.moreInfo')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" color="textSecondary">{t('workOrder.moreInfo.general')}</Typography>
            <ListItemText primary={`${t('workOrder.moreInfo.referenceNumber')}: ${data.referenceNumber}`} />
            <Typography variant="body2" color="textSecondary">{t('workOrder.moreInfo.orderTypeSpecific')}</Typography>
            <ListItemText primary={`${t('workOrder.moreInfo.customerNumber')}: ${data.customerNumber}`} />
            <ListItemText primary={`${t('workOrder.moreInfo.agreementNumber')}: ${data.agreementNumber}`} />
            <Typography variant="body2" color="textSecondary">{t('workOrder.moreInfo.customerContactInfo')}</Typography>
            <ListItemText primary={`${t('name')}: ${data.customerContactName}`} />
            <ListItemText primary={`${t('mobile')}: ${data.customerContactPhone}`} />
            <Typography variant="body2" color="textSecondary">{t('workOrder.moreInfo.projectLead')}</Typography>
            <ListItemText primary={`${t('name')}: ${data.projectLead?.name}`} />
            <ListItemText primary={`${t('mobile')}: ${data.projectLead?.mobilePhone}`} />
            <ListItemText>
              {t('email')}: <a href={`mailto:${data.projectLead?.email}`} target="_top">{`${data.projectLead?.email}`}</a>
            </ListItemText>
            <Typography variant="body2" color="textSecondary">{t('workOrder.moreInfo.constructionLead')}</Typography>
            <ListItemText primary={`${t('name')}: ${data.constructionLead?.name}`} />
            <ListItemText primary={`${t('mobile')}: ${data.constructionLead?.mobilePhone}`} />
            <ListItemText>
              {t('email')}: <a href={`mailto:${data.constructionLead?.email}`} target="_top">{`${data.constructionLead?.email}`}</a>
            </ListItemText>
            <Typography variant="body2" color="textSecondary">{t('workOrder.moreInfo.productionPlanner')}</Typography>
            <ListItemText primary={`${t('name')}: ${data.productionPlanner?.name}`} />
            <ListItemText primary={`${t('mobile')}: ${data.productionPlanner?.mobilePhone}`} />
            <ListItemText>
              {t('email')}: <a href={`mailto:${data.productionPlanner?.email}`} target="_top">{`${data.productionPlanner?.email}`}</a>
            </ListItemText>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content">
            <ListIcon sx={{ mr: 1 }} />
            <Typography>{t('workOrder.products')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Partlist />
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content">
            <HistoryIcon sx={{ mr: 1 }} />
            <Typography>{t('workOrder.events')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {
              data.taskId && expanded === 'panel4' ? (
                <HistoryLog wono={data.taskId} />
              ) : (<></>)
            }
          </AccordionDetails>
        </Accordion>

        { data.hasAgreementChecklist && data.hasAgreementChecklist === true ? (
          <Accordion expanded={expanded === 'checklist'} onChange={handleChange('checklist')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="checklist-content">
              <ChecklistIcon sx={{ mr: 1 }} />
              <Typography>{t('workOrder.checklist')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                data.taskId && expanded === 'checklist' ? (
                  <Checklist wono={data.taskId} statusChangeErrorCodes={statusChangeErrorCodes} />
                ) : (<></>)
              }
            </AccordionDetails>
          </Accordion>)
        : (<></>)}
        
        <Accordion expanded={expanded === 'agreementInfo'} onChange={handleChange('agreementInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="checklist-content">
            <HandshakeIcon sx={{ mr: 1 }} />
            <Typography>{t('workOrder.agreementInfo')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            { agreementInfo.length > 0 && expanded === 'agreementInfo' ?
              ( <Typography style={{ whiteSpace: 'pre-line' }}>{agreementInfo}</Typography>
              ) : (<>{t('workOrder.noAgreementInfoAvailable')}</>)
            }
          </AccordionDetails>
        </Accordion>

        { process.env.REACT_APP_ENABLE_HISTORICAL_WORKORDERS === 'true' ? (
        <Accordion expanded={expanded === 'historicalWOs'} onChange={handleChange('historicalWOs')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="historical-content">
            <WorkHistoryIcon sx={{ mr: 1 }} />
            <Typography>{t('workOrder.historicalWorkorders')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {
              data.taskId && expanded === 'historicalWOs' ? (
                <HistoricalWorkorderList wono={data.taskId} />
              ) : (<></>)
            }
          </AccordionDetails>
        </Accordion>)
        : (<></>)}
      </Box>

      <Footer activePage="task"  // Adjust the activePage and the handlers based on your needs.
        onFirstMenuItemClick={() => console.log("Clicked task")}
        onFirstMenuItem1Click={() => console.log("Clicked notes")}
        onFirstMenuItem2Click={() => console.log("Clicked materials")}
        onFirstMenuItem3Click={() => console.log("Clicked attachments")}
        onFirstMenuItem4Click={() => console.log("Clicked report")}
      />
    </Box>
  );
}
