import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Menu, MenuItem } from '@mui/material';
import { AccountCircle, AccessTime, Menu as MenuIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import LanguageIcon from '@mui/icons-material/Language';
import CellTowerIcon from '@mui/icons-material/CellTower';
import SupportAgent from '@mui/icons-material/SupportAgent';
import FeedbackIcon from '@mui/icons-material/Feedback';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PortalPopup from "../components/PortalPopup"
import LanguageSelectionModal from './LanguageSelectionModal';
import SupportModal from "./SupportModal";
import { sv } from "date-fns/locale";
import { parse } from 'date-fns';

const Footer = ({ setSuccessToastState, mapMode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
  const [isSupportModalOpen, setSupportModalOpen] = useState(false);

  const openLanguageModal = useCallback(() => {
    setLanguageModalOpen(true);
    handleClose();
  }, []);

  const closeLanguageModal = useCallback(() => {
    setLanguageModalOpen(false);
  }, []);

  const openSupportModal = useCallback(() => {
    setSupportModalOpen(true);
    handleClose();
  }, []);

  const closeSupportModal = useCallback(() => {
    setSupportModalOpen(false);
  }, []);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const showSupportMenuOption = () => {
    const showSupportMenuOptionFromDate = process.env.REACT_APP_SHOW_SUPPORT_MENU_OPTION_FROM_DATE;
    let dateToShowSupportMenuOptionFrom = parse(showSupportMenuOptionFromDate, "yyyy-MM-dd HH:mm:ss", new Date(), { locale: sv });

    return new Date() > dateToShowSupportMenuOptionFrom;
  };

  const handleMapClick = (event) => {
    if (mapMode === "broadcast") {
      navigate("/workordermap/broadcast");
    }
    else {
      navigate("/workordermap");
    }
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuAnchorEl(null);
  };

  const navigateToProfile = () => {
    navigate("/profile");
    handleClose();
  };

  const navigateToBroadcast = () => {
    navigate("/broadcast");
    handleClose();
  };

  const navigateToNotificationSettings = () => {
    navigate("/settings/notifications");
    handleClose();
  };
  
  const navigateToNews = () => {
    navigate("/news");
    handleClose();
  };

  return (
    <>
      <BottomNavigation
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <BottomNavigationAction
          icon={<AccountCircle />}
          onClick={handleProfileClick}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={navigateToProfile}>{t('footer.profile')}</MenuItem>
          <MenuItem onClick={navigateToNotificationSettings}>{t('footer.notificationSettings')}</MenuItem>
        </Menu>

        {/*<BottomNavigationAction icon={<AccessTime />} />*/}
        <BottomNavigationAction icon={<TravelExploreIcon />} onClick={handleMapClick} />
        <BottomNavigationAction icon={<MenuIcon />} onClick={handleMenuClick} />
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={navigateToNews}>{<NewspaperIcon sx={{ mr: '5px' }} />}{t('news.news')}</MenuItem>
          <MenuItem onClick={()=>window.showCollectorDialog()}>{<FeedbackIcon sx={{ mr: '5px' }} />}{t('feedback')}</MenuItem>
          {showSupportMenuOption() && (<MenuItem onClick={openSupportModal}>{<SupportAgent sx={{ mr: '5px' }} />}{t('support')}</MenuItem>)}
          <MenuItem onClick={navigateToBroadcast}>{<CellTowerIcon sx={{ mr: '5px' }} />}{t('broadcast-list')}</MenuItem>
          <MenuItem onClick={openLanguageModal}>{<LanguageIcon sx={{ mr: '5px' }} />}{t('language')}</MenuItem>
        </Menu>
      </BottomNavigation>
      {isLanguageModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeLanguageModal}
        >
          <LanguageSelectionModal onClose={closeLanguageModal} setSuccessToastState={setSuccessToastState} />
        </PortalPopup>
      )}
      {isSupportModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSupportModal}
        >
          <SupportModal onClose={closeSupportModal} />
        </PortalPopup>
      )}
    </>
  );
};

export default Footer;
