import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importer dine språkpakker
import en from './translations/en.json';
import no from './translations/no.json';
import sv from './translations/sv.json';
import {defaultLang} from "./components/LanguageProvider";

i18n
  .use(LanguageDetector) // Denne vil automatisk oppdage brukerens språk
  .use(initReactI18next) // binder i18n til react
  .init({
    resources: {
      en: { translation: en },
      no: { translation: no },
      sv: { translation: sv }
    },
    fallbackLng: defaultLang,
    interpolation: {
      escapeValue: false // react er allerede trygt mot XSS-angrep
    }
  });

export default i18n;
