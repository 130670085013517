import React, { useState, useEffect } from 'react';
import { useAuth } from 'oidc-react';
import { Box, Typography, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { AccountCircle, AccessTime, Menu } from '@mui/icons-material';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';

const Profile = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const fullName = auth.userData?.profile?.name;
  const username = auth.userData?.profile?.preferred_username;
  const email = auth.userData?.profile?.email;

  useEffect(() => {
    if (auth.userData) {
      console.log(auth.userData);  // This will log the user data to the console.
      setIsLoading(false);
    }
  }, [auth.userData]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">Laster inn...</Typography>
      </div>
    );
  }
  return (
    <div style={{ height: "100vh", overflow: "hidden", backgroundColor: "#eee" }}>
      <Header />

      <div style={{ overflowY: "scroll", height: "calc(100vh - 120px)" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 4,
          }}
        >
          <Typography variant="h4" color="primary" gutterBottom>
            {t('profile.profile')}
          </Typography>

          <Box sx={{ width: '100%', maxWidth: 400, marginBottom: 2 }}>
            <Typography variant="h6" color="textSecondary">
            {t('profile.fullName')}:
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {fullName || 'Ukjent'}
            </Typography>
          </Box>

          <Box sx={{ width: '100%', maxWidth: 400, marginBottom: 2 }}>
            <Typography variant="h6" color="textSecondary">
              {t('profile.username')}:
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {username || 'Ukjent'}
            </Typography>
          </Box>

          <Box sx={{ width: '100%', maxWidth: 400, marginBottom: 2 }}>
            <Typography variant="h6" color="textSecondary">
              {t('profile.email')}:
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {email || 'Ukjent'}
            </Typography>
          </Box>
        </Box>
      </div>

      {/* Footer */}
      <Footer activeDate={new Date()} />
    </div>
  );
};

export default Profile;
