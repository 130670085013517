import React, { useState, useEffect } from 'react';
import { useAuth } from 'oidc-react';
import { BottomNavigation, BottomNavigationAction, Box, Typography, Button } from '@mui/material';
import { AccountCircle, AccessTime, Menu } from '@mui/icons-material';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';

const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
let pushServiceWorkerRegistration;

const NotificationSettings = () => {
    let applicationServerPublicKey;
    const [registration, setRegistration] = useState(null);
    const [showSubscribed, setShowSubscribed] = useState(false);
    const [showNoSupport, setShowNoSupport] = useState(false);
    const [showSubscribe, setShowSubscribe] = useState(false);
    const { t } = useTranslation();

    const auth = useAuth();
    const accessToken = auth.userData?.access_token;

    useEffect(() => {
        if (!('serviceWorker' in navigator)) {
            console.log('Service Workers are not supported');
            return;
        }

        if (!('PushManager' in window)) {
            console.log('Push API not supported');
            return;
        }

        registerPushServiceWorker();
      }, []);

    function registerPushServiceWorker() {
        navigator.serviceWorker.register('/scripts/web-push/push-serviceWorker.js', { scope: '/scripts/web-push/' })
            .then(function (serviceWorkerRegistration) {
                pushServiceWorkerRegistration = serviceWorkerRegistration;

                initializeUIState();

                console.log('Push Service Worker has been registered successfully');
            }).catch(function (error) {
                console.log('Push Service Worker registration has failed: ' + error);
            });
    }

    function initializeUIState() {
        pushServiceWorkerRegistration.pushManager.getSubscription()
            .then(function (subscription) {
                changeUIState(Notification.permission === 'denied', subscription !== null);
            });
    }

    function changeUIState(notificationsBlocked, isSubscribed) {
        // subscribeButton.disabled = notificationsBlocked || isSubscibed;
        // unsubscribeButton.disabled = notificationsBlocked || !isSubscibed;

        if (notificationsBlocked || isSubscribed) {
            setShowSubscribe(false);
        }
        else {
            setShowSubscribe(true);
        }

        if (notificationsBlocked) {
            setShowNoSupport(true);
        }

        setShowSubscribed(isSubscribed);
    }

    function urlB64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
    
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
    
        return outputArray;
    }
    
    function retrievePublicKey() {
        return fetch(`${rootUrl}api/notifications/public-key`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }})
            .then(function (response) {
                if (response.ok) {
                    return response.text()
                        .then(function (applicationServerPublicKeyBase64) {
                            return urlB64ToUint8Array(applicationServerPublicKeyBase64);
                        });
                } else {
                    return Promise.reject(response.status + ' ' + response.statusText);
                }
            });
    }
    
    function storePushSubscription(pushSubscription) {
        return fetch(`${rootUrl}api/notifications/subscriptions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(pushSubscription)
        });
    }
    
    function discardPushSubscription(pushSubscription) {
        return fetch(`${rootUrl}api/notifications/subscriptions?endpoint=` + encodeURIComponent(pushSubscription.endpoint), {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${accessToken}` }
        });
    }

    const handleRemoveClick = () => {
        pushServiceWorkerRegistration.pushManager.getSubscription()
            .then(function (pushSubscription) {
                if (pushSubscription) {
                    pushSubscription.unsubscribe()
                        .then(function () {
                            discardPushSubscription(pushSubscription)
                                .then(function (response) {
                                    if (response.ok) {
                                        console.log('Successfully unsubscribed from Push Notifications');
                                    } else {
                                        console.log('Failed to discard the Push Notifications subscrition from server');
                                    }
                                }).catch(function (error) {
                                    console.log('Failed to discard the Push Notifications subscrition from server: ' + error);
                                });
    
                            changeUIState(false, false);
                        }).catch(function (error) {
                            console.log('Failed to unsubscribe from Push Notifications: ' + error);
                        });
                }
            });
    }

    const handleSubscribeClick = () => {
        if (applicationServerPublicKey) {
            subscribeForPushNotificationsInternal();
        } else {
            retrievePublicKey()
                .then(function (retrievedPublicKey) {
                    applicationServerPublicKey = retrievedPublicKey;
                    console.log('Successfully retrieved Public Key');

                    subscribeForPushNotificationsInternal();
                }).catch(function (error) {
                    console.log('Failed to retrieve Public Key: ' + error);
                });
        }
    };

    function subscribeForPushNotificationsInternal() {
        pushServiceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerPublicKey
        })
            .then(function (pushSubscription) {
                storePushSubscription(pushSubscription)
                    .then(function (response) {
                        if (response.ok) {
                            console.log('Successfully subscribed for Push Notifications');
                        } else {
                            console.log('Failed to store the Push Notifications subscrition on server');
                        }
                    }).catch(function (error) {
                        console.log('Failed to store the Push Notifications subscrition on server: ' + error);
                    });

                changeUIState(false, true);
            }).catch(function (error) {
                if (Notification.permission === 'denied') {
                    changeUIState(true, false);
                } else {
                    console.log('Failed to subscribe for Push Notifications: ' + error);
                }
            });
    }

    return (
        <div style={{ height: "100vh", overflow: "hidden", backgroundColor: "#eee" }}>
            <Header />

            <div style={{ overflowY: "scroll", height: "calc(100vh - 120px)" }}>
                <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 4,
                }}
                >
                <Typography variant="h6" color="primary" gutterBottom>
                    {t('notificationSettings.heading')}
                </Typography>

                <Box sx={{ width: '100%', maxWidth: 400, marginBottom: 2 }} style={{display: showSubscribe ? "block" : "none"}}>
                    <Typography variant="h6" color="textSecondary">
                        {t('notificationSettings.activate')}:
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleSubscribeClick} style={{marginTop: '15px'}}>
                    {t('notificationSettings.allow')}
                    </Button>
                </Box>
                
                <Box sx={{ width: '100%', maxWidth: 400, marginBottom: 2 }} style={{display: showNoSupport ? "block" : "none"}}>
                    <Typography variant="h6" color="textSecondary">
                        {t('notificationSettings.noSupportOrBlocked')}
                    </Typography>
                </Box>

                <Box sx={{ width: '100%', maxWidth: 400, marginBottom: 2 }} style={{display: showSubscribed ? "block" : "none"}}>
                    <Typography variant="body1" color="textSecondary">
                        {t('notificationSettings.activated')}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleRemoveClick} style={{marginTop: '15px'}}>
                    {t('notificationSettings.inactivate')}
                    </Button>
                </Box>
            </Box>
            </div>

            {/* Footer */}
            <Footer activeDate={new Date()} />
        </div>
    );
};

export default NotificationSettings;
