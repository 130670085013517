import React, { useState, useEffect, useCallback } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import Header from "../components/Header";
import Footer from "../components/Footer";

const BroadcastView = () => {
    const [tasks, setTasks] = useState([]);

    const navigate = useNavigate();

    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const getBroadcastTasks = async () => {
        try {
            const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
            const response = await fetch(`${rootUrl}workorder/broadcast`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setTasks(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (accessToken) {
            getBroadcastTasks();
        }
    }, [accessToken]);

    // Add a useEffect to listen for changes in the auth state
    useEffect(() => {
        if (accessToken) {
            getBroadcastTasks();
        }
    }, [auth, accessToken]);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2196f3',
            },
        },
    });
    const { t } = useTranslation();

    const handleNavigateToDetailPage = useCallback((cardId) => {
        navigate(`/task/${cardId}`);
    }, [navigate]);

    return (
        <div style={{ height: "100vh", overflow: "hidden", backgroundColor: "#eee" }}>
            <Header />
            <div style={{ overflowY: "scroll", height: "calc(100vh - 120px)" }}>
                <ThemeProvider theme={theme}>
                    <Container>
                        <Typography variant="h6" color="primary" sx={{ my: 1, textAlign: 'center' }}>
                            {t('broadcast-list')}
                        </Typography>
                        <Grid container justifyContent="center" spacing={2}>
                            {tasks.length === 0 ? (
                                <Typography variant="body1">{t('noTasks')}</Typography>
                            ) : (
                                tasks.map((task) => (
                                    <Grid key={task.id} item xs={12} sm={12} md={12} lg={12}>
                                        <div onClick={() => handleNavigateToDetailPage(task.id)} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
                                            <BasicCard task={task} />
                                        </div>
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    </Container>
                </ThemeProvider>
            </div>
            <Footer mapMode="broadcast" />
        </div>
    );
};

const BasicCard = ({ task }) => {
    const { t, i18n } = useTranslation();

    return (
        <Card
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                p: 2,
                position: 'relative',
            }}
        >
            <div
                style={{
                    width: 25,
                    height: 25,
                    borderRadius: '50%',
                    backgroundColor: '#FFFFFF',
                    position: 'absolute',
                    top: 10,
                    right: 10,
                }}
            />
            <CardContent sx={{ flex: '1 0 auto', padding: 0, mt: 2 }}>
                <Typography sx={{ fontSize: 14, display: 'flex', justifyContent: 'space-between' }} color="text.secondary" gutterBottom>
                    <span>{t('workOrder.id')}: {task.id}</span>
                </Typography>
                <Typography sx={{ fontSize: 14, display: 'flex', justifyContent: 'space-between' }} color="text.secondary" gutterBottom>
                    <span>{t('workOrder.referenceNbr')}: {task.type}</span>
                    {/* <span>{t('workOrder.notBooked')}</span> */}
                </Typography>
                <Typography variant="h5" component="div">
                    {task.title}
                </Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                    {t('workOrder.requestedStartTime')}: {task.start && new Date(task.start).toLocaleString(i18n.language)}
                </Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                    {t('workOrder.requestedEndTime')}: {task.end && new Date(task.end).toLocaleString(i18n.language)}
                </Typography>
                <Typography variant="body2">{task.address}</Typography>
            </CardContent>
        </Card>
    );
};

export default BroadcastView;
