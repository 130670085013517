import React, { useState, useEffect } from "react";
import styles from "./Checklist.module.css";
import { Typography, Box } from "@mui/material";
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { async } from "q";


const Checklist = ({ wono, statusChangeErrorCodes }) => {
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const { t } = useTranslation();
    const [checklist, setChecklist] = useState([]);
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;

    useEffect(() => {
        const fetchChecklist = async () => {
            try {
                const response = await fetch(`${rootUrl}WorkOrder/checklist/${wono}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const jsonData = await response.json();
                    setChecklist(jsonData);
                } else {
                    console.error("Error fetching data. Status:", response.status);
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchChecklist();
    }, []);

    const updateCheckboxValue = async (agreementRuleId, id, checked) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
          method: id === null ? "POST" : "PATCH",
          headers: headers,
        };
        let url = `${rootUrl}WorkOrder/checklist/${wono}?`;

        if (id === null) {
            url += `agreementRuleId=${agreementRuleId}`;
        }
        else {
            url += `id=${id}&isChecked=${checked}`;
        }

        const response = await fetch(url, options);
    
        if (!response.ok) {
          console.error('Could not update checkbox value.');
          console.error(response);
        }
      };

      const getCheckboxClassName = (ruleKey) => {
        if (statusChangeErrorCodes.some(errorCode => errorCode == ruleKey)) { 
            return styles.checklistError;
        }
        return '';
      }

    const handleChange = async (event, agreementRuleId, id) => {
        let updatedChecklist = checklist.map((item) => {
            if (item.agreementRuleId === agreementRuleId) {
               return { ...item, isChecked: event.target.checked };
            }
            return item;
         });
         
         setChecklist(updatedChecklist);

        await updateCheckboxValue(agreementRuleId, id, event.target.checked);

        // event.target.checked
      };

    return (
        <Box sx={{ marginTop: -3 }}>
            <FormGroup>
                {checklist.map((row, index) => (
                    <div key={row.ruleKey}>
                        <FormControlLabel control={
                            <Checkbox
                                checked={row.isChecked}
                                onChange={e => handleChange(e, row.agreementRuleId, row.id)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                            />}
                            label={t('workOrder.agreementRules.' + row.ruleKey)}
                            className={getCheckboxClassName(row.ruleKey)}
                        />
                    </div>
                ))}
            </FormGroup>
        </Box>
    );
}

export default Checklist