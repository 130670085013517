import React, { useState, useEffect } from 'react';
import { Radio, FormControlLabel, RadioGroup, FormControl, Typography, Box, FormLabel, CircularProgress, Button, TextField } from '@mui/material';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';

const ReportQualityEE000 = ({ wono, setToastState }) => {
    const [saving, setSaving] = useState(false);
    const [parameterEE00xValue, setParameterEE00xValue] = useState(null);
    const [ee00xDefinitions, setEe00xDefinitions] = useState([]);
    const [valueEE00x, setValueEE00x] = useState({ EE000: "", EE001: "", EE002: "", EE003: "", EE004: "", EE005: "", EE006: "", EE007: "", EE008: "", });
    let woData = "";
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const { t } = useTranslation();
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;

    useEffect(() => {
        const fetchParameterEE00xDefinitions = async () => {
            try {
                let url = `${rootUrl}WorkOrder/parameter/definitions/ee00x`;
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const parameterValue = await response.json();
                    console.log(parameterValue);
                    setEe00xDefinitions(parameterValue);
                }
            } catch (error) {
                console.error('Error fetching parameter value:', error);
            }
        };

        const fetchParameterEE00xValue = async () => {
            try {
                let url = `${rootUrl}WorkOrder/parameter/ee00xValuses/${wono}`;

                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const parameterValue = await response.json();
                    console.log(parameterValue);
                    setParameterEE00xValue(parameterValue);
                }
            } catch (error) {
                console.error('Error fetching parameter value:', error);
            }
        };
        fetchParameterEE00xDefinitions();
        fetchParameterEE00xValue();
    }, []);

    useEffect(() => {
        const fetchParameterDefList = async () => {
            try {
                if (parameterEE00xValue !== null) {
                    const newValues = {};
                    for (let i = 0; i < 9; i++) {
                        const key = `EE00${i}`;
                        newValues[key] = parameterEE00xValue[i]?.valuE_WITH_LOV ?? "";
                    }
                    setValueEE00x(newValues);
                }
            } catch (error) {
                console.error('Error fetching parameter definition list:', error);
            }
        };
        fetchParameterDefList();
    }, [parameterEE00xValue]);

    function isButtonDisabeled() {
        let ret = false;
        if (valueEE00x.EE000 !== "JA") {
            if (valueEE00x.EE001 === "" && valueEE00x.EE002 === "" && valueEE00x.EE003 === "" && valueEE00x.EE004 === "" &&
                valueEE00x.EE005 === "" && valueEE00x.EE006 === "" && valueEE00x.EE007 === "" && valueEE00x.EE008 === "") {
                ret = true;
            }
        }
        return ret;
    };

    const updateWorkOrder = async () => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(woData)
        };
        let url = `${rootUrl}WorkOrder/wo`;
        const response = await fetch(url, options);

        if (!response.ok) {
            console.log(response);
            setToastState({
                open: true,
                severity: 'error',
                message: t('updateFail'),
            });
        }
        else {
            setToastState({
                open: true,
                severity: 'success',
                message: t('updateSuccess'),
            });
        }
        setSaving(false);
    };

    const handleUploadReportQuality = async () => {
        setSaving(true);
        let parameteR_CATEGORIES = [];

        for (let key in valueEE00x) {
            if (key.startsWith("EE") && valueEE00x[key] !== "") {
                parameteR_CATEGORIES.push({
                    parameteR_ID: key,
                    valuE_WITH_LOV: valueEE00x[key]
                });
            }
        }
        woData = {
            wO_NO: wono,
            parameteR_CATEGORIES: parameteR_CATEGORIES
        };
        updateWorkOrder();
    };

    const handleChangeText = (event, selectId) => {
        setValueEE00x(prevState => ({
            ...prevState,
            [selectId]: event.target.value
        }));
    };

    return (
        <Box sx={{ minWidth: 220 }}>
            <FormControl>
                <FormLabel id="EE000-label">{(ee00xDefinitions.find(param => param.parameterId === 'EE000') || {}).parameterDescription || ''}</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="EE000-label"
                    defaultValue=""
                    name="EE000-radio-buttons-group"
                    value={valueEE00x.EE000 ?? ''}
                    onChange={(e) => handleChangeText(e, 'EE000')}
                >
                    <FormControlLabel value="JA" control={<Radio />} label={t('yes')} />
                    <FormControlLabel value="NEJ" control={<Radio />} label={t('no')} />
                </RadioGroup>
            </FormControl>
            {
                valueEE00x.EE000 === "NEJ" ? (
                    <>
                        <FormControl fullWidth>
                            <TextField
                                id="select-EE001"
                                label={(ee00xDefinitions.find(param => param.parameterId === 'EE001') || {}).parameterDescription || ''}
                                variant="outlined"
                                // Assuming valueEE20x.EE202 is your state value for this TextField
                                value={valueEE00x.EE001 ?? ''}
                                onChange={(e) => handleChangeText(e, 'EE001')}
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ marginTop: '-10px' }}>
                            <TextField
                                id="select-EE002"
                                label={(ee00xDefinitions.find(param => param.parameterId === 'EE002') || {}).parameterDescription || ''}
                                variant="outlined"
                                // Assuming valueEE20x.EE202 is your state value for this TextField
                                value={valueEE00x.EE002 ?? ''}
                                onChange={(e) => handleChangeText(e, 'EE002')}
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ marginTop: '-10px' }}>
                            <TextField
                                id="select-EE003"
                                label={(ee00xDefinitions.find(param => param.parameterId === 'EE003') || {}).parameterDescription || ''}
                                variant="outlined"
                                // Assuming valueEE20x.EE202 is your state value for this TextField
                                value={valueEE00x.EE003 ?? ''}
                                onChange={(e) => handleChangeText(e, 'EE003')}
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ marginTop: '-10px' }}>
                            <TextField
                                id="select-EE004"
                                label={(ee00xDefinitions.find(param => param.parameterId === 'EE004') || {}).parameterDescription || ''}
                                variant="outlined"
                                // Assuming valueEE20x.EE202 is your state value for this TextField
                                value={valueEE00x.EE004 ?? ''}
                                onChange={(e) => handleChangeText(e, 'EE004')}
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ marginTop: '-10px' }}>
                            <TextField
                                id="select-EE005"
                                label={(ee00xDefinitions.find(param => param.parameterId === 'EE005') || {}).parameterDescription || ''}
                                variant="outlined"
                                // Assuming valueEE20x.EE202 is your state value for this TextField
                                value={valueEE00x.EE005 ?? ''}
                                onChange={(e) => handleChangeText(e, 'EE005')}
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ marginTop: '-10px' }}>
                            <TextField
                                id="select-EE006"
                                label={(ee00xDefinitions.find(param => param.parameterId === 'EE006') || {}).parameterDescription || ''}
                                variant="outlined"
                                // Assuming valueEE20x.EE202 is your state value for this TextField
                                value={valueEE00x.EE006 ?? ''}
                                onChange={(e) => handleChangeText(e, 'EE006')}
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ marginTop: '-10px' }}>
                            <TextField
                                id="select-EE007"
                                label={(ee00xDefinitions.find(param => param.parameterId === 'EE007') || {}).parameterDescription || ''}
                                variant="outlined"
                                // Assuming valueEE20x.EE202 is your state value for this TextField
                                value={valueEE00x.EE007 ?? ''}
                                onChange={(e) => handleChangeText(e, 'EE007')}
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ marginTop: '-10px' }}>
                            <TextField
                                id="select-EE008"
                                label={(ee00xDefinitions.find(param => param.parameterId === 'EE008') || {}).parameterDescription || ''}
                                variant="outlined"
                                // Assuming valueEE20x.EE202 is your state value for this TextField
                                value={valueEE00x.EE008 ?? ''}
                                onChange={(e) => handleChangeText(e, 'EE008')}
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                    </>
                ) : (<></>)
            }

            <Typography style={{ whiteSpace: 'pre-line' }}>
                <Button
                    sx={{ marginTop: '10px' }}
                    variant="contained"
                    color="primary"
                    onClick={handleUploadReportQuality}
                    disabled={saving || isButtonDisabeled()}>
                    {saving ? <CircularProgress size={24} /> : t('save')}
                </Button>
            </Typography>
        </Box>
    )
}

export default ReportQualityEE000