import React, { useState } from "react";
import PropTypes from 'prop-types';
import { styled } from "@mui/material/styles";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { AssignmentTurnedIn, EditNote, Build, AttachFile, Assessment } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const StyledBottomNavigation = styled(BottomNavigation)({
  position: "fixed",
  left: 0,
  bottom: 0,
  width: "100%",
  zIndex: 2000,
  boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.2)", // Optional: Add a shadow,
  "& .MuiBottomNavigationAction-root": {
    "@media (max-width: 768px)": {
      minWidth: "auto",
      padding: "6px 0"
    }
  }
});

const Footer = ({ activePage, onFirstMenuItemClick, onFirstMenuItem1Click, onFirstMenuItem2Click, onFirstMenuItem3Click, onFirstMenuItem4Click, requestID, taskID }) => {
  const { t } = useTranslation();

  // Kartlegging fra sidens navn til indeksen i BottomNavigation
  const pageToValueMap = {
    task: 0,
    notes: 1,
    materials: 2,
    attachments: 3,
    report: 4
  };

  const [value, setValue] = useState(pageToValueMap[activePage]);

  const handleButtonClick = (index, onClickHandler) => {
    setValue(index);
    onClickHandler(); // Call the appropriate click handler for the button
  };

  return (
    <StyledBottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
    >
      <BottomNavigationAction label={t('workOrder.footer.workOrder')} icon={<AssignmentTurnedIn />} onClick={() => handleButtonClick(0, onFirstMenuItemClick)} />
      <BottomNavigationAction label={t('workOrder.footer.notes')} icon={<EditNote />} onClick={() => handleButtonClick(1, onFirstMenuItem1Click)} />
      <BottomNavigationAction label={t('workOrder.footer.materials')} icon={<Build />} onClick={() => handleButtonClick(2, onFirstMenuItem2Click)} />
      <BottomNavigationAction label={t('workOrder.footer.attachments')} icon={<AttachFile />} onClick={() => handleButtonClick(3, onFirstMenuItem3Click)} />
      <BottomNavigationAction label={t('workOrder.footer.report')} icon={<Assessment />} onClick={() => handleButtonClick(4, onFirstMenuItem4Click)} />
    </StyledBottomNavigation>
  );
};

Footer.propTypes = {
  activePage: PropTypes.oneOf(['task', 'notes', 'materials', 'attachments', 'report']).isRequired,
  onFirstMenuItemClick: PropTypes.func.isRequired,
  onFirstMenuItem1Click: PropTypes.func.isRequired,
  onFirstMenuItem2Click: PropTypes.func.isRequired,
  onFirstMenuItem3Click: PropTypes.func.isRequired,
  onFirstMenuItem4Click: PropTypes.func.isRequired,
};

export default Footer;
