import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import {useAuth} from "oidc-react";
import {useToastState} from "./ToastStateProvider";

const RemoveMaterialModal = ({ open, onClose, wono, posting, postings, setPostings }) => {
    const { t } = useTranslation();
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
    const {setToastState} = useToastState();
    const removeWorkOrderCoding = async () => {
        const woUpdate =
            {
                WO_NO: wono,
                WORK_ORDER_CODINGS: [
                    {
                        ROW_NO: posting.rowNo,
                        DELETED: 'TRUE'
                    }
                ]
            };
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(woUpdate)
        };
        let url = `${rootUrl}WorkOrder/wo`;
        const response = await fetch(url, options);

        if (response.ok) {
            setToastState({
                open: true,
                severity: 'success',
                message: t('updateSuccess'),
            });
            const filteredItems = postings.filter(item => item.rowNo !== posting.rowNo);
            setPostings(filteredItems);
        }
        else {
            setToastState({
                open: true,
                severity: 'error',
                message: t('updateFail'),
            });
        }
    };

    const onOk = async () => {
        await removeWorkOrderCoding();
        onClose();
    };

    return (
            <Dialog
                open={open}
                onClose={onClose}
            >
                <DialogTitle>
                    {t('workOrder.materials.removeModal.title')}
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('workOrder.materials.removeModal.description', {id: posting.catalogNo || posting.custCatalogNo || ""})}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {t('workOrder.materials.removeModal.cancel')}
                    </Button>
                    <Button onClick={onOk} color="primary" autoFocus>
                        {"OK"}
                    </Button>
                </DialogActions>
            </Dialog>
    );
}

export default RemoveMaterialModal;
