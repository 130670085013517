import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Container, Menu, MenuItem, Chip, ListItemIcon, ListItemText } from '@mui/material';
import { ArrowBack as ArrowBackIcon, Home as HomeIcon, Search as SearchIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { useAuth } from 'oidc-react';
import { format } from 'date-fns';
import PortalPopup from "./PortalPopup";
import SearchModal from "./SearchModal";


const Header = ({ hideButtons = false }) => {
  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState(true);
  const [isSearchModalOpen, setSearchModalOpen] = useState(false);

  const sw = navigator.serviceWorker;

  const auth = useAuth();
  const accessToken = auth.userData?.access_token;

  const getNotifications = async () => {
    try {
      const API_BASE_URL = `${rootUrl}api/notifications`;
      const response = await fetch(API_BASE_URL , {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setNotifications(data);
        setNewNotifications(false);
      }
    } catch (error) {
      console.error('Error fetching notification list:', error);
    }
  };

  function markNotificationsRead(aoNumber) {
    return fetch(`${rootUrl}api/notifications/` + aoNumber, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${accessToken}` }
    });
}

  useEffect(() => {
    if (newNotifications === true) {
      getNotifications();
    }
  }, [newNotifications]);

  useEffect(() => {
    if ( sw ) {
      window.addEventListener( 'load', () => {

        sw.register( '/scripts/web-push/push-serviceWorker.js', { scope: '/scripts/web-push/' } )
        .then( (reg) => reg.ready )
        .then( () => {
          sw.addEventListener( 'message', ( { data } ) => {
            if (data === "newNotification"){
              setNewNotifications(true);
            }
          })
        })
      });
    }

  }, [setNewNotifications, sw]);

  const headerStyle = {
    backgroundColor: '#fff', // White background
    color: '#000', // Text color
    display: 'flex',
    justifyContent: 'center', // Center the header horizontally
  };

  const logoStyle = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const logoImageStyle = {
    maxHeight: 50, // Max height of the logo set to 50px
  };

  const iconsStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleHomeClick = () => {
    navigate('/');
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleNotificationsClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleNotificationClick = async (aoNumber) => {
    markNotificationsRead(aoNumber);
    setNewNotifications(true);
    setAnchorEl(null);
    navigate(`/task/${aoNumber}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSearchClick = () => {
    setSearchModalOpen(!isSearchModalOpen);
  }

  if (hideButtons) {
    return (
      <AppBar position="static" style={headerStyle}>
        <Container>
          <Toolbar>
            <div style={logoStyle}>
              <img alt="Logo" src={process.env.PUBLIC_URL + '/images/tapp.png'} style={logoImageStyle} />
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    );
  } else {
    return (
        <>
      <AppBar position="static" style={headerStyle}>
        <Container>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="back" onClick={handleBackClick}>
              <ArrowBackIcon />
            </IconButton>
            <div style={logoStyle}>
              <img alt="Logo" src={process.env.PUBLIC_URL + '/images/tapp.png'} style={logoImageStyle} />
            </div>
            <div style={iconsStyle}>
              <IconButton color="inherit" aria-label="Home" onClick={handleHomeClick}>
                <HomeIcon />
              </IconButton>
              <IconButton color="inherit" aria-label="Search" onClick={handleSearchClick}>
                <SearchIcon />
              </IconButton>
              <IconButton color="inherit" aria-label="Notifications" onClick={handleNotificationsClick}>
                <NotificationsIcon />
                {notifications.filter(item => item.read === false).length > 0  ? 
              <Chip
                label={ notifications.filter(item => item.read === false).length }
                color={ 'error' }
                sx={{ height: 15, width: 15, fontSize: '.65rem', marginLeft: '-8px', marginTop: '10px',
                  "& .MuiChip-label": {
                    padding: 0
                  }
                }}
              />
              : <></> }
              </IconButton>
              {notifications.length > 0  ? 
            <Menu
              id="notifications-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              slotProps={{
                paper: {
                  sx: {
                    width: '350px',
                    maxWidth: '100%',
                  },
                }
              }}
            >
              {
                notifications.map((row, key) => (
                  <MenuItem onClick={() => handleNotificationClick(row.woNumber)} style={{whiteSpace: 'normal'}} key={key}>
                    <ListItemIcon>
                      <FiberManualRecordRoundedIcon fontSize="small" style={{ color: row.read ? 'green' : 'red', paddingTop: '5px' }} />
                    </ListItemIcon>
                    <ListItemText><span style={{ fontWeight: row.read ? 'normal' : 'bold' }}>{format(new Date(row.createdDate), 'dd/M')}</span> {row.body}</ListItemText>
                  </MenuItem>
                ))
              }
            </Menu>
            : <></> }
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <SearchModal open={isSearchModalOpen} onClose={() => setSearchModalOpen(false)} />
    </>
    );
  };
}

export default Header;
