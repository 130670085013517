import React, {createContext, useContext, useEffect, useState} from 'react';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {enUS, sv} from "date-fns/locale";
import {useAuth} from "oidc-react";
import i18n from "i18next";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(defaultLang);
    const [dateFnsLocal, setDateFnsLocale] = useState(defaultLang === 'sv' ? sv : enUS);

    const auth = useAuth();
    const accessToken = auth.userData?.access_token;

    useEffect( () => {
        if (!accessToken) {
            return;
        }
        const initLanguage = async () => {
            const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
            const accessToken = auth.userData?.access_token;
            let url = `${rootUrl}user/language`;
            const languageResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (!languageResponse.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const languageVal = await languageResponse.text();
            if (languageVal) {
                setLanguage(languageVal);
            }
        }
        initLanguage();
    }, [accessToken]);

    useEffect( () => {
        if (language && language.startsWith('en')) {
            setDateFnsLocale(enUS);
            i18n.changeLanguage('en');
        } else {
            setDateFnsLocale(sv);
            i18n.changeLanguage('sv');
        }
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language: language, setLanguage: setLanguage }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocal}>
                {children}
            </LocalizationProvider>
        </LanguageContext.Provider>
    );
};

export const defaultLang = process.env.REACT_APP_LANGUAGE_LOCALE ?? 'sv';
export const useLanguage = () => useContext(LanguageContext);