import styles from "./GoogleMapsQuestionPopup.module.css";
import { useTranslation } from "react-i18next";

const GoogleMapsQuestionPopup = ({ onClose, setGoogleMapsQuestionPopupOpen, coordinates, wono }) => {
  const { t } = useTranslation();
  const lat = coordinates.latitude.replace(',', '.');
  const lng = coordinates.longitude.replace(',', '.');

  const openGoogelMaps = async () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);

    const mapURL = isMobile
      ? (isAndroid ? `geo:${lat},${lng}?q=${lat},${lng}` : `http://maps.apple.com/?q=AO+${wono}&ll=${lat},${lng}`)
      : `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

    if (isMobile) {
      window.location.href = mapURL;
    } else {
      window.open(mapURL, '_blank');
    }
    setGoogleMapsQuestionPopupOpen(false);
  }

  return (
    <div className={styles.modalExamples}>
      <div className={styles.header}>
        <div className={styles.text}>
          {t('workOrder.openGoogleMaps.header')}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.text1}>{t('workOrder.openGoogleMaps.question')}</div>
      </div>
      <div className={styles.footer}>
        <div className={styles.button}>
          <div className={styles.button1}>
            <div className={styles.text2} onClick={onClose}>{t('close')}</div>
          </div>
        </div>
        <div className={styles.button}>
          <div className={styles.button1}>
            <div className={styles.text2} onClick={openGoogelMaps}>OK</div>
          </div>
        </div>
      </div>
      <div className={styles.close}>
        <img className={styles.xIcon} alt="" src="/x.svg" onClick={onClose} />
      </div>
    </div>
  );
};

export default GoogleMapsQuestionPopup;
