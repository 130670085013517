import React from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styles from "./Banner.module.css";

const Banner = ({ text = "" }) => {
  if (text === "") {
    return null; 
  }

  return (
    <div
      style={{
        backgroundColor: "#ffd700",
        padding: "4px",
        textAlign: "center",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
      }}
      className={styles.bannerMarkdown}
    >
      <ReactMarkdown children={text} remarkPlugins={[remarkGfm]} />
    </div>
  );
};

export default Banner;
