import React, { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { statusMapping } from '../Utils.js';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const StatusFilter = ({onStatusFilterChanged}) => {
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
    const { t } = useTranslation();

    const auth = useAuth();
    const accessToken = auth.userData?.access_token;

    const statuses = [
        { value: "UNDERPREPARATION", label: t('workOrder.statuses.underpreparation') },
        { value: "PREPARED", label: t('workOrder.statuses.prepared') },
        { value: "STARTED", label: t('workOrder.statuses.started') },
        { value: "JOB_START", label: t('workOrder.statuses.onSite') },
        { value: "RELEASED", label: t('workOrder.statuses.released') },
        { value: "CANCELLED", label: t('workOrder.statuses.cancelled') },
        { value: "CUSTOMER_READY", label: t('workOrder.statuses.customerReady') },
        { value: "TEC_READY", label: t('workOrder.statuses.fieldReady') },
        { value: "WORKDONE", label: t('workOrder.statuses.workDone') },
        { value: "REPORTED", label: t('workOrder.statuses.reported') },
        { value: "FINISHED", label: t('workOrder.statuses.finished') },
    ];

    useEffect(() => {
        const fetchWoFilter = async () => {
            try {
                
            let url = `${rootUrl}user/workorderfilter`;
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const data = await response.text();
                    if (data !== null && data !== undefined && data !== "") {
                        setSelectedStatuses(data.split('|'));
                        onStatusFilterChanged(data.replaceAll('|', ','));
                    }
                }
            } catch (error) {
                console.error('Error fetching wo filter:', error);
            }
        };

        fetchWoFilter();
      }, [accessToken]);

    const saveWorkOrderFilter = async (selectedValues) => {
        const workOrderFilter = {
            FilterValues: selectedValues.map((s) => `${s}`)
        };

        let url = `${rootUrl}user/workorderfilter`;
        const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(workOrderFilter),
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        console.log('User WO filter saved.');
      } else {
        console.error('Save user wo filter failed');
      }
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedStatuses(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        onStatusFilterChanged(value);
        saveWorkOrderFilter(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <div style={{textAlign: 'center'}}>
            <FormControl sx={{ mb: 3, width: 300 }}>
                <InputLabel id="multiple-checkbox-label">Statusar att visa</InputLabel>
                <Select
                    labelId="multiple-checkbox-label"
                    id="multiple-checkbox"
                    multiple
                    value={selectedStatuses}
                    onChange={handleChange}
                    input={<OutlinedInput label="Statusar att visa" />}
                    renderValue={(selected) => selected.map((status) => { return (statusMapping(status).stateName); }).join(', ')}
                    MenuProps={MenuProps}
                >
                    {statuses.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                            <Checkbox checked={selectedStatuses.indexOf(status.value) > -1} />
                            <ListItemText primary={status.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default StatusFilter;
