import React, { useState, useEffect } from 'react';
import { Select, TextField, MenuItem, FormControl, InputLabel, Button, Box } from '@mui/material';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';

const TrvReport = ({ wono, setToastState }) => {
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const { t } = useTranslation();
    const parameters = [
        {
            PARAMETER_ID: "98210",
            description: "Arbete i eller vid järnvägsspår?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }, { label: "Nej", value: "NEJ" }]
        },
        {
            PARAMETER_ID: "98211",
            description: "Riskbedömning bifogad?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }]
        },
        {
            PARAMETER_ID: "98220",
            description: "Korrekt dokumentation?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }, { label: "Nej", value: "NEJ" }]
        },
        {
            PARAMETER_ID: "98221",
            description: "Är dokumentation bifogad där felaktigheten framgår?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }]
        },
        {
            PARAMETER_ID: "98230",
            description: "Upptäckta brister i anläggningen?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }, { label: "Nej", value: "NEJ" }]
        },
        {
            PARAMETER_ID: "98231",
            description: "Beskrivning av brist",
            IsSelect: false
        },
        {
            PARAMETER_ID: "98240",
            description: "Material på uppdraget?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }, { label: "Nej", value: "NEJ" }]
        },
        {
            PARAMETER_ID: "98241",
            description: "UE på uppdraget?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }, { label: "Nej", value: "NEJ" }]
        },
        {
            PARAMETER_ID: "98242",
            description: "Övriga kostnader?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }, { label: "Nej", value: "NEJ" }]
        },
        {
            PARAMETER_ID: "98243",
            description: "Beskrivning övrig kostnad",
            IsSelect: false
        },
        {
            PARAMETER_ID: "98250",
            description: "Har uppdraget tagit längre än 7 timmar?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }, { label: "Nej", value: "NEJ" }]
        },
        {
            PARAMETER_ID: "98251",
            description: "Är dagbok bifogad med ordern?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }]
        },
        {
            PARAMETER_ID: "98252",
            description: "Har du installerat aktiv utrustning?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }, { label: "Nej", value: "NEJ" }]
        },
        {
            PARAMETER_ID: "98253",
            description: "Egenkontroll bifogad?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }]
        },
        {
            PARAMETER_ID: "98260",
            description: "APK, arbetsplatskontroll bifogad?",
            IsSelect: true,
            selectValues: [{ label: "Ja", value: "JA" }, { label: "Nej", value: "NEJ" }]
        }
    ];

    const [values, setValues] = useState({
        98210: "", 98211: "", 98220: "", 98221: "", 98230: "", 98231: "", 98240: "",
        98241: "", 98242: "", 98243: "", 98250: "", 98251: "", 98252: "", 98253: "", 98260: ""
    });
    const [fetchedValues, setFetchedValuess] = useState({});
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        const fetchPulsCodeParametersValue = async () => {
            try {
                let url = `${rootUrl}WorkOrder/parameter/trv/${wono}`;
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const parameterValue = await response.json();
                    setFetchedValuess(parameterValue);
                }
            } catch (error) {
                console.error('Error fetching puls code parameters value:', error);
            }
        };
        fetchPulsCodeParametersValue();
    }, []);

    const updateWorkOrder = async (woData) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(woData)
        };
        let url = `${rootUrl}WorkOrder/wo`;
        const response = await fetch(url, options);

        if (!response.ok) {
            console.log(response);
            setToastState({
                open: true,
                severity: 'error',
                message: t('updateFail'),
            });
        }
        else {
            setToastState({
                open: true,
                severity: 'success',
                message: t('updateSuccess'),
            });
        }
        setSaving(false);
    };

    useEffect(() => {
        const fixValuesFromIFS = async () => {
            if (fetchedValues.length > 0) {
                fetchedValues.forEach(item => {
                    values[item.parameteR_ID] = item.valuE_WITH_LOV || "";
                });
            }
        };
        fixValuesFromIFS();
    }, [fetchedValues]);


    const handleInputChange = (parameterId, value) => {
        setValues(prevValues => ({
            ...prevValues,
            [parameterId]: value
        }));
    };

    const isSelectDisabled = (parameterId) => {
        let ret = false;
        if (parameterId === "98211") {
            if (values[98210] !== "JA") {
                values[98211] = "";
                ret = true;
            }
        } else if (parameterId === "98221") {
            if (values[98220] !== "NEJ") {
                values[98221] = "";
                ret = true;
            }
        } else if (parameterId === "98251") {
            if (values[98250] !== "JA") {
                values[98251] = "";
                ret = true;
            }
        } else if (parameterId === "98252") {
            if (values[98250] !== "JA") {
                values[98252] = "";
                ret = true;
            }
        } else if (parameterId === "98253") {
            if (values[98252] !== "JA") {
                values[98253] = "";
                ret = true;
            }
        }
        return ret;
    };

    function isButtonDisabled() {
        let ret = false;
        if (values[98210] === "") {
            ret = true;
        } else if (values[98210] === "JA" && values[98211] === "") {
            ret = true;
        } else if (values[98220] === "") {
            ret = true;
        } else if (values[98220] === "NEJ" && values[98221] === "") {
            ret = true;
        } else if (values[98230] === "") {
            ret = true;
        } else if (values[98230] === "JA" && values[98231] === "") {
            ret = true;
        } else if (values[98240] === "" || values[98241] === "") {
            ret = true;
        } else if (values[98242] === "") {
            ret = true;
        } else if (values[98242] === "JA" && values[98243] === "") {
            ret = true;
        } else if (values[98250] === "") {
            ret = true;
        } else if (values[98250] === "JA" && (values[98251] === "" || values[98252] === "")) {
            ret = true;
        } else if (values[98252] === "JA" && values[98253] === "") {
            ret = true;
        } else if (values[98260] === "") {
            ret = true;
        }
        return ret;
    };

    const handleSave = () => {
        setSaving(true);
        let woParameters = [];
        Object.entries(values).forEach(([parameterId, value]) => {
            woParameters.push({
                parameteR_ID: parameterId,
                valuE_WITH_LOV: value === "Ja" || value === "Nej" ? value.toUpperCase() : value
            });
        });
        const woData =
        {
            wO_NO: wono,
            parameteR_CATEGORIES: woParameters
        };
        updateWorkOrder(woData);
    };

    return (
        <div>
            <span style={{color: 'red', padding: "10px 0px 0px 10px", 
                display: isButtonDisabled() ? '' : 'none'}}>
                    {t('workOrder.extraReport.trvReportText')}
            </span>
            {parameters.map(parameter => (
                <Box key={parameter.PARAMETER_ID} sx={{ minWidth: 220, padding: "10px 0px 0px 10px" }}>
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                        {parameter.IsSelect ? (
                            <>
                                <InputLabel id={`label-${parameter.PARAMETER_ID}`}>{parameter.description}</InputLabel>
                                <Select
                                    labelId={`label-${parameter.PARAMETER_ID}`}
                                    label={parameter.description}
                                    id={`select-${parameter.PARAMETER_ID}`}
                                    value={isSelectDisabled(parameter.PARAMETER_ID) ? '' : (values[parameter.PARAMETER_ID] || '')}
                                    onChange={(e) => handleInputChange(parameter.PARAMETER_ID, e.target.value)}
                                    disabled={isSelectDisabled(parameter.PARAMETER_ID)}
                                >
                                    {parameter.selectValues.map(option => (
                                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : (
                            <TextField
                                id={`textfield-${parameter.PARAMETER_ID}`}
                                label={parameter.description}
                                fullWidth
                                value={values[parameter.PARAMETER_ID] || ''}
                                onChange={(e) => handleInputChange(parameter.PARAMETER_ID, e.target.value)}
                            />
                        )}
                    </FormControl>
                </Box>
            ))}

            <Button variant="contained" color="primary" onClick={handleSave}
                style={{ margin: "10px 0px 10px 10px" }} disabled={isButtonDisabled() || saving}>
                {t('save')}
            </Button>
        </div>
    )
}
export default TrvReport;