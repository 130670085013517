import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#c5d1e5',
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const SubHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#243e90',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '5px 16px',
        height: 'auto !important',
        fontWeight: 'bold'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const DiaryOverview = ({ diary }) => {
    const { t } = useTranslation();

    return (
        <>
            <TableContainer component={Paper} sx={{ maxWidth: 1500 }}>
                <Table sx={{ minWidth: 1000 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center" colSpan={2}><div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>{t('diary.overview.diary')}<Box component="img" sx={{ height: 60, width: 90 }} alt="Eltel logotype." src="/images/Logo.png" /></div></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow sx={{ height: '10px' }}>
                            <SubHeader component="th" scope="col">{t('diary.overview.entrepreneur')}</SubHeader>
                            <SubHeader component="th" scope="col">{t('diary.overview.agreement')}</SubHeader>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell>Eltel Networks Infranet AB</StyledTableCell>
                            <StyledTableCell>{diary.agreement}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow sx={{ height: '10px' }}>
                            <SubHeader component="th" scope="col">{t('diary.overview.constructionLead')}</SubHeader>
                            <SubHeader component="th" scope="col">{t('diary.overview.department')}</SubHeader>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell>{diary.constructionLead}</StyledTableCell>
                            <StyledTableCell>{diary.department}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow sx={{ height: '10px' }}>
                            <SubHeader component="th" scope="col">{t('diary.overview.woNumber')}</SubHeader>
                            <SubHeader component="th" scope="col">{t('diary.overview.shortDescription')}</SubHeader>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell>{diary.woNumber}</StyledTableCell>
                            <StyledTableCell>{diary.shortDescription}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow sx={{ height: '10px' }}>
                            <SubHeader component="th" scope="col">{t('diary.overview.establishedBy')}</SubHeader>
                            <SubHeader component="th" scope="col">{t('diary.overview.approvedBy')}</SubHeader>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell>{diary.establishedBy}</StyledTableCell>
                            <StyledTableCell>{diary.approvedBy}</StyledTableCell>
                        </StyledTableRow>
                        {
                            diary.referenceNo !== "" || diary.teliaOrderNo !== "" ? (
                            <>
                                <StyledTableRow sx={{ height: '10px' }}>
                                    <SubHeader component="th" scope="col">{t('diary.overview.orderNumber')}</SubHeader>
                                    <SubHeader component="th" scope="col">{t('diary.overview.assignmentNumber')}</SubHeader>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell>{diary.referenceNo}</StyledTableCell>
                                    <StyledTableCell>{diary.teliaOrderNo}</StyledTableCell>
                                </StyledTableRow>
                            </>
                            ) : (<></>)
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ maxWidth: 500, marginTop: '20px' }}>
                <Table sx={{ minWidth: 200 }} aria-label="customized table">
                    <TableBody>
                        <StyledTableRow>
                            <SubHeader component="th" scope="row">{t('diary.overview.location')}</SubHeader>
                            <StyledTableCell>{diary.locality}</StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default DiaryOverview;