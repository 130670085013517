import { Document, Page, View, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";
import { useAuth } from 'oidc-react';
import { format } from 'date-fns';

const styles = StyleSheet.create({
  page: {
    color: "black",
    alignItems: 'center',
    marginTop: '10px'
  },
  section: {
    margin: 5,
    padding: 5,
  },
  viewer: {
    width: '50vw',
    height: '90vh',
  },
  tableContainer: {
    borderRadius: '4px',
    border: '1px solid rgb(245,245,245)',
    width: '100%',
    overflowX: 'auto',
    maxWidth: '550px',
    margin: 10,
  },
  td: {
    fontSize: '12px',
    border: 0,
    padding: '4px'
  },
  th: {
    padding: '2px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    fontFamily: 'Helvetica-Bold'
  },
  tr: {
    padding: '6px',
  },
  subHeader: {
    fontFamily: 'Helvetica-Bold',
    border: 0,
    fontSize: '12px',
    padding: '4px'
  },
  stripe: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  rightAlign: {
    justifyContent: 'flex-end'
  }
});

function PdfView({ diary, handleOverviewClick, setToastState }) {
  const [uploading, setUploading] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const { t } = useTranslation();
  const [obstacles, setObstacles] = useState([]);
  const [times, setTimes] = useState([]);
  const { wono } = useParams();
  const auth = useAuth();
  const accessToken = auth.userData?.access_token;
  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;

  const fetchObstacles = async () => {
    if (!accessToken) return;
    try {
      const url = `${rootUrl}Diary/list/${wono}/OCST`;
      console.log("URL = " + url);
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error("Failed to fetch diary list.");
      }

      const data = await response.json();
      setObstacles(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTimes = async () => {
    if (!accessToken) return;
    try {
        const url = `${rootUrl}Diary/timereports/${wono}`;
        console.log("URL = " + url);
        const response = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error("Failed to fetch diary list.");
        }

        const data = await response.json();
        setTimes(data);
    } catch (error) {
        console.error(error);
    }
};

  useEffect(() => {
    fetchObstacles();
    fetchTimes();
  }, [accessToken]);

  const handleAddPdfToWoClick = (event) => {
    setUploading(true);
    addPdfToWorkOrder();
  };

  const addPdfToWorkOrder = async () => {
    var blobPDF = pdfBlob.blob;
    const formData = new FormData();
    formData.append('filedata', blobPDF);
    let url = `${rootUrl}attachment/upload/diary/${wono}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        setToastState({
          open: true,
          severity: 'success',
          message: t('diary.overview.addPdfSuccessMessage'),
        });
        setUploading(false);
      } else {
        console.error('Upload PDF to WO failed.');
      }
    } catch (error) {
      console.error('Upload PDF error:', error);
      setToastState({
        open: true,
        severity: 'error',
        message: 'An error occurred during upload',
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <Box sx={{ pb: 0 }}>
        <Stack direction={"row"}>
          <Button onClick={handleOverviewClick} color="primary">{t('diary.overview.showOverview')}</Button>
          <Button onClick={handleAddPdfToWoClick}
            color="primary"
            disabled={uploading}
          >{uploading ? <CircularProgress size={24} /> : t('diary.overview.addPdf')}</Button>
        </Stack>
      </Box>
      <PDFViewer style={styles.viewer}>
        <PdfDoc diary={diary} onRender={setPdfBlob} obstacles={obstacles} times={times} />
      </PDFViewer>
    </>
  );
}

const PdfDoc = ({ diary, onRender, obstacles, times }) => {
  const { t } = useTranslation();

  return (
    <Document onRender={onRender} title={t('diary.overview.diary')}>
      <Page size="A4" style={styles.page}>
        <View style={styles.tableContainer}>
          <Table>
            <TH style={{ backgroundColor: 'rgb(197, 209, 229)' }}>
              <TD style={{ border: 0, fontSize: '20px', fontWeight: 500, justifyContent: 'center' }}>
                {t('diary.overview.diary')}<Image style={{ height: 60, width: 90 }} src="/images/Logo.png" />
              </TD>
            </TH>
            <TH style={styles.th}>
              <TD style={styles.td}>{t('diary.overview.entrepreneur')}</TD>
              <TD style={styles.td}>{t('diary.overview.agreement')}</TD>
            </TH>
            <TR style={styles.tr}>
              <TD style={styles.td}>Eltel Networks Infranet AB</TD>
              <TD style={styles.td}>{diary.agreement}</TD>
            </TR>
            <TH style={styles.th}>
              <TD style={styles.td}>{t('diary.overview.constructionLead')}</TD>
              <TD style={styles.td}>{t('diary.overview.department')}</TD>
            </TH>
            <TR style={styles.tr}>
              <TD style={styles.td}>{diary.constructionLead}</TD>
              <TD style={styles.td}>{diary.department}</TD>
            </TR>
            <TH style={styles.th}>
              <TD style={styles.td}>{t('diary.overview.woNumber')}</TD>
              <TD style={styles.td}>{t('diary.overview.shortDescription')}</TD>
            </TH>
            <TR style={styles.tr}>
              <TD style={styles.td}>{diary.woNumber}</TD>
              <TD style={styles.td}>{diary.shortDescription}</TD>
            </TR>
            {
              diary.referenceNo !== "" || diary.teliaOrderNo !== "" ? (
                <>
                  <TH style={styles.th}>
                    <TD style={styles.td}>{t('diary.overview.orderNumber')}</TD>
                    <TD style={styles.td}>{t('diary.overview.assignmentNumber')}</TD>
                  </TH>
                  <TR style={styles.tr}>
                    <TD style={styles.td}>{diary.referenceNo}</TD>
                    <TD style={styles.td}>{diary.teliaOrderNo}</TD>
                  </TR>
                </>
              ) : (<></>)
            }
            <TH style={styles.th}>
              <TD style={styles.td}>{t('diary.overview.establishedBy')}</TD>
              <TD style={styles.td}>{t('diary.overview.approvedBy')}</TD>
            </TH>
            <TR style={styles.tr}>
              <TD style={styles.td}>{diary.establishedBy}</TD>
              <TD style={styles.td}>{diary.approvedBy}</TD>
            </TR>
          </Table>
        </View>
        <View style={[styles.tableContainer, { marginTop: '20px' }]}>
          <Table style={{ minWidth: 200, border: 0 }}>
            <TR style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)', fontSize: '14px' }}>
              <TD style={[styles.td, { fontFamily: 'Helvetica-Bold' }]}>{t('diary.overview.location')}</TD>
              <TD style={styles.td}>{diary.locality}</TD>
            </TR>
          </Table>
        </View>
        <View style={styles.tableContainer}>
          <Table>
            <TH style={{ backgroundColor: 'rgb(197, 209, 229)' }}>
              <TD style={{ border: 0, fontSize: '14px', fontWeight: 500, padding: '8px' }}>{t('diary.summary.heading')}</TD>
            </TH>
            <TR style={[styles.stripe, styles.tr]}>
              <TD style={styles.subHeader}>{t('diary.summary.technician')}</TD>
              <TD style={[styles.td, styles.rightAlign]}>{diary.technician}</TD>
              <TD style={styles.td}>{t('diary.summary.hours')}</TD>
            </TR>
            <TR style={styles.tr}>
              <TD style={styles.subHeader}>{t('diary.summary.projectLead')}</TD>
              <TD style={[styles.td, styles.rightAlign]}>{diary.projectLead}</TD>
              <TD style={styles.td}>{t('diary.summary.hours')}</TD>
            </TR>
            <TR style={[styles.stripe, styles.tr]}>
              <TD style={styles.subHeader}>{t('diary.summary.designer')}</TD>
              <TD style={[styles.td, styles.rightAlign]}>{diary.designer}</TD>
              <TD style={styles.td}>{t('diary.summary.hours')}</TD>
            </TR>
            <TR style={styles.tr}>
              <TD style={styles.subHeader}>{t('diary.summary.fullDesigner')}</TD>
              <TD style={[styles.td, styles.rightAlign]}>{diary.fullDesigner}</TD>
              <TD style={styles.td}>{t('diary.summary.hours')}</TD>
            </TR>
            <TR style={[styles.stripe, styles.tr]}>
              <TD style={styles.subHeader}>{t('diary.summary.workMaster')}</TD>
              <TD style={[styles.td, styles.rightAlign]}>{diary.workMaster}</TD>
              <TD style={styles.td}>{t('diary.summary.hours')}</TD>
            </TR>
            <TR style={styles.tr}>
              <TD style={styles.subHeader}>{t('diary.summary.subcontractorTechnician')}</TD>
              <TD style={[styles.td, styles.rightAlign]}>{diary.subcontractorTechnician}</TD>
              <TD style={styles.td}>{t('diary.summary.hours')}</TD>
            </TR>
            <TR style={[styles.stripe, styles.tr]}>
              <TD style={styles.subHeader}>{t('diary.summary.subcontractor')}</TD>
              <TD style={[styles.td, styles.rightAlign]}>{diary.subcontractor}</TD>
              <TD style={styles.td}>{t('diary.summary.hours')}</TD>
            </TR>
            <TR style={styles.tr}>
              <TD style={styles.subHeader}>{t('diary.summary.totalTime')}</TD>
              <TD style={[styles.td, styles.rightAlign]}>{diary.totalTime}</TD>
              <TD style={styles.td}>{t('diary.summary.hours')}</TD>
            </TR>
            <TR style={[styles.stripe, styles.tr]}>
              <TD style={styles.subHeader}>{t('diary.summary.obstacles')}</TD>
              <TD style={[styles.td, styles.rightAlign]}>{diary.obstacles}</TD>
              <TD style={styles.td}>{t('diary.summary.hours')}</TD>
            </TR>
            <TR style={styles.tr}>
              <TD style={styles.subHeader}>{t('diary.summary.otherCosts')}</TD>
              <TD style={[styles.td, styles.rightAlign]}>{diary.otherCosts}</TD>
              <TD style={styles.td}>SEK</TD>
            </TR>
          </Table>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <PdfDiaryObstaclesAndCosts obstacles={obstacles} />
        <DiaryTimeReportTable times={times} />
      </Page>
    </Document>
  );
};

const PdfDiaryObstaclesAndCosts = ({ obstacles }) => {
  const { t } = useTranslation();
  const valueOptionsMapping = [
    { value: 'OBSTACLES', label: t('diary.obstacles.obstacles') },
    { value: 'OTHER', label: t('diary.obstacles.other') },
    { value: 'ATA', label: t('diary.obstacles.ata') },
    { value: 'OTHERCOST', label: t('diary.obstacles.otherCost') },
  ];

  const calculateSum = () => {
    return obstacles.reduce((n, { diaryTime }) => n + diaryTime, 0);
  };

  const calculateSumCost = () => {
    return obstacles.reduce((n, { cost }) => n + cost, 0);
  };

  const getObstacleDesc = (name) => {
    return valueOptionsMapping.find(x => x.value === name)?.label;
  };

  return (
    obstacles.length > 0 ?
      <View style={styles.tableContainer}>
        <Table weightings={[0.25, 0.35, 0.2, 0.2]}>
          <TH style={{ backgroundColor: 'rgb(197, 209, 229)', padding: '8px' }}>
            <TD style={[styles.td, styles.subHeader]}>{t('diary.tabs.tab6')}</TD>
            <TD style={[styles.td, styles.subHeader]}>{t('diary.description')}</TD>
            <TD style={[styles.td, styles.rightAlign]}>{t('diary.cost')}</TD>
            <TD style={[styles.td, styles.rightAlign]}>{t('diary.time')}</TD>
          </TH>
          {obstacles.map((row, index) => (
            <TR key={row.id} style={[styles.tr, { backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.04)' : undefined }]}>
              <TD style={styles.td}>{getObstacleDesc(row.obstacles)}</TD>
              <TD style={styles.td}>{row.diaryText}</TD>
              <TD style={[styles.td, styles.rightAlign]}>{row.cost && row.cost > 0 ? row.cost + ' SEK' : ''}</TD>
              <TD style={[styles.td, styles.rightAlign]}>{row.diaryTime && row.diaryTime > 0 ? row.diaryTime + 'h' : ''}</TD>
            </TR>
          ))}
          <TR key={'sum'} style={styles.tr}>
            <TD style={styles.td}>&nbsp;</TD>
            <TD style={styles.td}>&nbsp;</TD>
            <TD style={[styles.td, styles.rightAlign, styles.subHeader, { paddingRight: 0 }]}>{t('diary.sum')}:&nbsp;{calculateSumCost()}&nbsp;SEK</TD>
            <TD style={[styles.td, styles.rightAlign, styles.subHeader, { paddingRight: '4px' }]}>{t('diary.sumTime')}:&nbsp;{calculateSum()}h</TD>
          </TR>
        </Table>
      </View>
      : <></>
  );
}

const DiaryTimeReportTable = ({ times }) => {
  const { t } = useTranslation();

  const roleNamesMapping = [
    { role: 'TEC', label: t('diary.summary.technician') },
    { role: 'DES', label: t('diary.summary.designer') },
    { role: 'FDES', label: t('diary.summary.fullDesigner') },
    { role: 'PLEAD', label: t('diary.summary.projectLead') },
    { role: 'SUBC', label: t('diary.summary.subcontractor') },
    { role: 'SUBCTECH', label: t('diary.summary.subcontractorTechnician') },
    { role: 'WMASTER', label: t('diary.summary.workMaster') }
  ];

  const getRoleName = (role) => {
    return roleNamesMapping.find(x => x.role === role).label;
  };

  function hasTimeCodeOrWorkCode() {
    return times.some(entry => entry.timeCode || entry.workCode);
  }
  if (hasTimeCodeOrWorkCode() === true)
  {
    return (
      <View style={styles.tableContainer}>
        <Table  weightings={[0.12, 0.1, 0.1, 0.1, 0.23, 0.25, 0.10]}>
          <TH style={{ backgroundColor: 'rgb(197, 209, 229)' }}>
              <TD style={[styles.td, styles.subHeader]}>{t('diary.date')}</TD>
              <TD style={[styles.td, styles.subHeader]}>{t('diary.performer')}</TD>
              <TD style={[styles.td, styles.subHeader, styles.rightAlign]}>{t('diary.hours')}</TD>
              <TD style={[styles.td, styles.subHeader]}>{t('diary.timeCode')}</TD>
              <TD style={[styles.td, styles.subHeader]}>{t('diary.work')}</TD>
              <TD style={[styles.td, styles.subHeader]}>{t('diary.description')}</TD>
              <TD style={[styles.td, styles.subHeader]}>{t('diary.role')}</TD>
          </TH>
            {times.map((row, index) => (
              <TR key={row.id} style={{ backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.04)' : undefined }}>
                <TD style={styles.td}>{format(new Date(row.createdTime), 'yyyy-MM-dd')}</TD>
                <TD style={styles.td}>{row.userId}</TD>
                <TD  style={[styles.td, styles.rightAlign]}>{row.diaryTime}</TD>
                <TD style={styles.td}>{row.timeCode}</TD>
                <TD style={styles.td}>{row.workCode}</TD>
                <TD style={styles.td}>{row.diaryText}</TD>
                <TD style={styles.td}>{getRoleName(row.userRole)}</TD>
              </TR>
            ))}
        </Table>
      </View>
    );
  } else {
    return (
      <View style={styles.tableContainer}>
        <Table  weightings={[0.15, 0.15, 0.15, 0.40, 0.15]}>
          <TH style={{ backgroundColor: 'rgb(197, 209, 229)' }}>
              <TD style={[styles.td, styles.subHeader]}>{t('diary.date')}</TD>
              <TD style={[styles.td, styles.subHeader]}>{t('diary.performer')}</TD>
              <TD style={[styles.td, styles.subHeader, styles.rightAlign]}>{t('diary.hours')}</TD>
              <TD style={[styles.td, styles.subHeader]}>{t('diary.description')}</TD>
              <TD style={[styles.td, styles.subHeader]}>{t('diary.role')}</TD>
          </TH>
            {times.map((row, index) => (
              <TR key={row.id} style={{ backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.04)' : undefined }}>
                <TD style={styles.td}>{format(new Date(row.createdTime), 'yyyy-MM-dd')}</TD>
                <TD style={styles.td}>{row.userId}</TD>
                <TD  style={[styles.td, styles.rightAlign]}>{row.diaryTime}</TD>
                <TD style={styles.td}>{row.diaryText}</TD>
                <TD style={styles.td}>{getRoleName(row.userRole)}</TD>
              </TR>
            ))}
        </Table>
      </View>
    );
  }

}

export default PdfView;