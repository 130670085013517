import React, {createContext, useContext, useState} from 'react';
import {Alert, Snackbar} from "@mui/material";

const ToastStateContext = createContext();

export const ToastStateProvider = ({ children }) => {
    const [toastState, setToastState] = useState({
        open: false,
        severity: "success",
        message: '',
    });

    return (
        <ToastStateContext.Provider value={{ toastState: toastState, setToastState: setToastState }}>
            <Snackbar
                open={toastState.open}
                autoHideDuration={6000}
                onClose={() => setToastState({...toastState, open: false})}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}} // Position and alignment
                style={{position: 'absolute', zIndex: 1301}} // Adjust zIndex to be above other elements
            >
                <Alert
                    severity={toastState.severity}
                    variant="filled"
                    onClose={() => setToastState({...toastState, open: false})}
                >
                    {toastState.message}
                </Alert>
            </Snackbar>
            {children}
        </ToastStateContext.Provider>
    );
};

export const useToastState = () => useContext(ToastStateContext);