import i18n from 'i18next';

export function statusMapping(objState) {
    let ret = { stateName: "", stateColor: "" };
    if (objState === "FAULTREPORT" || objState === "OBSERVED" || objState === "WORKREQUEST") {
        ret = { stateName: "", stateColor: "#FFFFFF" };
    }
    else if (objState === "UNDERPREPARATION") {
        ret = { stateName: i18n.t('workOrder.statuses.underpreparation'), stateColor: "#E69138" };
    } else if (objState === "PREPARED") {
        ret = { stateName: i18n.t('workOrder.statuses.prepared'), stateColor: "#F5F599" };
    } else if (objState === "RELEASED") {
        ret = { stateName: i18n.t('workOrder.statuses.released'), stateColor: "#F89088" };
    } else if (objState === "STARTED") {
        ret = { stateName: i18n.t('workOrder.statuses.started'), stateColor: "#A8E7EA" };
    } else if (objState === "JOB_START") {
        ret = { stateName: i18n.t('workOrder.statuses.onSite'), stateColor: "#4DA6BB" };
    } else if (objState === "TEC_READY") {
        ret = { stateName: i18n.t('workOrder.statuses.fieldReady'), stateColor: "#88DE92" };
    } else if (objState === "CUSTOMER_READY") {
        ret = { stateName: i18n.t('workOrder.statuses.customerReady'), stateColor: "#88DE92" };
    } else if (objState === "WORKDONE") {
        ret = { stateName: i18n.t('workOrder.statuses.workDone'), stateColor: "#849AE0" };
    } else if (objState === "CANCELLED") {
        ret = { stateName: i18n.t('workOrder.statuses.cancelled'), stateColor: "#FF3737" };
    } else if (objState === "REPORTED") {
        ret = { stateName: i18n.t('workOrder.statuses.reported'), stateColor: "#849A10" };
    } else if (objState === "FINISHED") {
        ret = { stateName: i18n.t('workOrder.statuses.finished'), stateColor: "#BB2B73" };
    }
    
    return ret;
}

export function selectableStatuses(objState, contractType, previousState = "", eventControl = "") {
    let ret = [];
    if (eventControl === "PENDING") {
        return [];
    }
    if (objState === "UNDERPREPARATION") {
        ret = [ ];
    }
    else if (objState === "PREPARED") {
        ret = [
            { stateName: i18n.t('workOrder.statuses.released'), stateColor: "#F89088", objState: "RELEASED" },
            { stateName: i18n.t('workOrder.statuses.started'), stateColor: "#A8E7EA", objState: "STARTED" }
        ];
    } else if (objState === "RELEASED") {
        if (previousState === "PREPARED") {
            ret = [
                { stateName: i18n.t('workOrder.statuses.prepared'), stateColor: "#F5F599", objState: "PREPARED" },
                { stateName: i18n.t('workOrder.statuses.started'), stateColor: "#A8E7EA", objState: "STARTED" }
            ];
        } else if (previousState === "STARTED") {
            ret = [
                { stateName: i18n.t('workOrder.statuses.onSite'), stateColor: "#4DA6BB", objState: "JOB_START" },
                { stateName: i18n.t('workOrder.statuses.started'), stateColor: "#A8E7EA", objState: "STARTED" }
            ];
        } else if (previousState === "JOB_START" && contractType === "BUILD") { //På plats bygg
            ret = [
                { stateName: i18n.t('workOrder.statuses.onSite'), stateColor: "#4DA6BB", objState: "JOB_START" },
                { stateName: i18n.t('workOrder.statuses.fieldReady'), stateColor: "#88DE92", objState: "TEC_READY" }
            ];
        } else if (previousState === "JOB_START" && contractType === "SERVICE") { //På plats service
            ret = [
                { stateName: i18n.t('workOrder.statuses.onSite'), stateColor: "#4DA6BB", objState: "JOB_START" },
                { stateName: i18n.t('workOrder.statuses.customerReady'), stateColor: "#88DE92", objState: "CUSTOMER_READY" }
            ];
        }
    } else if (objState === "STARTED") {
        ret = [
            { stateName: i18n.t('workOrder.statuses.released'), stateColor: "#F89088", objState: "RELEASED" },
            { stateName: i18n.t('workOrder.statuses.onSite'), stateColor: "#4DA6BB", objState: "JOB_START" }
        ];
    } else if (objState === "JOB_START" && contractType === "BUILD") { //På plats
        ret = [
            { stateName: i18n.t('workOrder.statuses.released'), stateColor: "#F89088", objState: "RELEASED" },
            { stateName: i18n.t('workOrder.statuses.fieldReady'), stateColor: "#88DE92", objState: "TEC_READY" }
        ];
    } else if (objState === "JOB_START" && contractType === "SERVICE") {
        ret = [
            { stateName: i18n.t('workOrder.statuses.released'), stateColor: "#F89088", objState: "RELEASED" },
            { stateName: i18n.t('workOrder.statuses.customerReady'), stateColor: "#88DE92", objState: "CUSTOMER_READY" }
        ];
    } else if (objState === "TEC_READY") {
        ret = [];
    } else if (objState === "CUSTOMER_READY" && contractType === "SERVICE") {
        ret = [
            { stateName: i18n.t('workOrder.statuses.workDone'), stateColor: "#849AE0", objState: "WORKDONE" }
        ];
    } else if (objState === "CUSTOMER_READY" && contractType === "BUILD") {
        ret = [];
    } else if (objState === "WORKDONE") {
        ret = [];
    } else if (objState === "REPORTED") {
        ret = [];
    } else if (objState === "CANCELLED") {
        ret = [];
    }
    return ret;
}