import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DescriptionIcon from '@mui/icons-material/Description';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LockIcon from '@mui/icons-material/Lock';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import FileWorker from './FileWorker';
import { useAuth } from 'oidc-react';

const FileCard = ({ file, setSuccessToastState, fetchNewFiles, onClose, setToastState, isReadOnly }) => {
    const { t, i18n } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const auth = useAuth();
    const username = auth.userData?.profile?.preferred_username?.toUpperCase();

    const centeredIconStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '2rem'
    };

    const getFileExtension = (fileName) => {
        const dotIndex = fileName.lastIndexOf('.');
        return dotIndex !== -1 ? fileName.slice(dotIndex + 1).toLowerCase() : '';
    };

    const checkoutFileClientSide = (file) => {
        file.docState = "Checked Out";
        file.checkedOutSign = username;
    };

    const undoCheckoutFileClientSide = (file) => {
        file.docState = "Checked In";
    };

    const checkinFileClientSide = () => {
        if (file.docState !== "Checked In") {
            file.docState = "Checked In";
            file.checkedInSign = username;
            file.checkedInDate = new Date().toLocaleString(i18n.language) + ' (*)';
        }
    };

    const getFileIcon = () => {
        const fileExtension = getFileExtension(file.docTitle);

        if (fileExtension === 'jpeg' || fileExtension === 'jpg' || fileExtension === 'png') {
            return <div style={centeredIconStyle}><CameraAltIcon fontSize="large" /></div>;
        } else if (fileExtension === 'pdf') {
            return <div style={centeredIconStyle}><PictureAsPdfIcon fontSize="large" /></div>;
        } else if (fileExtension === 'txt') {
            return <div style={centeredIconStyle}><DescriptionIcon fontSize="large" /></div>;
        } else {
            return <div style={centeredIconStyle}><InsertDriveFileIcon fontSize="large" /></div>;
        }
    };
    //---

    const [openFileWorker, setOpenFileWorker] = useState(false);

    const handleOpenFileWorker = () => {
        setOpenFileWorker(true);
    };

    const handleCloseFileWorker = () => {
        setOpenFileWorker(false);
    };

    //--
    return (
        <Card variant="outlined" sx={{ cursor: 'pointer' }}>
            <CardContent onClick={handleOpenFileWorker}>
                <Grid container spacing={3}>
                    <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', wordWrap: 'break-word' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {getFileIcon()}
                            {file.docState === "Checked Out" && (
                                <div style={{ marginTop: '8px' }}>
                                    <LockIcon style={{ color: 'orange' }} />
                                </div>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={8} style={{ wordWrap: 'break-word' }}>
                        <Typography variant="h6" component="div">
                            {file.docTitle}
                        </Typography>
                        {file.docClass && (
                            <Typography variant="body2">
                                {t('workOrder.attachments.file.description')}: {file.docClass}
                            </Typography>
                        )}
                        <Typography variant="body2">
                            {t('workOrder.attachments.file.addedBy')}: {file.checkedInSign}
                        </Typography>
                        <Typography variant="body2">
                            {t('workOrder.attachments.file.addedDate')}: {file.checkedInDate}
                        </Typography>
                        {file.docState === "Checked Out" && (
                            <Typography variant="body2">
                                {t('workOrder.attachments.file.checkedOutBy')}: {file.checkedOutSign}
                            </Typography>
                        )
                        }

                    </Grid>
                </Grid>
            </CardContent>
            <FileWorker openFileWorker={openFileWorker} handleOpenFileWorker={handleOpenFileWorker}
                handleCloseFileWorker={handleCloseFileWorker} file={file}
                setSuccessToastState={setSuccessToastState} fetchNewFiles={fetchNewFiles} onClose={onClose} setToastState={setToastState}
                checkoutFileClientSide={checkoutFileClientSide} undoCheckoutFileClientSide={undoCheckoutFileClientSide}
                checkinFileClientSide={checkinFileClientSide} isReadOnly={isReadOnly} />
        </Card>
    );
};

export default FileCard;
