import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Button, Snackbar, Alert } from '@mui/material';
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { useAuth } from 'oidc-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import { useTranslation } from 'react-i18next';
import Header from "../components/Header";
import Footer from "../components/FooterTask";
import TeliaPulsCode from '../components/TeliaPulsCode';
import ReportQuality from '../components/ReportQuality';
import ReportParameters from '../components/ReportParameters';
import TrvReport from '../components/TrvReport';

const ExtraReport = () => {
    const navigate = useNavigate();
    const { wono } = useParams();
    const [saving, setSaving] = useState(false);
    const [tdk011Exists, setTdk011] = useState(false);
    const [tdk011Mandatory, setTdk011Mandatory] = useState(null);
    const [trvExists, setTrvExists] = useState(false);
    const [isReadOnly, setReadOnly] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [tdk011Value, setTdk011Value] = useState("");
    const [error, setError] = useState("");
    const [pulsCode, setPulsCode] = useState('');
    const [parameterEE20xValue, setParameterEE20xValue] = useState(null);
    const [pEE000, setPEE000] = useState("");
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
    let woData = "";
    const isMobile = useMediaQuery('(max-width:600px)');
    const maxHeight = isMobile ? '70%' : '80%';
    const [toastState, setToastState] = useState({
        open: false,
        severity: '',
        message: '',
    });

    const onFirstMenuItemClick = useCallback(() => {
        navigate(`/task/${wono}`);
    }, [navigate, wono]);

    const onFirstMenuItem1Click = useCallback(() => {
        navigate(`/notes/${wono}`);
    }, [navigate, wono]);

    const onFirstMenuItem2Click = useCallback(() => {
        navigate(`/materials/${wono}`);
    }, [navigate, wono]);

    const onFirstMenuItem3Click = useCallback(() => {
        navigate(`/attachments/${wono}`);
    }, [navigate, wono]);

    const onFirstMenuItem4Click = useCallback(() => {
        navigate(`/report/${wono}`);
    }, [navigate, wono]);

    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    function getDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        const fetchParameterEE20xValue = async () => {
            try {
                let url = `${rootUrl}WorkOrder/parameter/ee20xValuses/${wono}`;
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const parameterValue = await response.json();
                    console.log(parameterValue);
                    setParameterEE20xValue(parameterValue);
                }
            } catch (error) {
                console.error('Error fetching parameter value:', error);
            }
        };
        const fetchParameterValue = async () => {
            try {
                let url = `${rootUrl}WorkOrder/parameter/${wono}/EE000`;
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const parameterValue = await response.json();
                    console.log(parameterValue);
                    setPEE000(parameterValue);
                }
            } catch (error) {
                console.error('Error fetching parameter value:', error);
            }
        };
        fetchParameterEE20xValue();
        fetchParameterValue();
    }, []);

    useEffect(() => {
        const fetchPulsCodeParametersValue = async () => {
            try {
                let url = `${rootUrl}WorkOrder/parameter/pulscodes/${wono}`;
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const parameterValue = await response.json();
                    setPulsCode(parameterValue);
                }
            } catch (error) {
                console.error('Error fetching puls code parameters value:', error);
            }
        };
        const fetchParametersValue = async () => {
            try {
                let url = `${rootUrl}WorkOrder/parameter/${wono}/TDK011`;
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setTdk011(data.parameteR_ID === "" ? false : true);
                    setTdk011Value(data.valuE_WITH_LOV);
                    setTdk011Mandatory(data.isMandatoryOn);
                }
            } catch (error) {
                console.error('Error fetching puls code parameters value:', error);
            }
        };

        fetchPulsCodeParametersValue();
        fetchParametersValue();
    }, []);

    useEffect(() => {
        const fetchIsTrvVisible = async () => {
            try {
                let url = `${rootUrl}WorkOrder/parameter/${wono}/98210`;
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setTrvExists(data.parameteR_ID === "" ? false : true);
                }
            } catch (error) {
                console.error('Error fetching trv parameters value:', error);
            }
        };
        fetchIsTrvVisible();
    }, []);

    useEffect(() => {
        const fetchWorkOrderStatus = async () => {
            try {
                const response = await fetch(`${rootUrl}WorkOrder/status/${wono}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const jsonData = await response.text();
                    setReadOnly(jsonData === "REPORTED" || jsonData === "FINISHED");
                } else {
                    console.log("Error fetching work order status. Status:", response.status);
                }
            } catch (error) {
                console.log("Error:", error);
            }
        };
        fetchWorkOrderStatus();
    }, []);

    const handleUploadTDK = async () => {
        setSaving(true);
        woData =
        {
            wO_NO: wono,
            parameteR_CATEGORIES: [
                {
                    parameteR_ID: "TDK011",
                    valuE_WITH_LOV: "Tid är bokat för platsbesök: " + getDate(selectedDate)
                }
            ]
        };
        updateWorkOrder();
    };

    const handleDateClickTDK = (date) => {
        setSelectedDate(date);
    };

    const updateWorkOrder = async () => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(woData)
        };
        let url = `${rootUrl}WorkOrder/wo`;
        const response = await fetch(url, options);

        if (!response.ok) {
            setError(response);
            console.log(response);
            setToastState({
                open: true,
                severity: 'error',
                message: t('updateFail'),
            });
        }
        else {
            setTdk011Value(woData.parameteR_CATEGORIES[0].valuE_WITH_LOV);
            setToastState({
                open: true,
                severity: 'success',
                message: t('updateSuccess'),
            });
        }
        setSaving(false);
    };
    return (
        <div>
            <Header />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px', height: '85vh' }}>
                <Box sx={{ flex: 1, overflowY: 'auto', zIndex: 500 }}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ display: tdk011Exists ? 'block' : 'none' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content">
                            <DescriptionIcon />
                            <Typography>{t('workOrder.extraReport.panelTdk')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                                <b>1. Tid bokad för platsbesök</b><br />
                                <MobileDatePicker
                                    label={t('workOrder.extraReport.chooseDate')}
                                    format='yyyy-MM-dd' sx={{ margin: '10px 0 10px 0' }}
                                    onChange={handleDateClickTDK} value={selectedDate}
                                    views={['year', 'month', 'day']} closeOnSelect />
                                <br />
                                <span style={{color: tdk011Value ? '': 'red'}}>
                                    {tdk011Value ? 
                                        tdk011Value : (tdk011Mandatory ? 
                                            ( `${t('workOrder.parameterMandatory')} ${t(`workOrder.statusesIfs.${tdk011Mandatory.toLowerCase()}`)}`) : null)}
                                </span>
                            </Typography>
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleUploadTDK}
                                    disabled={saving || selectedDate === null || isReadOnly}>
                                    {saving ? <CircularProgress size={24} /> : t('save')}
                                </Button>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
                        sx={{ maxHeight: maxHeight, overflow: 'auto', display: pulsCode.length === 0 ? 'none' : 'block' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content">
                            <DescriptionIcon />
                            <Typography>{t('workOrder.extraReport.panel2')}</Typography>
                        </AccordionSummary>
                        <TeliaPulsCode wono={wono} setToastState={setToastState} pulsCode={pulsCode} isReadOnly={isReadOnly} />
                    </Accordion>

                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ maxHeight: maxHeight, overflow: 'auto' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content">
                            <DescriptionIcon />
                            <Typography>{t('workOrder.extraReport.panel3')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ReportParameters wono={wono} setToastState={setToastState} isReadOnly={isReadOnly} />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} 
                        sx={{ maxHeight: maxHeight, overflow: 'auto', 
                        display: (parameterEE20xValue && parameterEE20xValue.length) || pEE000.parameteR_ID === "EE000" ? 'block' : 'none' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content">
                            <DescriptionIcon />
                            <Typography>{t('workOrder.extraReport.panel4')}</Typography>
                        </AccordionSummary>
                        <ReportQuality wono={wono} setToastState={setToastState} isReadOnly={isReadOnly} parameterEE20xValue={parameterEE20xValue} pEE000={pEE000} />
                    </Accordion>

                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
                        sx={{ maxHeight: maxHeight, overflow: 'auto', display: trvExists ? 'block' : 'none' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5-content">
                            <DescriptionIcon />
                            <Typography>{t('workOrder.extraReport.panel5')}</Typography>
                        </AccordionSummary>
                        <TrvReport wono={wono} setToastState={setToastState} isReadOnly={isReadOnly} />
                    </Accordion>
                </Box>
                <Snackbar
                    open={toastState.open}
                    autoHideDuration={6000}
                    onClose={() => setToastState({ ...toastState, open: false })}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    style={{ position: 'absolute', zIndex: 1301 }} >
                    <Alert
                        severity={toastState.severity}
                        variant="filled"
                        onClose={() => setToastState({ ...toastState, open: false })} >
                        {toastState.message}
                    </Alert>
                </Snackbar>
                <Footer
                    activePage="report"
                    onFirstMenuItemClick={onFirstMenuItemClick}
                    onFirstMenuItem1Click={onFirstMenuItem1Click}
                    onFirstMenuItem2Click={onFirstMenuItem2Click}
                    onFirstMenuItem3Click={onFirstMenuItem3Click}
                    onFirstMenuItem4Click={onFirstMenuItem4Click} />
            </Box>
        </div>
    )
}

export default ExtraReport