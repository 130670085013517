import { useState, useCallback } from "react";
import ModalExamples2 from "./ModalExamples2";
import PortalPopup from "./PortalPopup";
import ModalExamples from "./ModalExamples";
import GoogleMapsQuestionPopup from "./GoogleMapsQuestionPopup";
import styles from "./Buttons.module.css";
import StatusDropdown from "./StatusDropdown";

const Buttons = ({ taskId, status, phoneNumber, coordinates, showStatusDropdown, setToastState, agreementType, setStatusChangeErrorCodes, callCode, eventControl }) => {
  const [isModalExamplesOpen, setModalExamplesOpen] = useState(false);
  const [isModalExamples1Open, setModalExamples1Open] = useState(false);
  const [isGoogleMapsQuestionPopupOpen, setGoogleMapsQuestionPopupOpen] = useState(false);

  const openModalExamples = useCallback(() => {
    setModalExamplesOpen(true);
  }, []);

  const closeModalExamples = useCallback(() => {
    setModalExamplesOpen(false);
  }, []);

  const openModalExamples1 = useCallback(() => {
    setModalExamples1Open(true);
  }, []);

  const closeModalExamples1 = useCallback(() => {
    setModalExamples1Open(false);
  }, []);

  const openGoogleMapsQuestionPopup = useCallback(() => {
    setGoogleMapsQuestionPopupOpen(true);
  }, []);

  const closeGoogleMapsQuestionPopup = useCallback(() => {
    setGoogleMapsQuestionPopupOpen(false);
  }, []);

  const isMobileDevice = () => {
    return /Android|iPhone/i.test(navigator.userAgent)
  }

  return (
    <>
      <div className={styles.ctaButtonsParent}>
        <div className={styles.ctaButtons}>
          {isMobileDevice() && phoneNumber ? (
            <div>
              <div className={styles.callButton}>
                <div className={styles.buttonIcon} onClick={openModalExamples}>
                  <img className={styles.phoneIcon} alt="" src="/phone.svg" />
                </div>
              </div>

              <div className={styles.navigateButton} onClick={openModalExamples1}>
                <div className={styles.buttonIcon1} />
                <img
                  className={styles.navigateButtonChild}
                  alt=""
                  src="/group-13.svg"
                />
              </div>
            </div>
          ): <></>}
        </div>
        {coordinates && coordinates.longitude !== "" && coordinates.latitude !== "" ? (
          <div className={styles.navigateButton1} onClick={openGoogleMapsQuestionPopup}>
            <div className={styles.buttonIcon2}>
              <img className={styles.addressIcon} alt="" src="/address.svg" />
            </div>
          </div>
        ) : <></>}
        <div className={styles.status} style={{ display: showStatusDropdown ? "inline-flex" : "none" }}>
          <StatusDropdown taskId={taskId} woStatus={status} setToastState={setToastState} agreementType={agreementType}
            setStatusChangeErrorCodes={setStatusChangeErrorCodes} callCode={callCode} eventControl={eventControl} />
        </div>
      </div>
      {isModalExamplesOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeModalExamples}
        >
          <ModalExamples2 onClose={closeModalExamples} phoneNumber={phoneNumber} />
        </PortalPopup>
      )}
      {isModalExamples1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeModalExamples1}
        >
          <ModalExamples onClose={closeModalExamples1} phoneNumber={phoneNumber} />
        </PortalPopup>
      )}
      {isGoogleMapsQuestionPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeGoogleMapsQuestionPopup}
        >
          <GoogleMapsQuestionPopup onClose={closeGoogleMapsQuestionPopup} setGoogleMapsQuestionPopupOpen={setGoogleMapsQuestionPopupOpen}
            coordinates={coordinates} wono={taskId} />
        </PortalPopup>
      )}
    </>
  );
};

export default Buttons;
