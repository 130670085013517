import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    IconButton,
    InputAdornment,
    List,
    ListItemText,
    CircularProgress,
    ListItemButton,
    Snackbar,
    Alert,
    Typography,
    Tooltip,
    Stack
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { useAuth } from "oidc-react";
import { Link } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Clear from "@mui/icons-material/Clear";

const SearchModal = ({ open, onClose }) => {
    const { t, i18n } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchMade, setSearchMade] = useState(false);
    const [searchMode, setSearchMode] = useState('simple');
    const [openInfoIcon, setOpenInfoIcon] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [woNumberSearchField, setWoNumberSearchField] = useState('');
    const [refNumberSearchField, setRefNumberSearchField] = useState('');
    const [addressSearchField, setAddressSearchField] = useState('');
    const [descriptionSearchField, setDescriptionSearchField] = useState('');
    const [executedBySearchField, setExecutedBySearchField] = useState('');
    const [toastState, setToastState] = useState({
        open: false,
        severity: 'error',
        message: '',
    });

    const [validationErrors, setValidationErrors] = useState({});
    let errors = {
      woNumber: false,
      refNumber: false,
      address: false,
      description: false,
      date: false,
      executedBy: false,
    };

    function validateSearchTerms() {
        let ret = true;

        if (woNumberSearchField === '' && refNumberSearchField === '' && addressSearchField === ''
            && descriptionSearchField === '' && selectedDate === null && executedBySearchField === '') {
                ret = false;
        }
        
        if (woNumberSearchField !== '' && woNumberSearchField.length < 8) {
            errors = { ...errors, woNumber: true };
            ret = false;
        } else {
            errors = { ...errors, woNumber: false };
        }

        if (refNumberSearchField !== '' && refNumberSearchField.length < 5) {
            errors = { ...errors, refNumber: true };
            ret = false;
        } else {
            errors = { ...errors, refNumber: false };
        }

        if (addressSearchField !== '' && addressSearchField.length < 5) {
            errors = { ...errors, address: true };
            ret = false;
        } else {
            errors = { ...errors, address: false };
        }

        if (descriptionSearchField !== '' && descriptionSearchField.length < 5) {
            errors = { ...errors, description: true };
            ret = false;
        } else {
            errors = { ...errors, description: false };
        }

        if (executedBySearchField !== '' && executedBySearchField.length < 5) {
            errors = { ...errors, executedBy: true };
            ret = false;
        } else {
            errors = { ...errors, executedBy: false };
        }

        setValidationErrors(errors);
        return ret;
    }

    const auth = useAuth();
    const accessToken = auth.userData?.access_token;

    const handleSearch = async () => {
        if (!accessToken) {
            return;
        }
        if (searchTerm.length < 3) {
            setToastState({
                open: true,
                severity: 'error',
                message: t('searchModal.searchTermTooSmall'),
            });
            return;
        }

        setSearchMade(true);
        setLoading(true);
        try {
            const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
            let url = `${rootUrl}WorkOrder/search?searchTerm=${encodeURIComponent(searchTerm)}`;
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setSearchResults(data);
            } else {
                setToastState({
                    open: true,
                    severity: 'error',
                    message: t('updateFail'),
                });
            }
        } catch (error) {
            setToastState({
                open: true,
                severity: 'error',
                message: t('updateFail'),
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAdvancedSearch = async () => {
        if (!accessToken) {
            return;
        }

        // Validate field lengths etc.
        let allSearchTermsValid = validateSearchTerms();

        if (allSearchTermsValid) {
            setSearchMade(true);
            setLoading(true);
            try {
                const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
                let url = `${rootUrl}WorkOrder/advancedSearch`;

                const searchModel =
                {
                    woNo: woNumberSearchField,
                    referenceNumber: refNumberSearchField,
                    address: addressSearchField,
                    description: descriptionSearchField,
                    date: selectedDate,
                    executedBy: executedBySearchField
                };

                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(searchModel)
                    });
                if (response.ok) {
                    const data = await response.json();
                    setSearchResults(data);
                } else {
                    setToastState({
                        open: true,
                        severity: 'error',
                        message: t('searchModal.searchFail'),
                    });
                }
            } catch (error) {
                setToastState({
                    open: true,
                    severity: 'error',
                    message: t('searchModal.searchFail'),
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const handleTooltipOpen = () => {
        setOpenInfoIcon(true);
    };

    const handleTooltipClose = () => {
        setOpenInfoIcon(false);
    };

    const handleSwitchSearchMode = () => {
        if (searchMode === 'simple') {
            setSearchMode('advanced');
        }
        else {
            setSearchMode('simple');
        }
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
    };

    const clearDate = (e) => {
        if (!!e?.preventDefault) {
            e?.preventDefault();
            e?.stopPropagation();
        }
        setSelectedDate(null);
    };

    const mergeAdornments = (...adornments) => {
        const nonNullAdornments = adornments.filter(el => el != null);
        if (nonNullAdornments.length === 0) {
          return null;
        }
      
        if (nonNullAdornments.length === 1) {
          return nonNullAdornments[0];
        }
      
        return React.createElement(
          Stack,
          { direction: "row" },
          nonNullAdornments.map((adornment, index) => (
            React.createElement(React.Fragment, { key: index }, adornment)
          ))
        );
      };
      
      const PickerTextField = ({
        onClearClick,
        ...rest
      }) => (
        React.createElement(TextField, Object.assign({}, rest, {
          InputProps: Object.assign({}, rest.InputProps, {
            endAdornment: mergeAdornments(selectedDate != null ?
              React.createElement(InputAdornment, { position: "end" },
                React.createElement(IconButton, { onClick: onClearClick },
                  React.createElement(Clear, null)
                )
              ) : null,
              rest.InputProps?.endAdornment ?? null
            )
          })
        }))
      );
      

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" maxHeight="95vh">
                <DialogTitle sx={{ pt: 1, pb: 1}}>
                    {t('searchModal.title')}
                    <Tooltip
                        title={
                            <div>
                                <p>{t('searchModal.infoIconTitle')}</p>
                                <ul>
                                    <li>{t('searchModal.infoIconS1')}</li>
                                    <li>{t('searchModal.infoIconS2')}</li>
                                    <li>{t('searchModal.infoIconS3')}</li>
                                    <li>{t('searchModal.infoIconS4')}</li>
                                    <li>{t('searchModal.infoIconS5')}</li>
                                    { searchMode == 'advanced' &&
                                    <li>{t('searchModal.infoIconS6')}</li>
                                    }
                                </ul>
                            </div>
                        }
                        open={openInfoIcon}
                        onClose={handleTooltipClose}
                        disableTouchListener
                        placement="bottom-start"
                        PopperProps={{ style: { maxWidth: '400px' } }}
                    >
                        <span onClick={handleTooltipOpen} style={{ cursor: 'pointer', marginLeft: '4px' }}>
                            <InfoIcon />
                        </span>
                    </Tooltip>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers={true} sx={{ pt: 1, pb: 0 }}>
                    { searchMode == 'simple' ? (
                        <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label={t('searchModal.label')}
                    />
                    ) : (
                        <Stack>
                            <TextField
                            label={t('searchModal.woNumber')}
                            id="woNumber"
                            sx={{ m: 1 }}
                            fullWidth
                            error={validationErrors['woNumber']}
                            helperText={validationErrors['woNumber'] && t('searchModal.woNumberSearchFieldError')}
                            value={woNumberSearchField}
                            onChange={(event) => setWoNumberSearchField(event.target.value)}
                            size='small'
                            margin='dense'
                            />
                            <TextField
                            label={t('searchModal.refNumber')}
                            id="refNumber"
                            sx={{ m: 1 }}
                            fullWidth
                            error={validationErrors['refNumber']}
                            helperText={validationErrors['refNumber'] && t('searchModal.searchFieldMinLengthError')}
                            value={refNumberSearchField}
                            onChange={(event) => setRefNumberSearchField(event.target.value)}
                            size='small'
                            />
                            <TextField
                            label={t('searchModal.address')}
                            id="address"
                            sx={{ m: 1 }}
                            fullWidth
                            error={validationErrors['address']}
                            helperText={validationErrors['address'] && t('searchModal.searchFieldMinLengthError')}
                            value={addressSearchField}
                            onChange={(event) => setAddressSearchField(event.target.value)}
                            size='small'
                            />
                            <TextField
                            label={t('searchModal.description')}
                            id="description"
                            sx={{ m: 1 }}
                            fullWidth
                            error={validationErrors['description']}
                            helperText={validationErrors['description'] && t('searchModal.searchFieldMinLengthError')}
                            value={descriptionSearchField}
                            onChange={(event) => setDescriptionSearchField(event.target.value)}
                            size='small'
                            />
                            <DatePicker
                                value={selectedDate}
                                label={t('searchModal.date')}
                                onChange={handleDateClick}
                                format='yyyy-MM-dd'
                                sx={{ m: 1 }}
                                fullWidth
                                views={['year', 'month', 'day']} 
                                closeOnSelect
                                slotProps={{
                                    textField: {
                                       onClearClick: clearDate
                                    }
                                  }}
                                  slots={{
                                    textField: PickerTextField
                                  }}
                                size="small"
                            />
                            <TextField
                            label={t('searchModal.executedBy')}
                            id="executedBy"
                            sx={{ m: 1 }}
                            fullWidth
                            error={validationErrors['executedBy']}
                            helperText={validationErrors['executedBy'] && t('searchModal.searchFieldMinLengthError')}
                            value={executedBySearchField}
                            onChange={(event) => setExecutedBySearchField(event.target.value)}
                            size='small'
                            />
                        </Stack>
                        
                        
                    )}
                    
                    {loading ? (
                        <CircularProgress />
                    ) : searchResults.length > 0 ? (
                        <List sx={{ maxHeight: 300, overflow: 'auto', mt: 0, pt: 0 }}>
                            {searchResults.map((result, index) => (
                                <Link to={`/task/${result.id}`} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <ListItemButton divider>
                                        <ListItemText
                                            primary={`${result.id}, ${result.shortDesc}`}
                                            secondary={`${result.address2}${result.address4 ? `, ${result.address4}` : ''}`}
                                        />
                                    </ListItemButton>
                                </Link>
                            ))}
                        </List>
                    ) : searchMade && (
                        <Typography sx={{ mt: 2 }} variant="subtitle1" component="div">
                            {t('searchModal.noResults')}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button onClick={handleSwitchSearchMode}>
                        { searchMode === 'simple' ? t('searchModal.advancedSearchMode') : t('searchModal.simpleSearchMode') }
                    </Button>
                    { searchMode == 'advanced' && <Button onClick={handleAdvancedSearch}>{t('searchModal.search')}</Button> }
                    <Button onClick={onClose}>{t('searchModal.cancel')}</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={toastState.open}
                autoHideDuration={6000}
                onClose={() => setToastState({ ...toastState, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position and alignment
                style={{ position: 'absolute', zIndex: 1301 }} // Adjust zIndex to be above other elements
            >
                <Alert
                    severity={toastState.severity}
                    variant="filled"
                    onClose={() => setToastState({ ...toastState, open: false })}
                >
                    {toastState.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default SearchModal;
