import React, { useState, useEffect } from 'react';
import DiaryDataGrid from './DiaryDataGrid';
import { useAuth } from 'oidc-react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import {
    GridRowModes,
    GridToolbarContainer,
    GridEditInputCell
} from '@mui/x-data-grid';

const ObstaclesTab = ({ wono }) => {
    const [diaryList, setDiaryList] = useState([]);
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
    const { t } = useTranslation();

    useEffect(() => {
        fetchDiaryList();
    }, []);

    const fetchDiaryList = async () => {
        if (!accessToken) return;
        try {
            const url = `${rootUrl}Diary/list/${wono}/OCST`;
            console.log("URL = " + url);
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error("Failed to fetch diary list.");
            }

            const data = await response.json();
            setDiaryList(data);
        } catch (error) {
            console.error(error);
        }
    };

    const addNewDiaryRow = async (diaryRow) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(diaryRow)
        };
        let url = `${rootUrl}Diary/add`;
        const response = await fetch(url, options);

        if (response.ok) {
            console.log(response);
        }
        else {
            console.error(response);
        }
        fetchDiaryList();
    };

    const editDiaryRow = async (diaryRow) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(diaryRow)
        };
        let url = `${rootUrl}Diary/edit`;
        const response = await fetch(url, options);

        if (response.ok) {
            console.log(response);
        }
        else {
            console.error(response);
        }
    };

    const deleteDiaryRow = async (id) => {
        if (!accessToken) return;
        try {
            const url = `${rootUrl}Diary/delete/${id}`;
            console.log("URL = " + url);
            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                console.error(response);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const formatDate = (date) => {
        return new Date(date); // Adjust the format as needed
    };

    function EditToolbar(props) {
        const { setRows, setRowModesModel, rows } = props;
        const { t } = useTranslation();
        const handleClick = () => {
            const maxId = Math.max(...rows.map(row => row.id), 0);
            const id = maxId + 1;
            setRows((oldRows) => [...oldRows, {
                id, woNo: wono, createdTime: '', userId: '', userRole: 'OCST',
                diaryTime: null, diaryText: '', visible: true, isNew: true
            }]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'obstacles' },
            }));
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    {t('diary.addRow')}
                </Button>
            </GridToolbarContainer>
        );
    }

    const valueOptionsMapping = [
        { value: 'OBSTACLES', label: t('diary.obstacles.obstacles') },
        { value: 'OTHER', label: t('diary.obstacles.other') },
        { value: 'ATA', label: t('diary.obstacles.ata') },
        { value: 'OTHERCOST', label: t('diary.obstacles.otherCost') },
    ]

    const formatList = (val) => {
        const mapping = valueOptionsMapping.find(item => item.value === val);
        return mapping ? mapping.label : val;
    };

    const CurrencyColumn = ({ value }) => (
        <div style={{ textAlign: 'right' }}>
            {value !== undefined ?
                new Intl.NumberFormat('sv-SE', {
                    style: 'currency',
                    currency: 'SEK',
                }).format(value) : ''}
        </div>
    );

    const columns = [
        {
            field: 'obstacles',
            headerName: t('diary.disturbances'),
            width: 200,
            editable: true,
            type: 'singleSelect',
            valueOptions: valueOptionsMapping,
            valueFormatter: (params) => formatList(params.value)
        },
        {
            field: 'createdTime',
            headerName: t('diary.date'),
            type: 'dateTime',
            width: 200,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            valueGetter: (params) => formatDate(params.row.createdTime)
        },
        {
            field: 'userId',
            headerName: t('diary.performer'),
            width: 180,
            editable: true,
            preProcessEditCellProps: (params) => {
                if (params.props.value) {
                    const hasError = params.props.value.length > 100;
                    return { ...params.props, error: hasError };
                } else {
                    return { ...params.props, error: false };
                }
            },
            renderEditCell: (params) => (
                <GridEditInputCell {...params} inputProps={{ maxLength: 100 }} />
            ),
        },
        {
            field: 'diaryTime',
            headerName: t('diary.hours'),
            width: 80,
            editable: true,
            type: 'number',
        },
        {
            field: 'cost',
            headerName: t('diary.cost'),
            width: 80,
            editable: true,
            renderCell: (params) => <CurrencyColumn value={params.value} />,
            cellClassName: 'currency-column',
        },
        {
            field: 'diaryText',
            headerName: t('diary.description'),
            width: 580,
            editable: true,
        },
    ];

    return (
        <div>

            <DiaryDataGrid diaryList={diaryList} addNewDiaryRow={addNewDiaryRow}
                editDiaryRow={editDiaryRow} deleteDiaryRow={deleteDiaryRow} columns={columns} EditToolbar={EditToolbar} />

        </div>
    );
}

export default ObstaclesTab