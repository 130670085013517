import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FileCard from "../components/FileCard";
import Header from "../components/Header";
import Footer from "../components/FooterTask";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { Paper, Typography, Snackbar, Alert, Portal } from "@mui/material";
import FileInput from "../components/FileInput";
import { useAuth } from "oidc-react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
const API_BASE_URL = `${rootUrl}attachment/list/`;

const App = () => {
  const { t } = useTranslation();
  const { wono } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isReadOnly, setReadOnly] = useState(false);

  const onFirstMenuItemClick = useCallback(() => {
    navigate(`/task/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem1Click = useCallback(() => {
    navigate(`/notes/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem2Click = useCallback(() => {
    navigate(`/materials/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem3Click = useCallback(() => {
    navigate(`/attachments/${wono}`);
  }, [navigate, wono]);

  const onFirstMenuItem4Click = useCallback(() => {
    navigate(`/report/${wono}`);
  }, [navigate, wono]);

  const [files, setFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    severity: "error",
    message: "",
  });
  const [successToastState, setSuccessToastState] = useState({
    open: false,
    message: "",
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const auth = useAuth();
  const accessToken = auth.userData?.access_token;

  const fetchFiles = async () => {
    try {
      const response = await fetch(API_BASE_URL + wono, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setFiles(data);
      }
    } catch (error) {
      console.error("Error fetching file list:", error);
    }
  };

  useEffect(() => {
    const fetchWorkOrderStatus = async () => {
      try {
        const response = await fetch(`${rootUrl}WorkOrder/status/${wono}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const jsonData = await response.text();
          setReadOnly(jsonData === "REPORTED" || jsonData === "FINISHED");
        } else {
          console.log(
            "Error fetching work order status. Status:",
            response.status
          );
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchWorkOrderStatus();
  }, []);

  const fetchNewFiles = async (retries = 10) => {
    setShowBackdrop(true);
    return fetch(API_BASE_URL + wono, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const data = await res.json();

          if (data.length > files.length) {
            // Added file received in response
            setShowBackdrop(false);
            setFiles(data);
            return data;
          }
        }

        if (retries > 0) {
          setTimeout(async () => {
            return await fetchNewFiles(retries - 1);
          }, 2000);
        } else {
          // We can't wait forever
          setShowBackdrop(false);
          return;
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#eee",
        position: "relative",
        zIndex: 0,
      }}
    >
      <Header />
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container
        style={{ overflowY: "auto", overflowX: "hidden", height: "85dvh" }}
      >
      <Container maxWidth="lg">
        <Portal>
          {" "}
          {/*way to get the snackbar over the background*/}
          <Snackbar
            open={toastState.open}
            autoHideDuration={6000}
            onClose={() => setToastState({ ...toastState, open: false })}
            anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position and alignment
            style={{ position: "absolute", zIndex: 1301 }} // Adjust zIndex to be above other elements
          >
            <Alert
              severity={toastState.severity}
              sx={{ whiteSpace: 'pre-line' }}
              variant="filled"
              onClose={() => setToastState({ ...toastState, open: false })}
            >
              {toastState.message}
            </Alert>
          </Snackbar>
          <Snackbar
            open={successToastState.open}
            autoHideDuration={6000}
            onClose={() =>
              setSuccessToastState({ ...successToastState, open: false })
            }
            anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position and alignment
            style={{ position: "relative", zIndex: 1301 }} // Adjust zIndex to be above other elements
          >
            <Alert
              severity="success"
              variant="filled"
              onClose={() =>
                setSuccessToastState({ ...successToastState, open: false })
              }
            >
              {successToastState.message}
            </Alert>
          </Snackbar>
        </Portal>
      </Container>
      <Container maxWidth="lg">
        <Box mt={3}>
          <Grid container spacing={3}>
            {files.map((file, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <FileCard
                  file={file}
                  setSuccessToastState={setSuccessToastState}
                  fetchNewFiles={fetchNewFiles}
                  onClose={handleModalClose}
                  setToastState={setToastState}
                  isReadOnly={isReadOnly}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      </Container>
      <IconButton
        onClick={handleModalOpen}
        disabled={isReadOnly}
        sx={{
          position: "fixed",
          bottom: "80px",
          right: "20px",
          bgcolor: "#fff",
          borderRadius: "50%",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <AddIcon sx={{ fontSize: 32, color: "#333" }} />
      </IconButton>

      <Footer
        activePage="attachments"
        onFirstMenuItemClick={onFirstMenuItemClick}
        onFirstMenuItem1Click={onFirstMenuItem1Click}
        onFirstMenuItem2Click={onFirstMenuItem2Click}
        onFirstMenuItem3Click={onFirstMenuItem3Click}
        onFirstMenuItem4Click={onFirstMenuItem4Click}
      />

      {/* Modal with Backdrop */}
      <Modal
        open={isModalOpen}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              position: "relative",
              minWidth: "300px",
              maxWidth: "80%",
            }}
          >
            <IconButton
              onClick={handleModalClose}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                margin: "8px",
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              {t("workOrder.attachments.addFiles")}
            </Typography>
            <Box sx={{ marginBottom: "40px" }}>
              <FileInput
                requestId={wono}
                onClose={handleModalClose}
                setToastState={setToastState}
                setSuccessToastState={setSuccessToastState}
                fetchNewFiles={fetchNewFiles}
                wono={wono}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "16px",
                right: "16px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                zIndex: 1,
              }}
            >
              <IconButton
                onClick={handleModalClose}
                sx={{ backgroundColor: "#fff" }}
              >
                {t("close")}
              </IconButton>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </Box>
  );
};

export default App;
