import styles from "./ModalExamples2.module.css";
import { useTranslation } from 'react-i18next';

const ModalExamples2 = ({ onClose, phoneNumber }) => {
  const { t } = useTranslation();

  const onOk = () => {
    window.location.href = `tel:${phoneNumber}`;
    onClose();
  }

  return (
    <div className={styles.modalExamples}>
      <div className={styles.header}>
        <div className={styles.text}>
          {t('workOrder.callCustomer.header')}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.text1}>{t('workOrder.callCustomer.question')}</div>
      </div>
      <div className={styles.footer}>
        <div className={styles.button}>
          <div className={styles.button1} onClick={onOk}>
            <div className={styles.text2} >OK</div>
          </div>
        </div>
      </div>
      <div className={styles.close}>
        <img className={styles.xIcon} alt="" src="/x.svg" onClick={onClose} />
      </div>
    </div>
  );
};

export default ModalExamples2;
