import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAuth } from 'oidc-react';
import { useTranslation } from "react-i18next";
import { Box, Snackbar, Alert } from '@mui/material';


const EditMaterialModual = ({ open, onClose, wono, setCounter, posting }) => {
    const { t } = useTranslation();
    const [quantity, setQuantity] = useState(1);
    const [isProcessing, setIsProcessing] = useState(false);
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;

    const [toastState, setToastState] = useState({
      open: false,
      severity: '',
      message: '',
    });
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
    const preferred_username = auth.userData?.profile?.preferred_username?.toUpperCase();
    
    useEffect(() => {
      if (posting && posting.qty) {
        setQuantity(posting.qty);
      }
    }, [posting])
  
    const editProduct = () => {
      setIsProcessing(true);
      const woData =
      {
        wO_NO: wono,
        worK_ORDER_CODINGS: [
          {
            rOW_NO: posting.rowNo,
            qtY_TO_INVOICE: quantity,
            qty: quantity,
            emP_NO: preferred_username,
          }
        ]
      };
      updateWorkOrder(woData);
    }
  
    const updateWorkOrder = async (woData) => {
      const headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type", "application/json");
      const options = {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(woData)
      };
      let url = `${rootUrl}WorkOrder/wo`;
      const response = await fetch(url, options);
  
      if (response.ok) {
        setToastState({
          open: true,
          severity: 'success',
          message: t('updateSuccess'),
        });
        setCounter(0);
      }
      else {
        setToastState({
          open: true,
          severity: 'error',
          message: t('updateFail'),
        });
      }
      setIsProcessing(false);
    };

    const increaseQuantity = () => {
      setQuantity(prev => prev + 0.25);
    }
  
    const decreaseQuantity = () => {
      if (quantity > 0) {
        setQuantity(prev => prev - 0.25);
      }
    }
  
    const handleOnClose = () => {
      setQuantity(1);
      onClose();
    };

  return (
    <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth={true}
    >
    <DialogTitle> {posting.costType === 'P' ?
      t('workOrder.materials.editModal.titleTime') : t('workOrder.materials.editModal.titleProduct')}
    </DialogTitle>

    <DialogContent>
      <Box sx={{ minWidth: 220, marginTop: 1 }}>
        <Typography variant="subtitle1" gutterBottom>
        {
          (posting.catalogNo && posting.catalogNo !== "" && posting.lineDescription && posting.lineDescription !== "") ?
          (    
            <>{posting.catalogNo} - {posting.lineDescription}</>
          ) : (<></>)
        }
        {
          (posting.cmnt && posting.cmnt !== "" ? (<>{posting.cmnt}</>) : (<></>) )
        }
        </Typography>
      </Box>
      <Box sx={{ minWidth: 220, marginTop: 1 }}>

      </Box>

      {/* Quantity Counter */}
      <Box sx={{ minWidth: 220, marginTop: 1 }}>
        <IconButton onClick={decreaseQuantity}>
          <RemoveIcon />
        </IconButton>
        <TextField
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(Math.max(0, + e.target.value))}
          style={{ width: '85px', textAlign: 'center' }}
        />
        <IconButton onClick={increaseQuantity}>
          <AddIcon />
        </IconButton>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={editProduct} color="primary" disabled={isProcessing}>
        {t('workOrder.materials.editModal.edit')}
      </Button>
      <Button onClick={handleOnClose} color="primary">
        {t('close')}
      </Button>
    </DialogActions>
    <Snackbar
      open={toastState.open}
      autoHideDuration={6000}
      onClose={() => setToastState({ ...toastState, open: false })}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position and alignment
      style={{ position: 'absolute', zIndex: 1301 }} // Adjust zIndex to be above other elements
    >
      <Alert
        severity={toastState.severity}
        variant="filled"
        onClose={() => setToastState({ ...toastState, open: false })}
      >
        {toastState.message}
      </Alert>
    </Snackbar>
  </Dialog>
  )
}

export default EditMaterialModual