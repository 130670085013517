import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./global.css";
import './i18n';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const container = document.getElementById("root");
const root = createRoot(container);

const appInsightsConnStr = process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTIONSTRING;

if (appInsightsConnStr !== undefined && appInsightsConnStr !== null && appInsightsConnStr !== '') {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
      config: {
          connectionString: appInsightsConnStr,
          extensions: [reactPlugin],
          enableAutoRouteTracking: true
      }
  });
  appInsights.loadAppInsights();
}


root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (reg) => {
      try {
        reg.waiting.postMessage({type: 'SKIP_WAITING'});
      } catch (error) {
        console.warn("error", error);
      }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
