import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Container, Typography, Box, Tab, Tabs, Divider  } from '@mui/material';
import Footer from '../components/Footer'
import Header from '../components/Header'

const DisplayMarkdown = ({ content }) => {
  return (
    <Box>
      <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} />
    </Box>
  );
};

const News = () => {
  const auth = useAuth();
  const accessToken = auth.userData?.access_token;
  const { t } = useTranslation();
  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
  const [texts, setTexts] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
  };
    
  useEffect(() => {
    if (selectedTab === 0) {
      fetchDataAndSetTexts("NEWS");
    } else {
      fetchDataAndSetTexts("INFO");
    }
  }, [selectedTab]);

  const fetchDataAndSetTexts = async (type) => {
    const data = await fetchData(type);
    if (data) {
      if (type === "NEWS") {
        data.sort((a, b) => b.item1 - a.item1);
      }
      setTexts(data); 
    }
  }

  const fetchData = async (type) => {
    try {
      let url = `${rootUrl}api/notifications/news/${type}`;
      const response = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      console.error('Error fetching parameter value:', error);
    }
  };

    return (
      <div>
        <Header />
        <Container>
        <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          <Tab label={t('news.tab1')} />
          <Tab label={t('news.tab2')} />
        </Tabs>
        {selectedTab === 0 && 
          <Box>
          {
            texts.map(text => (
              <Box sx={{
                borderRadius: '8px', 
                border: '1px solid #ccc',
                padding: '14px',
                backgroundColor: 'white',
                marginTop: '10px' 
              }}>            
                <DisplayMarkdown content={text.item2} /> 
              </Box>
            ))
          }   
          </Box>
        }
        {selectedTab === 1 && 
          <Box>
          {
            texts.length > 0 ? (
            texts.map(text => (
              <Box sx={{
                borderRadius: '8px', 
                border: '1px solid #ccc',
                padding: '16px',
                backgroundColor: 'white',
                marginTop: '10px' 
              }}>            
                <DisplayMarkdown content={text.item2} /> 
              </Box>
            ))
          ) : (<Box>{t('news.nothingToShow')}</Box>)
          }   
          </Box>
        }
      </Container>
          <Footer />
      </div>
  )
}

export default News