import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from 'oidc-react';
import styles from "./Diary.module.css";
import Header from '../components/Header'
import DiaryTabs from "./DiaryTabs";
import { Box, Button, Snackbar, Alert, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import DiaryOverview from "./DiaryOverview";
import DiarySummary from "./DiarySummary";
import TechnicianTab from "./TechnicianTab";
import ConstructionManagerTab from "./ConstructionManagerTab";
import UeWithTappTab from "./UeWithTappTab";
import UeWithoutTappTab from "./UeWithoutTappTab";
import ObstaclesTab from "./ObstaclesTab";
import Stack from "@mui/material/Stack";
import PdfView from "./PdfView";

const Diary = () => {
    const { t } = useTranslation();
    const [diaryOverviewData, setDiaryOverviewData] = useState({});
    const [toastState, setToastState] = useState({
        open: false,
        message: '',
      });
    const [selectedTab, setSelectedTab] = useState(0);
    const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);

    const { wono } = useParams();
    const auth = useAuth();
    const accessToken = auth.userData?.access_token;
    const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;

    const getDiaryOverview = async () => {
        try {
            const response = await fetch(`${rootUrl}diary/${wono}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setDiaryOverviewData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (accessToken && selectedTab === 0) {
            getDiaryOverview();
        }
    }, [accessToken, selectedTab]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handlePdfPreviewClick = (event) => {
        setPdfPreviewOpen(true);
    };

    const handleOverviewClick = (event) => {
        setPdfPreviewOpen(false);
    };

    if (selectedTab === 0) {
        return (
            <Box className={styles.pageContainer}>
                <Header hideButtons={true} />
                <Snackbar
                    open={toastState.open}
                    autoHideDuration={3000}
                    onClose={() => setToastState({ ...toastState, open: false })}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                    <Alert
                        severity={toastState.severity}
                        variant="filled"
                        onClose={() => setToastState({ ...toastState, open: false })}
                    >
                    {toastState.message}
                    </Alert>
                </Snackbar>
                <div className={styles.contentContainer}>
                    <DiaryTabs selectedTab={selectedTab} handleTabChange={handleTabChange} />
                    <Box className={styles.addNoteContainer} sx={{ display: 'flex',    alignItems: 'center',    justifyContent: 'center'}}>
                        <Stack>
                            <Box sx={{ pb: 0 }}>
                                <Button onClick={handlePdfPreviewClick} color="primary" sx={{ display: pdfPreviewOpen ? "none" : "block" }}>{t('diary.overview.previewPdf')}</Button>
                            </Box>
                        <div style={{ padding: '20px'}}>
                            <div style={{ display: pdfPreviewOpen ? 'none' : 'block' }}>
                                <DiaryOverview diary={diaryOverviewData}></DiaryOverview>
                                <DiarySummary diary={diaryOverviewData}></DiarySummary>
                            </div>
                            <Box sx={{ display: pdfPreviewOpen ? 'block' : 'none'}}>
                                <PdfView diary={diaryOverviewData} handleOverviewClick={handleOverviewClick} setToastState={setToastState} />
                            </Box>
                        </div>
                        </Stack>
                    </Box>
                </div>
            </Box>
        );
    } else if (selectedTab === 1) {
        return (
            <Box className={styles.pageContainer}>
                <Header hideButtons={true} />
                <div className={styles.contentContainer}>
                    <DiaryTabs selectedTab={selectedTab} handleTabChange={handleTabChange} />
                    <Box className={styles.addNoteContainer}>
                        <ConstructionManagerTab wono={wono} />
                    </Box>
                </div>
            </Box>
        );
    } else if (selectedTab === 2) {
        return (
            <Box className={styles.pageContainer}>
                <Header hideButtons={true} />
                <div className={styles.contentContainer}>
                    <DiaryTabs selectedTab={selectedTab} handleTabChange={handleTabChange} />
                    <Box className={styles.addNoteContainer}>
                        <TechnicianTab wono={wono} />
                    </Box>
                </div>
            </Box>
        );
    } else if (selectedTab === 3) {
        return (
            <Box className={styles.pageContainer}>
                <Header hideButtons={true} />
                <div className={styles.contentContainer}>
                    <DiaryTabs selectedTab={selectedTab} handleTabChange={handleTabChange} />
                    <Box className={styles.addNoteContainer}>
                        <UeWithTappTab wono={wono} />
                    </Box>
                </div>
            </Box>
        );
    } else if (selectedTab === 4) {
        return (
            <Box className={styles.pageContainer}>
                <Header hideButtons={true} />
                <div className={styles.contentContainer}>
                    <DiaryTabs selectedTab={selectedTab} handleTabChange={handleTabChange} />
                    <Box className={styles.addNoteContainer}>
                        <UeWithoutTappTab wono={wono} />
                    </Box>
                </div>
            </Box>
        );
    } else if (selectedTab === 5) {
        return (
            <Box className={styles.pageContainer}>
                <Header hideButtons={true} />
                <div className={styles.contentContainer}>
                    <DiaryTabs selectedTab={selectedTab} handleTabChange={handleTabChange} />
                    <Box className={styles.addNoteContainer}>
                        <ObstaclesTab wono={wono} />
                    </Box>
                </div>
            </Box>
        );
    }
}

export default Diary