import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#c5d1e5',
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const SubHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#243e90',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontWeight: 'bold'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const DiarySummary = ({ diary }) => {
    const { t } = useTranslation();

    return (
        <>
            <TableContainer component={Paper} sx={{ maxWidth: 500, marginTop: 5 }}>
                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell colSpan={3}>{t('diary.summary.heading')}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <SubHeader component="th" scope="row">{t('diary.summary.technician')}</SubHeader>
                            <StyledTableCell align='right'>{diary.technician}</StyledTableCell>
                            <StyledTableCell>{t('diary.summary.hours')}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <SubHeader component="th" scope="row">{t('diary.summary.projectLead')}</SubHeader>
                            <StyledTableCell align='right'>{diary.projectLead}</StyledTableCell>
                            <StyledTableCell>{t('diary.summary.hours')}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <SubHeader component="th" scope="row">{t('diary.summary.designer')}</SubHeader>
                            <StyledTableCell align='right'>{diary.designer}</StyledTableCell>
                            <StyledTableCell>{t('diary.summary.hours')}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <SubHeader component="th" scope="row">{t('diary.summary.fullDesigner')}</SubHeader>
                            <StyledTableCell align='right'>{diary.fullDesigner}</StyledTableCell>
                            <StyledTableCell>{t('diary.summary.hours')}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <SubHeader component="th" scope="row">{t('diary.summary.workMaster')}</SubHeader>
                            <StyledTableCell align='right'>{diary.workMaster}</StyledTableCell>
                            <StyledTableCell>{t('diary.summary.hours')}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <SubHeader component="th" scope="row">{t('diary.summary.subcontractorTechnician')}</SubHeader>
                            <StyledTableCell align='right'>{diary.subcontractorTechnician}</StyledTableCell>
                            <StyledTableCell>{t('diary.summary.hours')}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <SubHeader component="th" scope="row">{t('diary.summary.subcontractor')}</SubHeader>
                            <StyledTableCell align='right'>{diary.subcontractor}</StyledTableCell>
                            <StyledTableCell>{t('diary.summary.hours')}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <SubHeader component="th" scope="row">{t('diary.summary.totalTime')}</SubHeader>
                            <StyledTableCell align='right'>{diary.totalTime}</StyledTableCell>
                            <StyledTableCell>{t('diary.summary.hours')}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <SubHeader component="th" scope="row">{t('diary.summary.obstacles')}</SubHeader>
                            <StyledTableCell align='right'>{diary.obstacles}</StyledTableCell>
                            <StyledTableCell>{t('diary.summary.hours')}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <SubHeader component="th" scope="row">{t('diary.summary.otherCosts')}</SubHeader>
                            <StyledTableCell align='right'>{diary.otherCosts}</StyledTableCell>
                            <StyledTableCell>SEK</StyledTableCell>
                        </StyledTableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default DiarySummary;