import React, { useState } from "react";
import { Typography, IconButton } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const ExpandableText = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
  const maxChars = window.innerWidth <= 600 ? 60 : 130;

  const toggleExpansion = (event) => {
    if (text.length > maxChars) {
        event.stopPropagation();
    }
    setExpanded(!expanded);
  };
  
  return (
    text !== undefined ?  
    <div
      onClick={toggleExpansion}
      style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
    >
      <Typography variant="body1" component="p" style={{ flexGrow: 1 }}>
        {expanded
          ? <div style={{ display: 'inline-block', whiteSpace: 'pre-line', wordWrap: 'normal' }}>{text}</div>
          : `${text.slice(0, maxChars)}${text.length > maxChars ? "..." : ""}`}
      </Typography>
      {text.length > maxChars && (
        <IconButton onClick={toggleExpansion} size="small">
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      )}
    </div> : <></>
  );
};

export default ExpandableText;
