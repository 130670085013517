import styles from "./LanguageSelectionModal.module.css";
import { useTranslation } from "react-i18next";
import { FormControl, Select, MenuItem } from "@mui/material";
import { useAuth } from 'oidc-react';
import {sv} from "date-fns/locale";
import {defaultLang, useLanguage} from "./LanguageProvider";
import {useEffect, useState} from "react";

const LanguageSelectionModal = ({ onClose, setSuccessToastState }) => {
  const { t } = useTranslation();
  const rootUrl = process.env.REACT_APP_BACKEND_API_ROOT_URL;
  const {language, setLanguage} = useLanguage();
  const [languageSelection, setLanguageSelection]= useState(language ? language : defaultLang);

  const auth = useAuth();
  const accessToken = auth.userData?.access_token;


  useEffect(() => {
    if (language) {
      setLanguageSelection(language);
    }
  }, [language]);

  const handleLanguageSelection = async () => {
    try {
      setLanguage(languageSelection);
      onClose(false);
      let url = `${rootUrl}user/language/${languageSelection}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        setSuccessToastState({
          open: true,
          message: t('languageSetting.saved'),
        });
      } else {
        console.error('Save UI language failed');
      }
    } catch (error) {
      console.error('UI language update error:', error);
    }
  }

  const handleLanguageChange = (event) => {
    setLanguageSelection(event.target.value);
  };

  return (
    <div className={styles.languageSelection} style={{height: "220px"}}>
      <div className={styles.header}>
        <div className={styles.text}>
        {t('languageSetting.chooseLanguage')}
        </div>
      </div>
      <div className={styles.body}>
        <FormControl sx={{ minWidth: 120 }}>
          <Select
            id="language-select"
            value={languageSelection}
            onChange={handleLanguageChange}
          >
            <MenuItem value={'sv'}>{t('languageSetting.swedish')}</MenuItem>
            <MenuItem value={'en-US'}>{t('languageSetting.english')}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={styles.footer} style={{top: "150px"}}>
        <div className={styles.button} onClick={onClose}>
          <div className={styles.button1}>
            <div className={styles.text2} >{t('close')}</div>
          </div>
        </div>
        <div className={styles.button} onClick={handleLanguageSelection}>
          <div className={styles.button1}>
            <div className={styles.text2}>{t('save')}</div>
          </div>
        </div>
      </div>
      <div className={styles.close} onClick={onClose}>
        <img className={styles.xIcon} alt="" src="/x.svg"/>
      </div>
    </div>
  );
};

export default LanguageSelectionModal;
