import styles from "./ModalExamples.module.css";
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {TextField} from "@mui/material";

const ModalExamples = ({ onClose, phoneNumber }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState(t('workOrder.sendSMS.template'));
  const onOk = () => {
    const encodedMessage = encodeURIComponent(message);
    window.location.href = `sms:${phoneNumber}?body=${encodedMessage}`;
  }

  return (
      <div className={styles.modalExamples}>
          <div className={styles.header}>
              <div className={styles.text}>{t('workOrder.sendSMS.header')}</div>
          </div>
          <div className={styles.body}>
              <div className={styles.smsInputField}>
                  <TextField
                      fullWidth
                      multiline
                      variant="outlined"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      minRows={3}
                      label={t('workOrder.sendSMS.label')}
                  />
              </div>

          </div>
          <div className={styles.footer}>
              <div className={styles.text1}>{t('workOrder.sendSMS.question')}</div>
              <div className={styles.button} onClick={onOk}>
                  <div className={styles.button1}>
                      <div className={styles.text2}>OK</div>
                  </div>
              </div>
          </div>
          <div className={styles.close}>
              <img className={styles.xIcon} alt="" src="/x.svg" onClick={onClose}/>
          </div>
      </div>
  );
};

export default ModalExamples;
